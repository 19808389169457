import React, { Component } from 'react';
import moment from 'moment';
import Switch from 'react-switch';
import Modal from 'react-bootstrap/Modal';
import { isEmpty } from 'lodash';

import { getDegMinSec, getUTM, getWGS } from '../../utils/locationUtil';
import PhotoItem from '../../components/views/observation/PhotoItem';
import GraphItem from '../../components/views/observation/GraphItem';
import FileItem from '../../components/views/observation/FileItem';
import PDFItem from '../../components/views/observation/PDFItem';
import DraggableModalDialog from './DraggableModalDialog';

import _ from 'lodash';

import '../views/observation/Observation.scss';
import './Modal.scss';

const capitalize = s => (s ? s[0].toUpperCase() + s.slice(1) : '');

const VALUE_CREATED = 'created';
const VALUE_UPDATED = 'updated';
const VALUE_DELETED = 'deleted';
const VALUE_UNCHANGED = 'unchanged';

class ObservationModal extends Component {
  state = {
    genericDetailsOpen: false,
    attachmentsOpen: false,
    specificDetailsOpen: true,
    historyOpen: false,
    technicalDetailsOpen: false,
    UTM: false,
    copying: {},
    doneCopying: {}
  };

  handleSwitch = value => {
    this.setState({ UTM: value });
  };

  getDifference = prevVersions => {
    const localVersions = [...prevVersions];
    var result = {};
    for (var i = 0; i < this.props.data.attributes.length; i++) {
      result[this.props.data.attributes[i].key] = this.props.data.attributes[
        i
      ].value;
    }
    delete result.prevVersions;

    let changes = [];
    let prevVersion = {};
    localVersions.reverse();
    localVersions.forEach((version, index) => {
      if (index === 0) {
        let change = this.differenceMapper(version, result);
        if (change != null) changes.push(change);
        prevVersion = version;
      } else {
        let change = this.differenceMapper(version, prevVersion);
        if (change != null) changes.push(change);
        prevVersion = version;
      }
    });

    return changes;
  };

  differenceMapper = (obj1, obj2) => {
    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw 'Invalid argument. Function given, object expected.';
    }
    if (this.isValue(obj1) || this.isValue(obj2)) {
      let change = this.compareValues(obj1, obj2);
      if (
        change === VALUE_CREATED ||
        change === VALUE_DELETED ||
        change === VALUE_UPDATED
      ) {
        return {
          type: this.compareValues(obj1, obj2),
          prev: obj1 === undefined ? '' : obj1,
          next: obj2 === undefined ? '' : obj2
        };
      } else {
        return null;
      }
    }

    var diff = {};
    for (var key in obj1) {
      if (this.isFunction(obj1[key])) {
        continue;
      }

      var value2 = undefined;
      if (obj2[key] !== undefined) {
        value2 = obj2[key];
      }

      let change = null;
      if (key === 'tags' && this.isArray(obj1[key]) && this.isArray(value2)) {
        change = this.differenceTagsMapper(obj1[key], value2);
      } else {
        change = this.differenceMapper(obj1[key], value2);
      }

      if (
        change !== null &&
        change !== undefined &&
        Object.keys(change).length > 0
      )
        diff[key] = change;
    }

    for (var key2 in obj2) {
      if (this.isFunction(obj2[key2])) {
        continue;
      }

      var value1 = undefined;
      if (obj1[key2] !== undefined) {
        value1 = obj1[key2];
      }

      let change = null;
      if (key === 'tags' && this.isArray(obj2[key2]) && this.isArray(value1)) {
        change = this.differenceTagsMapper(value1, obj2[key2]);
      } else {
        change = this.differenceMapper(value1, obj2[key2]);
      }

      if (
        change !== null &&
        change !== undefined &&
        Object.keys(change).length > 0
      )
        diff[key2] = change;
    }

    delete diff.prevVersions;
    delete diff.recievedAt;
    delete diff.images;
    delete diff.editorId;
    delete diff.editorName;
    delete diff.user;
    delete diff.lastUpdateAt;
    delete diff.userid;
    delete diff.status;
    delete diff.trackId;
    delete diff.editor;

    if (Object.prototype.hasOwnProperty.call(obj2, 'lastUpdateAt'))
      diff.timestamp = obj2.lastUpdateAt;
    if (Object.prototype.hasOwnProperty.call(obj2, 'user'))
      diff.user = obj1.user;

    if (Object.prototype.hasOwnProperty.call(obj2, 'editor')) {
      diff.editor = obj2.editor;
    } else if (Object.prototype.hasOwnProperty.call(obj2, 'editorName')) {
      diff.editor = obj2.editorName;
    }

    return diff;
  };

  differenceTagsMapper = (obj1, obj2) => {
    if (!_.isEqual(obj1.sort(), obj2.sort())) {
      return {
        type: VALUE_UPDATED,
        prev: obj1 === undefined ? '' : obj1.join(', '),
        next: obj2 === undefined ? '' : obj2.join(', ')
      };
    }
  };

  isFunction = x => {
    return Object.prototype.toString.call(x) === '[object Function]';
  };

  isArray = x => {
    return Object.prototype.toString.call(x) === '[object Array]';
  };

  isDate = x => {
    return Object.prototype.toString.call(x) === '[object Date]';
  };

  isObject = x => {
    return Object.prototype.toString.call(x) === '[object Object]';
  };

  isValue = x => {
    return !this.isObject(x) && !this.isArray(x);
  };

  compareValues = (value1, value2) => {
    if (value1 === value2) {
      return VALUE_UNCHANGED;
    }
    if (
      this.isDate(value1) &&
      this.isDate(value2) &&
      value1.getTime() === value2.getTime()
    ) {
      return VALUE_UNCHANGED;
    }
    if (value1 === undefined) {
      return VALUE_CREATED;
    }
    if (value2 === undefined) {
      return VALUE_DELETED;
    }
    return VALUE_UPDATED;
  };

  hasMediaImage = media => {
    return media
      ? media.findIndex(element => element.Media.mediaType === 'jpeg')
      : -1;
  };

  hasMediaPDF = media => {
    return media
      ? media.findIndex(element => element.Media.mediaType === 'pdf')
      : -1;
  };

  copyTextToClipboard = async text => {
    await this.setState({
      copying: {
        ...this.state.copying,
        [text]: true
      }
    });
    navigator.clipboard.writeText(text);

    await new Promise(resolve => setTimeout(resolve, 300));

    await this.setState({
      copying: {
        ...this.state.copying,
        [text]: false
      },
      doneCopying: {
        ...this.state.doneCopying,
        [text]: true
      }
    });

    await new Promise(resolve => setTimeout(resolve, 500));

    await this.setState({
      doneCopying: {
        ...this.state.doneCopying,
        [text]: false
      }
    });
  };

  render() {
    const { ...props } = this.props;
    return (
      <Modal
        dialogAs={DraggableModalDialog}
        show={props.open}
        onHide={() => {
          this.setState({ specificDetailsOpen: true });
          props.close();
        }}
        centered={true}
        size="md"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {props.data.observationClass ? (
              <span>{capitalize(props.data.observationClass)}</span>
            ) : (
              <span></span>
            )}
          </h5>
        </Modal.Header>
        <Modal.Body
          className="pb-0"
          onMouseDown={event => event.stopPropagation()}
        >
          <div className="row no-gutters">
            <div className="col-auto  pl-2 pr-3 pt-3 pb-3 ml-1 photoItem">
              {this.hasMediaImage(props.data.media) > -1 && (
                <img
                  src={props.data.media[0].url}
                  className="img-fluid rounded"
                  alt=""
                  style={{ width: '7.5rem', height: '7.5rem' }}
                  onClick={e => {
                    e.stopPropagation();
                    props.openImage(props.data.media[0]);
                  }}
                />
              )}
              {this.hasMediaImage(props.data.media) === -1 && (
                <img
                  src="./assets/img/photos/no-image-placeholder.jpg"
                  className="img-fluid rounded"
                  alt=""
                  style={{ width: '7.5rem', height: '7.5rem' }}
                />
              )}
            </div>
            <div className="col">
              <div className="card-block pl-0 pr-3 py-2 pt-3">
                <h6 className="card-title mb-1 mt-1 font-weight-bold pointer">
                  {props.data.observationType === 'animal' && (
                    <span>
                      <img
                        src="./assets/img/icons/icon_animal.svg"
                        className="observation-type"
                        alt="animal"
                      />
                      Animal sighting
                      <span className="text-danger d-none">(!)</span>
                    </span>
                  )}
                  {props.data.observationType === 'infrastructure' && (
                    <span>
                      <img
                        src="./assets/img/icons/icon_infrastructure.svg"
                        className="observation-type"
                        alt="Infrastructure"
                      />
                      Point of interest
                    </span>
                  )}
                  {props.data.observationType === 'hwc' && (
                    <span>
                      <img
                        src="./assets/img/icons/icon_hwc.svg"
                        className="observation-type"
                        alt="Human wildlife conflict"
                      />
                      Human wildlife conflict
                    </span>
                  )}
                  {(props.data.observationType === 'offence' ||
                    props.data.observationType === 'human activity') && (
                    <span>
                      <img
                        src="./assets/img/icons/icon_humanactivity.svg"
                        className="observation-type"
                        alt="Human activity"
                      />
                      Human activity
                    </span>
                  )}
                  {props.data.observationType === 'a-find' && (
                    <span>A find</span>
                  )}
                  {(props.data.observationType === 'community' ||
                    props.data.observationType === 'community_work') && (
                    <span>
                      <img
                        src="./assets/img/icons/icon_community.svg"
                        className="observation-type"
                        alt="Community work"
                      />
                      Community work
                    </span>
                  )}
                  {[
                    'animal',
                    'infrastructure',
                    'hwc',
                    'offence',
                    'human activity',
                    'a-find',
                    'community',
                    'community_work'
                  ].indexOf(props.data.observationType) < 0 && (
                    <span>{capitalize(props.data.observationType)}</span>
                  )}
                </h6>
                <p className="card-text text-muted mb-1 font-weight-light">
                  {moment(props.data.when).format('DD MMM YYYY - HH:mm')}{' '}
                  {props.data.agent ? (
                    <span> - {capitalize(props.data.agent.agentName)}</span>
                  ) : (
                    <span></span>
                  )}
                </p>
                <p className="card-text text-muted mb-2 font-weight-light">
                  {props.data.description || 'No description'}
                  {this.state.copying[props.data.description] && (
                    <div className="spinner-border copy-spinner" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {this.state.doneCopying[props.data.description] && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-lg check-icon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                    </svg>
                  )}
                  {!this.state.copying[props.data.description] &&
                    !this.state.doneCopying[props.data.description] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-copy copy-icon"
                        viewBox="0 0 16 16"
                        onClick={() =>
                          this.copyTextToClipboard(props.data.description)
                        }
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                        />
                      </svg>
                    )}
                </p>
                {Array.isArray(props.data.concepts) &&
                  props.data.concepts.map((item, key) => (
                    <span
                      key={key}
                      className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase"
                    >
                      {item.label}
                    </span>
                  ))}
                {!Array.isArray(props.data.concepts) && (
                  <span className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase">
                    {props.data.concepts}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="card rounded-0 border-bottom-0 card-full-width">
            <div
              className="card-header"
              id="headingTwo"
              onClick={() =>
                this.setState({
                  genericDetailsOpen: !this.state.genericDetailsOpen
                })
              }
            >
              <h6 className="mb-0" style={{ float: 'left' }}>
                Generic details
              </h6>
              <div
                className={
                  this.state.genericDetailsOpen
                    ? `super-treeview-triangle-btn-up`
                    : `super-treeview-triangle-btn-down`
                }
              />
            </div>

            {this.state.genericDetailsOpen && (
              <div>
                <div className="list-group px-3">
                  {props.data.group && props.data.group.groupName && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Group
                      </label>
                      <label
                        htmlFor=""
                        className="col-form-label font-weight-light pl-4 detail-container__value"
                      >
                        {props.data.group.groupName}
                        {this.state.copying[props.data.group.groupName] && (
                          <div
                            className="spinner-border copy-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.state.doneCopying[props.data.group.groupName] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-check-lg check-icon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                        )}
                        {!this.state.copying[props.data.group.groupName] &&
                          !this.state.doneCopying[
                            props.data.group.groupName
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-copy copy-icon"
                              viewBox="0 0 16 16"
                              onClick={() =>
                                this.copyTextToClipboard(
                                  props.data.group.groupName
                                )
                              }
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          )}
                      </label>
                    </div>
                  )}
                  {props.data.where && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Degrees
                      </label>
                      <label
                        htmlFor=""
                        className="col-form-label font-weight-light pl-4 detail-container__value"
                      >
                        {getDegMinSec(
                          props.data.where.coordinates[1],
                          props.data.where.coordinates[0]
                        )}
                        {this.state.copying[
                          getDegMinSec(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )
                        ] && (
                          <div
                            className="spinner-border copy-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.state.doneCopying[
                          getDegMinSec(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )
                        ] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-check-lg check-icon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                        )}
                        {!this.state.copying[
                          getDegMinSec(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )
                        ] &&
                          !this.state.doneCopying[
                            getDegMinSec(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-copy copy-icon"
                              viewBox="0 0 16 16"
                              onClick={() =>
                                this.copyTextToClipboard(
                                  getDegMinSec(
                                    props.data.where.coordinates[1],
                                    props.data.where.coordinates[0]
                                  )
                                )
                              }
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          )}
                      </label>
                    </div>
                  )}
                  {props.data.where && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Coordinates
                      </label>
                      {!this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {getWGS(props.data.where.coordinates)}
                          {this.state.copying[
                            getWGS(props.data.where.coordinates)
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getWGS(props.data.where.coordinates)
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getWGS(props.data.where.coordinates)
                          ] &&
                            !this.state.doneCopying[
                              getWGS(props.data.where.coordinates)
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getWGS(props.data.where.coordinates)
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                      {this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {getUTM(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )}
                          {this.state.copying[
                            getUTM(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getUTM(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getUTM(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] &&
                            !this.state.doneCopying[
                              getUTM(
                                props.data.where.coordinates[1],
                                props.data.where.coordinates[0]
                              )
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getUTM(
                                      props.data.where.coordinates[1],
                                      props.data.where.coordinates[0]
                                    )
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                    </div>
                  )}
                  {props.data.where && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Coordinate type
                      </label>
                      <div>
                        <label htmlFor="" className="col-form-label text-muted">
                          WGS84
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value mx-2 p-0 mt-1"
                        >
                          <Switch
                            height={21}
                            width={42}
                            onChange={() =>
                              this.setState({ UTM: !this.state.UTM })
                            }
                            checked={this.state.UTM}
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />{' '}
                        </label>

                        <label htmlFor="" className="col-form-label text-muted">
                          UTM
                        </label>
                      </div>
                    </div>
                  )}
                  {props.data.attributes &&
                    props.data.attributes.findIndex(
                      attr =>
                        attr.key === 'lastUpdateAt' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters mb-1">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Last updated at
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {moment(
                            props.data.attributes.find(
                              attr => attr.key === 'lastUpdateAt'
                            ).value
                          ).format('DD MMM YYYY - HH:mm')}
                        </label>
                      </div>
                    )}
                </div>

                <div
                  id="collapseTwo"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                ></div>
              </div>
            )}
          </div>

          <div className="card rounded-0 border-bottom-0 card-full-width">
            <div
              className="card-header"
              id="headingTwo"
              onClick={() =>
                this.setState({
                  specificDetailsOpen: !this.state.specificDetailsOpen
                })
              }
            >
              <h6 className="mb-0" style={{ float: 'left' }}>
                Specific details
              </h6>
              <div
                className={
                  this.state.specificDetailsOpen
                    ? `super-treeview-triangle-btn-up`
                    : `super-treeview-triangle-btn-down`
                }
              />
            </div>

            {this.state.specificDetailsOpen && (
              <div>
                {props.data.attributes && props.data.attributes.length > 0 && (
                  <div className="list-group px-3">
                    {props.data.observationType === 'animal' && (
                      <div>
                        {props.data.tags && props.data.tags.length > 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Tags
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {Array.isArray(props.data.tags)
                                ? props.data.tags.join(', ')
                                : props.data.tags}

                              {this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] &&
                                !this.state.doneCopying[
                                  Array.isArray(props.data.tags)
                                    ? props.data.tags.join(', ')
                                    : props.data.tags
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        Array.isArray(props.data.tags)
                                          ? props.data.tags.join(', ')
                                          : props.data.tags
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                        {props.data.attributes.findIndex(
                          attr => attr.key === 'nTotal' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Total number
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nTotal'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nTotal'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nTotal'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nTotal'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nTotal'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nTotal'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'nMales' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              No. of males
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nMales'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nMales'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nMales'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nMales'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nMales'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nMales'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'nFemales' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              No. of females
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nFemales'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nFemales'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nFemales'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nFemales'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nFemales'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nFemales'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'nJuveniles' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              No. of juveniles
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nJuveniles'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nJuveniles'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nJuveniles'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nJuveniles'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nJuveniles'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nJuveniles'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'nInfants' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              No. of infants
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nInfants'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nInfants'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nInfants'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nInfants'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nInfants'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nInfants'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'distance' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Distance
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'distance'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'distance'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'distance'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'distance'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'distance'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'distance'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'direction' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Direction
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'direction'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'direction'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'speed' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Speed
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'speed'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'speed'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                      </div>
                    )}

                    {props.data.observationType === 'hwc' && (
                      <div>
                        {props.data.tags && props.data.tags.length > 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Tags
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {Array.isArray(props.data.tags)
                                ? props.data.tags.join(', ')
                                : props.data.tags}
                              {this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] &&
                                !this.state.doneCopying[
                                  Array.isArray(props.data.tags)
                                    ? props.data.tags.join(', ')
                                    : props.data.tags
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        Array.isArray(props.data.tags)
                                          ? props.data.tags.join(', ')
                                          : props.data.tags
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'fullName' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Full name
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'fullName'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'fullName'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'nickname' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Nickname
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nickname'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nickname'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'birthDate' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Birth date
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'birthDate'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'birthDate'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'address' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Address
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'address'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'address'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'idNumber' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              ID number
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'idNumber'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'idNumber'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'sex' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Gender
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'sex'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'sex'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                      </div>
                    )}

                    {(props.data.observationType === 'offence' ||
                      props.data.observationType === 'human activity') && (
                      <div>
                        {props.data.tags && props.data.tags.length > 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Tags
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {Array.isArray(props.data.tags)
                                ? props.data.tags.join(', ')
                                : props.data.tags}
                              {this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] &&
                                !this.state.doneCopying[
                                  Array.isArray(props.data.tags)
                                    ? props.data.tags.join(', ')
                                    : props.data.tags
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        Array.isArray(props.data.tags)
                                          ? props.data.tags.join(', ')
                                          : props.data.tags
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'legality' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Legality
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'legality'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'legality'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'legality'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'legality'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'legality'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'legality'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'fullName' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Full name
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'fullName'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'fullName'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'fullName'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'nickname' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Nickname
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nickname'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nickname'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nickname'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'birthDate' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Birth date
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'birthDate'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'birthDate'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'birthDate'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'address' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Address
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'address'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'address'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'address'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'idNumber' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              ID number
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'idNumber'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'idNumber'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'idNumber'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'sex' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Gender
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'sex'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'sex'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'sex'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'direction' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Direction
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'direction'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'direction'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'direction'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'speed' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Speed
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'speed'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'speed'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'speed'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                      </div>
                    )}

                    {props.data.observationType === 'infrastructure' && (
                      <div>
                        {props.data.tags && props.data.tags.length > 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Tags
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {Array.isArray(props.data.tags)
                                ? props.data.tags.join(', ')
                                : props.data.tags}
                              {this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] &&
                                !this.state.doneCopying[
                                  Array.isArray(props.data.tags)
                                    ? props.data.tags.join(', ')
                                    : props.data.tags
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        Array.isArray(props.data.tags)
                                          ? props.data.tags.join(', ')
                                          : props.data.tags
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'identifier' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Identifier
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'identifier'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'identifier'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'identifier'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'identifier'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'identifier'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'identifier'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'oil' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Oil used
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'oil'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'oil'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'oil'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'oil'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'oil'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'oil'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'rope' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Rope used
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'rope'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'rope'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'rope'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'rope'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'rope'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'rope'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'cloth' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Cloth used
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'cloth'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'cloth'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'cloth'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'cloth'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'cloth'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'cloth'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'chili' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Chili used
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'chili'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'chili'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'chili'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'chili'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'chili'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'chili'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'farmsProtected' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Number of farms protected
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'farmsProtected'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'farmsProtected'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'farmsProtected'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'farmsProtected'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'farmsProtected'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'farmsProtected'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'familiesTrained' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Number of families trained
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'familiesTrained'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'familiesTrained'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'familiesTrained'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'familiesTrained'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'familiesTrained'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'familiesTrained'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'chiliFenceId' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Chili fence ID
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'chiliFenceId'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'chiliFenceId'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'chiliFenceId'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'chiliFenceId'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'chiliFenceId'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'chiliFenceId'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'habitatEUNIS2012' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Habitat EUNIS 2012
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'habitatEUNIS2012'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'habitatEUNIS2012'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'habitatEUNIS2012'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'habitatEUNIS2012'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'habitatEUNIS2012'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr =>
                                            attr.key === 'habitatEUNIS2012'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'planter' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Planter
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'planter'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'planter'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'planter'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'planter'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'planter'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'planter'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                      </div>
                    )}

                    {props.data.observationType === 'Point of Interest' && (
                      <div>
                        {props.data.tags && props.data.tags.length > 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Tags
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {Array.isArray(props.data.tags)
                                ? props.data.tags.join(', ')
                                : props.data.tags}
                              {this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] &&
                                !this.state.doneCopying[
                                  Array.isArray(props.data.tags)
                                    ? props.data.tags.join(', ')
                                    : props.data.tags
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        Array.isArray(props.data.tags)
                                          ? props.data.tags.join(', ')
                                          : props.data.tags
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                        {props.data.attributes.findIndex(
                          attr => attr.key === 'attributes'
                        ) >= 0 && (
                          <div>
                            {props.data.attributes.find(
                              attr => attr.key === 'attributes'
                            ).value.alert__count && (
                              <div className="detail-container no-gutters">
                                <label
                                  htmlFor=""
                                  className="detail-container__title col-form-label text-muted"
                                >
                                  Alert count
                                </label>
                                <label
                                  htmlFor=""
                                  className="col-form-label font-weight-light pl-4 detail-container__value"
                                >
                                  {
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.alert__count
                                  }
                                  {this.state.copying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.alert__count
                                  ] && (
                                    <div
                                      className="spinner-border copy-spinner"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                  {this.state.doneCopying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.alert__count
                                  ] && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-check-lg check-icon"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                    </svg>
                                  )}
                                  {!this.state.copying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.alert__count
                                  ] &&
                                    !this.state.doneCopying[
                                      props.data.attributes.find(
                                        attr => attr.key === 'attributes'
                                      ).value.alert__count
                                    ] && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-copy copy-icon"
                                        viewBox="0 0 16 16"
                                        onClick={() =>
                                          this.copyTextToClipboard(
                                            props.data.attributes.find(
                                              attr => attr.key === 'attributes'
                                            ).value.alert__count
                                          )
                                        }
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                        />
                                      </svg>
                                    )}
                                </label>
                              </div>
                            )}
                            {props.data.attributes.find(
                              attr => attr.key === 'attributes'
                            ).value.confidence__cat && (
                              <div className="detail-container no-gutters">
                                <label
                                  htmlFor=""
                                  className="detail-container__title col-form-label text-muted"
                                >
                                  Confidence cat
                                </label>
                                <label
                                  htmlFor=""
                                  className="col-form-label font-weight-light pl-4 detail-container__value"
                                >
                                  {
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.confidence__cat
                                  }
                                  {this.state.copying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.confidence__cat
                                  ] && (
                                    <div
                                      className="spinner-border copy-spinner"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                  {this.state.doneCopying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.confidence__cat
                                  ] && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-check-lg check-icon"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                    </svg>
                                  )}
                                  {!this.state.copying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.confidence__cat
                                  ] &&
                                    !this.state.doneCopying[
                                      props.data.attributes.find(
                                        attr => attr.key === 'attributes'
                                      ).value.confidence__cat
                                    ] && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-copy copy-icon"
                                        viewBox="0 0 16 16"
                                        onClick={() =>
                                          this.copyTextToClipboard(
                                            props.data.attributes.find(
                                              attr => attr.key === 'attributes'
                                            ).value.confidence__cat
                                          )
                                        }
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                        />
                                      </svg>
                                    )}
                                </label>
                              </div>
                            )}
                            {props.data.attributes.find(
                              attr => attr.key === 'attributes'
                            ).value.frp__MW && (
                              <div className="detail-container no-gutters">
                                <label
                                  htmlFor=""
                                  className="detail-container__title col-form-label text-muted"
                                >
                                  frp MW
                                </label>
                                <label
                                  htmlFor=""
                                  className="col-form-label font-weight-light pl-4 detail-container__value"
                                >
                                  {
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.frp__MW
                                  }
                                  {this.state.copying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.frp__MW
                                  ] && (
                                    <div
                                      className="spinner-border copy-spinner"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                  {this.state.doneCopying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.frp__MW
                                  ] && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-check-lg check-icon"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                    </svg>
                                  )}
                                  {!this.state.copying[
                                    props.data.attributes.find(
                                      attr => attr.key === 'attributes'
                                    ).value.frp__MW
                                  ] &&
                                    !this.state.doneCopying[
                                      props.data.attributes.find(
                                        attr => attr.key === 'attributes'
                                      ).value.frp__MW
                                    ] && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-copy copy-icon"
                                        viewBox="0 0 16 16"
                                        onClick={() =>
                                          this.copyTextToClipboard(
                                            props.data.attributes.find(
                                              attr => attr.key === 'attributes'
                                            ).value.frp__MW
                                          )
                                        }
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                        />
                                      </svg>
                                    )}
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {(props.data.observationType === 'community' ||
                      props.data.observationType === 'community work' ||
                      props.data.observationType === 'community_work') && (
                      <div>
                        {props.data.tags && props.data.tags.length > 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Tags
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {Array.isArray(props.data.tags)
                                ? props.data.tags.join(', ')
                                : props.data.tags}
                              {this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                Array.isArray(props.data.tags)
                                  ? props.data.tags.join(', ')
                                  : props.data.tags
                              ] &&
                                !this.state.doneCopying[
                                  Array.isArray(props.data.tags)
                                    ? props.data.tags.join(', ')
                                    : props.data.tags
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        Array.isArray(props.data.tags)
                                          ? props.data.tags.join(', ')
                                          : props.data.tags
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'nameBeneficiary' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Beneficiary name
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'nameBeneficiary'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nameBeneficiary'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nameBeneficiary'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'nameBeneficiary'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'nameBeneficiary'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'nameBeneficiary'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'beneficiariesMen' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Beneficiaries men
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesMen'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesMen'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesMen'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesMen'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'beneficiariesMen'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr =>
                                            attr.key === 'beneficiariesMen'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'beneficiariesWomen' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Beneficiaries women
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesWomen'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesWomen'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesWomen'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesWomen'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'beneficiariesWomen'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr =>
                                            attr.key === 'beneficiariesWomen'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'beneficiariesTotal' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Beneficiaries total
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesTotal'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesTotal'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesTotal'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'beneficiariesTotal'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'beneficiariesTotal'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr =>
                                            attr.key === 'beneficiariesTotal'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'formality' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Formality
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'formality'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'formality'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'formality'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'formality'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'formality'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'formality'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'actor' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Actor
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'actor'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actor'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actor'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actor'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'actor'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'actor'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'actorType' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Agent type
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'actorType'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actorType'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actorType'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actorType'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'actorType'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'actorType'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'actorName' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Agent name
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'actorName'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actorName'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actorName'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'actorName'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'actorName'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'actorName'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'partners' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Partners
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'partners'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'partners'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'partners'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'partners'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'partners'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'partners'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'venue' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Venue
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'venue'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'venue'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'venue'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'venue'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'venue'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'venue'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'goal' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Goal
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'goal'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'goal'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'goal'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'goal'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'goal'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'goal'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'descriptionActivity' &&
                            !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Description
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'descriptionActivity'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'descriptionActivity'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'descriptionActivity'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'descriptionActivity'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'descriptionActivity'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr =>
                                            attr.key === 'descriptionActivity'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'outcome' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Outcome
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'outcome'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'outcome'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'outcome'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'outcome'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'outcome'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'outcome'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'startAt' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Start
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'startAt'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'startAt'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'startAt'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'startAt'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'startAt'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'startAt'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'endAt' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              End
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'endAt'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'endAt'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'endAt'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'endAt'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'endAt'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'endAt'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'cost' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Cost
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'cost'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'cost'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'cost'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'cost'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'cost'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'cost'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'subsidy' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Subsidy
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'subsidy'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'subsidy'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'subsidy'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'subsidy'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'subsidy'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'subsidy'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'income' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Income
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'income'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'income'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'income'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'income'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'income'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'income'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'justification' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Justification
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'justification'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'justification'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'justification'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'justification'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'justification'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'justification'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'inKind' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              In kind
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'inKind'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'inKind'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'inKind'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'inKind'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'inKind'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'inKind'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'surface' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Surface
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'surface'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'surface'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'surface'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'surface'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'surface'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'surface'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr =>
                            attr.key === 'landOwnership' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Type of land ownership
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'landOwnership'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'landOwnership'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'landOwnership'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'landOwnership'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'landOwnership'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'landOwnership'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'species' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Species
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'species'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'species'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'species'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'species'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'species'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'species'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'planted' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Planted
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'planted'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'planted'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'planted'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'planted'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'planted'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'planted'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}

                        {props.data.attributes.findIndex(
                          attr => attr.key === 'yield' && !isEmpty(attr.value)
                        ) >= 0 && (
                          <div className="detail-container no-gutters">
                            <label
                              htmlFor=""
                              className="detail-container__title col-form-label text-muted"
                            >
                              Yield
                            </label>
                            <label
                              htmlFor=""
                              className="col-form-label font-weight-light pl-4 detail-container__value"
                            >
                              {
                                props.data.attributes.find(
                                  attr => attr.key === 'yield'
                                ).value
                              }
                              {this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'yield'
                                ).value
                              ] && (
                                <div
                                  className="spinner-border copy-spinner"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                              {this.state.doneCopying[
                                props.data.attributes.find(
                                  attr => attr.key === 'yield'
                                ).value
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-lg check-icon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                              )}
                              {!this.state.copying[
                                props.data.attributes.find(
                                  attr => attr.key === 'yield'
                                ).value
                              ] &&
                                !this.state.doneCopying[
                                  props.data.attributes.find(
                                    attr => attr.key === 'yield'
                                  ).value
                                ] && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-copy copy-icon"
                                    viewBox="0 0 16 16"
                                    onClick={() =>
                                      this.copyTextToClipboard(
                                        props.data.attributes.find(
                                          attr => attr.key === 'yield'
                                        ).value
                                      )
                                    }
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                  </svg>
                                )}
                            </label>
                          </div>
                        )}
                      </div>
                    )}

                    {props.data.group && props.data.group.groupId.length === 4 && (
                      <div>
                        {props.data.attributes.map((item, key) => (
                          <div>
                            {!isEmpty(item.value) &&
                              item.key !== 'prevVersions' && (
                                <div className="detail-container no-gutters">
                                  <label
                                    htmlFor=""
                                    className="detail-container__title col-form-label text-muted"
                                  >
                                    {item.key}
                                  </label>
                                  <label
                                    htmlFor=""
                                    className="col-form-label font-weight-light pl-4 detail-container__value"
                                  >
                                    {typeof item.value === 'object' && (
                                      <div>
                                        {Object.keys(item.value).map(
                                          (itemNest, keyNest) => (
                                            <div>
                                              {typeof item.value[itemNest] ===
                                                'object' && (
                                                <div>
                                                  {Object.keys(
                                                    item.value[itemNest]
                                                  ).map((deepNest, deepKey) => (
                                                    <div>
                                                      {
                                                        item.value[itemNest][
                                                          deepNest
                                                        ]
                                                      }
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                              {Array.isArray(
                                                item.value[itemNest]
                                              ) && (
                                                <div>
                                                  {item.value[itemNest].map(
                                                    (deepNest, deepKey) => (
                                                      <div>
                                                        {deepNest.value}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                              {typeof item.value[itemNest] !==
                                                'object' &&
                                                !Array.isArray(
                                                  item.value[itemNest]
                                                ) &&
                                                item.value[itemNest]}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                    {Array.isArray(item.value) && (
                                      <div>
                                        {item.value.map((itemNest, keyNest) => (
                                          <div>{itemNest.value}</div>
                                        ))}
                                      </div>
                                    )}
                                    {typeof item.value !== 'object' &&
                                      !Array.isArray(item.value) &&
                                      item.value}
                                  </label>
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          {(this.hasMediaImage(props.data.media) > -1 ||
            this.hasMediaPDF(props.data.media) > -1 ||
            (props.data.graph && props.data.graph.length > 0) ||
            (props.data.files && props.data.files.length > 0)) && (
            <div className="card rounded-0 border-bottom-0 card-full-width">
              <div
                className="card-header"
                id="headingTwo"
                onClick={() =>
                  this.setState({
                    attachmentsOpen: !this.state.attachmentsOpen
                  })
                }
              >
                <h6 className="mb-0" style={{ float: 'left' }}>
                  Attachments
                </h6>
                <div
                  className={
                    this.state.attachmentsOpen
                      ? `super-treeview-triangle-btn-up`
                      : `super-treeview-triangle-btn-down`
                  }
                />
              </div>

              {this.state.attachmentsOpen && (
                <div className="mb-4">
                  {this.hasMediaImage(props.data.media) > -1 && (
                    <div className="list-group px-3 mt-4">
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label"
                        >
                          <h6>Photos</h6>
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          <span className="badge badge-primary badge-pill">
                            {props.data.media.length}
                          </span>
                        </label>
                      </div>
                    </div>
                  )}

                  {this.hasMediaImage(props.data.media) > -1 && (
                    <div className="container">
                      <div className="row" aria-labelledby="headingOne">
                        {props.data.media.map((item, key) => {
                          return item.Media.mediaType === 'jpeg' ? (
                            <div key={key} className="col-4">
                              <PhotoItem
                                image={item}
                                openImage={props.openImage}
                              />
                            </div>
                          ) : (
                            <div />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {this.hasMediaPDF(props.data.media) > -1 && (
                    <div className="list-group px-3 mt-4">
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label"
                        >
                          <h6>Documents</h6>
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          <span className="badge badge-primary badge-pill">
                            {props.data.media.length}
                          </span>
                        </label>
                      </div>
                    </div>
                  )}

                  {this.hasMediaPDF(props.data.media) > -1 && (
                    <div className="container">
                      {props.data.media.map((item, key) => {
                        return item.Media.mediaType === 'pdf' ? (
                          <div>
                            <PDFItem key={key} item={item} />
                          </div>
                        ) : (
                          <div />
                        );
                      })}
                    </div>
                  )}

                  {props.data.graph && props.data.graph.length > 0 && (
                    <div className="list-group px-3 mt-4">
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label"
                        >
                          <h6>Photos</h6>
                        </label>
                      </div>
                    </div>
                  )}

                  {props.data.graph && props.data.graph.length > 0 && (
                    <div className="container">
                      <div className="row" aria-labelledby="headingOne">
                        <GraphItem graph={props.data.graph.graph} />
                      </div>
                    </div>
                  )}

                  {props.data.files && props.data.files.length > 0 && (
                    <div className="list-group px-3 mt-4">
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label"
                        >
                          <h6>Files</h6>
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          <span className="badge badge-primary badge-pill">
                            {props.data.files.length}
                          </span>
                        </label>
                      </div>
                    </div>
                  )}

                  {props.data.files && props.data.files.length > 0 && (
                    <div className="container">
                      <div className="row" aria-labelledby="headingOne">
                        {props.data.files.map((item, key) => (
                          <div className="col-4">
                            <FileItem key={key} file={item} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {props.data.attributes &&
            props.data.attributes.length > 0 &&
            props.data.attributes.findIndex(
              attr => attr.key === 'prevVersions' && !isEmpty(attr.value)
            ) >= 0 && (
              <div className="card rounded-0 border-bottom-0 card-full-width">
                <div
                  className="card-header"
                  id="headingTwo"
                  onClick={() =>
                    this.setState({ historyOpen: !this.state.historyOpen })
                  }
                >
                  <h6 className="mb-0" style={{ float: 'left' }}>
                    Edit history
                  </h6>
                  <div
                    className={
                      this.state.historyOpen
                        ? `super-treeview-triangle-btn-up`
                        : `super-treeview-triangle-btn-down`
                    }
                  />
                </div>

                {this.state.historyOpen && (
                  <div>
                    <div className="list-group">
                      <div className="container no-gutters">
                        <div className="row">
                          <span className="col col-form-label detail-container__history">
                            Date &amp; Time
                          </span>
                          <span className="col col-form-label detail-container__history">
                            Attribute
                          </span>
                          <span className="col col-form-label detail-container__history">
                            Old
                          </span>
                          <span className="col col-form-label detail-container__history">
                            New
                          </span>
                          <span className="col col-form-label detail-container__history">
                            Editor
                          </span>
                        </div>
                      </div>
                    </div>
                    {Array.isArray(
                      props.data.attributes.find(
                        attr => attr.key === 'prevVersions'
                      ).value
                    ) &&
                      this.getDifference(
                        props.data.attributes.find(
                          attr => attr.key === 'prevVersions'
                        ).value
                      ).map((item, key) => (
                        <div key={key}>
                          {Object.keys(item).map((prop, propKey) => (
                            <div key={propKey}>
                              {((prop !== 'timestamp' &&
                                prop !== 'user' &&
                                prop !== 'editor' &&
                                prop !== 'appVersion' &&
                                prop !== 'location' &&
                                prop !== 'geometry') ||
                                (prop === 'geometry' &&
                                  Object.keys(item[prop].coordinates).length >
                                    1)) && (
                                <div>
                                  <div className="list-group">
                                    <div className="container no-gutters">
                                      <div className="row">
                                        <span className="col col-form-label font-weight-light detail-container__history">
                                          {moment(item.timestamp).format(
                                            'DD MMM YYYY - HH:mm'
                                          )}
                                        </span>
                                        <span className="col col-form-label font-weight-light detail-container__history">
                                          {prop}
                                        </span>
                                        <span className="col col-form-label font-weight-light detail-container__history">
                                          {prop === 'geometry'
                                            ? Object.keys(
                                                item[prop].coordinates
                                              ).length > 1
                                            : ''}
                                          {prop === 'geometry' &&
                                          Object.keys(item[prop].coordinates)
                                            .length > 1
                                            ? item[prop].coordinates[0].prev +
                                              ', ' +
                                              item[prop].coordinates[1].prev
                                            : item[prop].prev}
                                        </span>
                                        <span className="col col-form-label font-weight-light detail-container__history">
                                          {prop === 'geometry' &&
                                          Object.keys(item[prop].coordinates)
                                            .length > 1
                                            ? item[prop].coordinates[0].next +
                                              ', ' +
                                              item[prop].coordinates[1].next
                                            : item[prop].next}
                                        </span>
                                        <span className="col col-form-label font-weight-light detail-container__history">
                                          {item.editor}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}

          <div className="card rounded-0 border-bottom-0 card-full-width">
            <div
              className="card-header"
              id="headingTwo"
              onClick={() =>
                this.setState({
                  technicalDetailsOpen: !this.state.technicalDetailsOpen
                })
              }
            >
              <h6 className="mb-0" style={{ float: 'left' }}>
                Technical details
              </h6>
              <div
                className={
                  this.state.technicalDetailsOpen
                    ? `super-treeview-triangle-btn-up`
                    : `super-treeview-triangle-btn-down`
                }
              />
            </div>

            {this.state.technicalDetailsOpen && (
              <div>
                {props.data.attributes && props.data.attributes.length > 0 && (
                  <div className="list-group px-3">
                    {props.data.observationId && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Observation ID
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {props.data.observationId}
                          {this.state.copying[props.data.observationId] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[props.data.observationId] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[props.data.observationId] &&
                            !this.state.doneCopying[
                              props.data.observationId
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.observationId
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.trackId && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Track ID
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {props.data.trackId}
                          {this.state.copying[props.data.trackId] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[props.data.trackId] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[props.data.trackId] &&
                            !this.state.doneCopying[props.data.trackId] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(props.data.trackId)
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {((props.data.agent && props.data.agent.agentId) ||
                      props.data.attributes.findIndex(
                        attr => attr.key === 'userid' && !isEmpty(attr.value)
                      ) >= 0) && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          User ID of Creator
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {props.data.attributes.find(
                            attr => attr.key === 'userid'
                          )
                            ? props.data.attributes.find(
                                attr => attr.key === 'userid'
                              ).value
                            : props.data.agent.agentId}
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'userid'
                            )
                              ? props.data.attributes.find(
                                  attr => attr.key === 'userid'
                                ).value
                              : props.data.agent.agentId
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'userid'
                            )
                              ? props.data.attributes.find(
                                  attr => attr.key === 'userid'
                                ).value
                              : props.data.agent.agentId
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'userid'
                            )
                              ? props.data.attributes.find(
                                  attr => attr.key === 'userid'
                                ).value
                              : props.data.agent.agentId
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'userid'
                              )
                                ? props.data.attributes.find(
                                    attr => attr.key === 'userid'
                                  ).value
                                : props.data.agent.agentId
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'userid'
                                    )
                                      ? props.data.attributes.find(
                                          attr => attr.key === 'userid'
                                        ).value
                                      : props.data.agent.agentId
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.group && props.data.group.groupId && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Group ID
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {props.data.group.groupId}
                          {this.state.copying[props.data.group.groupId] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[props.data.group.groupId] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[props.data.group.groupId] &&
                            !this.state.doneCopying[
                              props.data.group.groupId
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.group.groupId
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.attributes.findIndex(
                      attr => attr.key === 'editorId' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          User ID of Editor
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'editorId'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'editorId'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.attributes.findIndex(
                      attr => attr.key === 'EUNIS' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          EUNIS
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {
                            props.data.attributes.find(
                              attr => attr.key === 'EUNIS'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'EUNIS'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'EUNIS'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'EUNIS'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'EUNIS'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'EUNIS'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.attributes.findIndex(
                      attr => attr.key === 'X3035' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          X3035
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {
                            props.data.attributes.find(
                              attr => attr.key === 'X3035'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'X3035'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'X3035'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'X3035'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'X3035'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'X3035'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.attributes.findIndex(
                      attr => attr.key === 'Y3035' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Y3035
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {
                            props.data.attributes.find(
                              attr => attr.key === 'Y3035'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'Y3035'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'Y3035'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'Y3035'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'Y3035'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'Y3035'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.attributes.findIndex(
                      attr => attr.key === 'appVersion' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Cluey version
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light pl-4 detail-container__value"
                        >
                          {
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'appVersion'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'appVersion'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ObservationModal;
