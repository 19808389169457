import React, { Component } from 'react';

class GraphItem extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <div className="card-body px-1 pb-0">
        <div className="row">
          <div className="col"></div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm float-right"
            >
              Gephi
              <svg
                className="bi bi-box-arrow-up-right"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z"
                  clip-rule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <img
          src={props.graph}
          className="img-fluid rounded"
          alt=""
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
    );
  }
}

export default GraphItem;
