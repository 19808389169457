import * as types from './actionTypes';

const reducer = (
  state = {
    searchQuery: '',
    lng: 0,
    lat: 0,
    north: null,
    east: null,
    west: null,
    south: null,
    zoom: 2,
    areas: [],
    concepts: [],
    mode: true,
    dataSources: [],
    agents: [],
    start: null,
    end: null
  },
  action
) => {
  switch (action.type) {
    case types.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload.query
      };
    case types.SET_MAP_COORDINATES:
      return {
        ...state,
        lng: action.payload.lng,
        lat: action.payload.lat,
        north: action.payload.north,
        east: action.payload.east,
        south: action.payload.south,
        west: action.payload.west,
        zoom: action.payload.zoom
      };
    case types.SET_SELECTED_AREAS:
      return {
        ...state,
        areas: action.payload.selectedAreas
      };
    case types.SET_CONCEPTS:
      return {
        ...state,
        concepts: action.payload.selectedConcepts
      };
    case types.SET_MODE:
      return {
        ...state,
        mode: action.payload.mode
      };
    case types.SET_DATA_SOURCES:
      return {
        ...state,
        dataSources: action.payload.selectedDataSources
      };
    case types.SET_AGENTS:
      return {
        ...state,
        agents: action.payload.selectedAgents
      };
    case types.SET_START_END:
      return {
        ...state,
        start: action.payload.start,
        end: action.payload.end
      };
    default:
      return state;
  }
};

const searchQuery = state => state.searchQuery;

const querySelectors = {
  searchQuery
};

export default reducer;
export { querySelectors };
