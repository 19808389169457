import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import ObservationItem from './ObservationItem';
import TrackItem from './TrackItem';
import actions from '../../../store/appActions';
import QueryBuilder from '../../../query-builder';

import './Observation.scss';

class ObservationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollToTop: false,
      timelineLength: 0
    };

    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected !== this.props.selected) {
      this.ref.current.scrollTo({
        left: 0,
        top: document.getElementById(this.props.selected).offsetTop - 85,
        behavior: 'smooth'
      });
    }

    if (prevProps.scrollToTop === false && this.props.scrollToTop === true) {
      this.ref.current.scrollTo({
        left: 0,
        top: 0
      });
    }

    if (prevProps.timelineResults !== this.props.timelineResults) {
      this.setState({ timelineLength: this.props.timelineResults.length });
    }
  }

  onScroll = () => {
    if (
      this.ref.current.scrollTop + this.ref.current.clientHeight >=
        this.ref.current.scrollHeight - 500 &&
      this.props.timelineFetching === false &&
      this.state.timelineLength < this.props.timelineTotal
    ) {
      this.props.startTimelineFetch();
      this.props.getTimeline(true);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  getEntireTrack = async id => {
    let query = new QueryBuilder.SearchQuery().withQueryText(
      'entityId:"' + id + '"'
    );

    await this.props.getEntireTrack('track', '0', query);
    await this.props.getTrackInfo(id);
    this.props.getTrackObservations(id);
  };

  render() {
    const { ...props } = this.props;
    return (
      <div className="h-100">
        <div className="card border-bottom-0 rounded-0">
          <div className="card-header pb-2 pr-3 mr-0">
            <h6 className="float-left pt-1 pb-0 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                className="mr-2"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 1.67c.955 0 1.845.467 2.39 1.247l.105.16l8.114 13.548a2.914 2.914 0 0 1-2.307 4.363l-.195.008H3.882a2.914 2.914 0 0 1-2.582-4.2l.099-.185l8.11-13.538A2.91 2.91 0 0 1 12 1.67M12.01 15l-.127.007a1 1 0 0 0 0 1.986L12 17l.127-.007a1 1 0 0 0 0-1.986zM12 8a1 1 0 0 0-.993.883L11 9v4l.007.117a1 1 0 0 0 1.986 0L13 13V9l-.007-.117A1 1 0 0 0 12 8"
                />
              </svg>
              Alerts ({props.alertsTotal})
            </h6>
            <button
              type="button"
              className="btn btn-outline-primary btn-sm float-right "
              aria-expanded="false"
              onClick={() => props.setAlertTimeline(false)}
            >
              <span>Close Alerts</span>
            </button>
            <span
              type="button"
              className="btn btn-outline-info btn-sm float-right d-none "
              data-toggle="collapse"
              href="#multiCollapseExample2"
              role="button"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
            >
              CSV
              <svg
                className="bi bi-download ml-1"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
        <div
          ref={this.ref}
          className={
            this.props.resultsCollapsed
              ? 'list-container no-scroll'
              : 'list-container'
          }
        >
          <div>
            {props.alertResults
              .reduce((filtered, result) => {
                // Find observation object
                let foundObservation = result.extracted.content.find(val => {
                  if (val.Observation) {
                    return true;
                  }
                  return false;
                });

                // If observation object exists push into array
                if (foundObservation && foundObservation.Observation) {
                  filtered.push(foundObservation.Observation);
                }

                let foundGeoFeature = result.extracted.content.find(val => {
                  if (val.GeoFeature) {
                    return true;
                  }
                  return false;
                });

                // If observation object exists push into array
                if (foundGeoFeature && foundGeoFeature.GeoFeature) {
                  filtered.push(foundGeoFeature.GeoFeature);
                }

                // Return array
                return filtered;
              }, [])
              .map((item, key) => {
                if (item.observationId)
                  return (
                    <ObservationItem
                      key={key}
                      item={item}
                      selected={
                        this.props.selected === item.observationId
                          ? true
                          : false
                      }
                      setSelectedItem={props.setSelectedItem}
                      openImage={props.openImageModal}
                      openObservation={props.openObservationModal}
                    />
                  );
                else if (item.featureId)
                  return (
                    <TrackItem
                      key={key}
                      item={item}
                      selected={
                        this.props.selected === item.featureId ? true : false
                      }
                      setSelectedItem={selectedItem => {
                        props.setSelectedItem(selectedItem);
                        this.getEntireTrack(item.featureId);
                      }}
                      openTrack={props.openTrackModal}
                    />
                  );
              })}
            {props.alertResults.reduce((filtered, result) => {
              // Find observation object
              let foundObservation = result.extracted.content.find(val => {
                if (val.Observation) {
                  return true;
                }
                return false;
              });

              // If observation object exists push into array
              if (foundObservation && foundObservation.Observation) {
                filtered.push(foundObservation.Observation);
              }

              let foundGeoFeature = result.extracted.content.find(val => {
                if (val.GeoFeature) {
                  return true;
                }
                return false;
              });

              // If observation object exists push into array
              if (foundGeoFeature && foundGeoFeature.GeoFeature) {
                filtered.push(foundGeoFeature.GeoFeature);
              }

              // Return array
              return filtered;
            }, []).length === 0 && (
              <div className="no-results-container">
                <span className="no-results-container-text">
                  There are no tracks, observations or agents within the current
                  selection. Zoom out on the map or change the filter and time
                  settings.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const getTimelineResults = state => state.timeline.results || [];
const getTimelineTotal = state => state.timeline.total || 0;
const getTimelineFetching = state => state.timeline.fetchingList || false;
const getScrollToTop = state => state.timeline.scrollToTop || false;
const getAlertsTotal = state => state.timeline.alerts.total || 0;
const getAlerts = state => state.timeline.alerts.results || [];

const mapStateToProps = createSelector(
  getTimelineResults,
  getTimelineTotal,
  getTimelineFetching,
  getScrollToTop,
  getAlertsTotal,
  getAlerts,
  (
    timelineResults,
    timelineTotal,
    timelineFetching,
    scrollToTop,
    alertsTotal,
    alertResults
  ) => ({
    timelineResults,
    timelineTotal,
    timelineFetching,
    scrollToTop,
    alertsTotal,
    alertResults
  })
);

const mapDispatchToProps = {
  setSelectedItem: actions.timeline.setSelectedItem,
  getEntireTrack: actions.layer.getEntireTrack,
  getTrackInfo: actions.layer.getTrackInfo,
  getTrackObservations: actions.layer.getTrackObservations,
  openImageModal: actions.modals.openImageModal,
  openObservationModal: actions.modals.openObservationModal,
  openTrackModal: actions.modals.openTrackModal,
  getTimeline: actions.timeline.getTimeline,
  startTimelineFetch: actions.timeline.startTimelineFetch,
  setAlertTimeline: actions.ontology.setAlertTimeline
};

export default connect(mapStateToProps, mapDispatchToProps)(ObservationList);
