import './Filter.scss';
import React, { Component } from 'react';
import FilterTree from './FilterTree';
import { some, find, isObject } from 'lodash';
import Switch from 'react-switch';
import { connect } from 'react-redux';

import { createSelector } from 'reselect';
import actions from '../../../store/appActions';

class Filter extends Component {
  //* add scroll to sidebar
  state = {
    conceptQuery: [],
    filterList: [],
    excludeList: [],
    expandedList: [],
    searchQuery: '',
    totalConcepts: 0,
    allConceptSelected: false,
    dataSourcesOpen: true,
    agentsOpen: true,
    filtersOpen: true,
    selectedDataSources: [],
    selectedAgents: [],
    mode: true,
    concepts: []
  };

  grabFilterList = async selectedNode => {
    const { filterList, excludeList, conceptQuery } = this.state;
    let conceptsList = [];
    let match = find(filterList, { id: selectedNode.id });
    if (!match) {
      this.setState({ filterList: filterList.concat(selectedNode) });
      conceptsList = [...conceptQuery, selectedNode.id];
    } else {
      let filtered = filterList.filter(node => {
        return node.id !== selectedNode.id;
      });

      conceptsList = conceptQuery.filter(item => {
        if (!isObject(item)) {
          return item !== selectedNode.id;
        }
        return false;
      });

      this.setState({ filterList: filtered });
    }

    let matchInExcludeList = find(excludeList, { id: selectedNode.id });
    if (matchInExcludeList) {
      let newExcludeList = excludeList.filter(node => {
        return node.id !== selectedNode.id;
      });
      this.setState({ excludeList: newExcludeList });

      conceptsList = conceptsList.filter(item => {
        if (isObject(item)) {
          return item.not !== selectedNode.id;
        }
        return true;
      });
    }

    await this.setState({ conceptQuery: conceptsList });
    this.setConcepts(this.state.conceptQuery);
  };

  grabExcludeNode = async selectedNode => {
    const { filterList, excludeList, conceptQuery } = this.state;
    if (selectedNode) {
      let conceptsList = [];

      let match = find(excludeList, { id: selectedNode.id });
      if (!match) {
        this.setState({
          excludeList: this.state.excludeList.concat(selectedNode)
        });

        //* Create a singleton
        let exclude = new (function() {
          this.not = selectedNode.id;
        })();

        if (exclude.not) {
          conceptsList = [...this.state.conceptQuery, exclude];
        }
      } else {
        let filtered = excludeList.filter(node => node.id !== selectedNode.id);

        conceptsList = conceptQuery.filter(item => {
          if (isObject(item)) {
            return item.not !== selectedNode.id;
          }
          return true;
        });

        this.setState({ excludeList: filtered });
      }

      let matchInFilterList = find(filterList, { id: selectedNode.id });
      if (matchInFilterList) {
        let newFilterList = filterList.filter(node => {
          return node.id !== selectedNode.id;
        });
        this.setState({ filterList: newFilterList });

        conceptsList = conceptsList.filter(item => {
          if (!isObject(item)) {
            return item !== selectedNode.id;
          }
          return true;
        });
      }

      await this.setState({ conceptQuery: conceptsList });
      this.setConcepts(this.state.conceptQuery);
    }
  };

  grabExpandedNodes = async node => {
    if (node && this.state.expandedList.some(value => value.id === node.id)) {
      this.setState({
        expandedList: this.state.expandedList.filter(
          value => value.id !== node.id
        )
      });
    } else {
      if (node !== null)
        await this.setState({
          expandedList: [...this.state.expandedList, node]
        });
    }
  };

  handleChange = event => {
    this.setState({ searchQuery: event.target.value });
  };

  filterListOnCountAndQuery(array, searchString) {
    let results = [];
    let totalConceptsWithoutSearch = 0;
    let totalConceptsWithSearch = 0;

    const removeAllWithoutCount = (result, object) => {
      if (object.count) {
        if (Array.isArray(object.children)) {
          const nodes = object.children.reduce(removeAllWithoutCount, []);
          if (nodes.length) {
            totalConceptsWithoutSearch = totalConceptsWithoutSearch + 1;
            result.push({ ...object, children: nodes });
          } else {
            totalConceptsWithoutSearch = totalConceptsWithoutSearch + 1;
            delete object.children;
            result.push(object);
          }
        } else {
          totalConceptsWithoutSearch = totalConceptsWithoutSearch + 1;
          result.push(object);
        }
      }

      return result;
    };

    // Remove all concepts without count
    results = array.reduce(removeAllWithoutCount, []);

    const matchSearchQuery = (result, object) => {
      if (Array.isArray(object.children) && object.children.length) {
        const nodes = object.children.reduce(matchSearchQuery, []);
        if (
          nodes.length > 0 ||
          object.label.toLowerCase().includes(searchString.toLowerCase())
        ) {
          totalConceptsWithSearch = totalConceptsWithSearch + 1;
          result.push({ ...object, children: nodes });
        }
      } else {
        if (object.label.toLowerCase().includes(searchString.toLowerCase())) {
          totalConceptsWithSearch = totalConceptsWithSearch + 1;
          result.push(object);
        }
      }

      return result;
    };

    // Remove all concepts that do not match search query
    if (searchString.length > 0) results = results.reduce(matchSearchQuery, []);

    return {
      list: results,
      totalConcepts:
        searchString.length > 0
          ? totalConceptsWithSearch
          : totalConceptsWithoutSearch
    };
  }

  removeFromIncludeList = async id => {
    await this.setState({
      filterList: this.state.filterList.filter(item => item.id !== id),
      conceptQuery: this.state.conceptQuery.filter(item => {
        if (isObject(item)) {
          return true;
        } else {
          return item !== id;
        }
      })
    });
    this.setConcepts(this.state.conceptQuery);
  };

  removeFromExcludeList = async id => {
    await this.setState({
      excludeList: this.state.excludeList.filter(item => item.id !== id),
      conceptQuery: this.state.conceptQuery.filter(item => {
        if (isObject(item)) {
          return item.not !== id;
        } else {
          return true;
        }
      })
    });
    this.setConcepts(this.state.conceptQuery);
  };

  collapseDataSources = () => {
    if (this.state.dataSourcesOpen) {
      this.setState({ dataSourcesOpen: false });
    } else {
      this.setState({ dataSourcesOpen: true });
    }
  };

  collapseAgents = () => {
    if (this.state.agentsOpen) {
      this.setState({ agentsOpen: false });
    } else {
      this.setState({ agentsOpen: true });
    }
  };

  collapseFilters = () => {
    if (this.state.filtersOpen) {
      this.setState({ filtersOpen: false });
    } else {
      this.setState({ filtersOpen: true });
    }
  };

  checkDataSource = async value => {
    if (some(this.state.selectedDataSources, ['name', value.name])) {
      let newDataSources = this.state.selectedDataSources.filter(
        item => item.name !== value.name
      );
      await this.props.setSelectedDataSources(newDataSources);
      await this.setState({
        selectedDataSources: newDataSources
      });
    } else {
      let newDataSources = [...this.state.selectedDataSources, value];
      this.props.setSelectedDataSources(newDataSources);
      await this.setState({
        selectedDataSources: newDataSources
      });
    }

    this.props.fetchAllData();
  };

  checkAllDataSources = async () => {
    if (
      this.state.selectedDataSources.length === this.props.dataSources.length
    ) {
      await this.props.setSelectedDataSources([]);
      await this.setState({
        selectedDataSources: []
      });
    } else {
      await this.props.setSelectedDataSources(this.props.dataSources);
      await this.setState({
        selectedDataSources: this.props.dataSources
      });
    }

    this.props.fetchAllData();
  };

  checkAgent = async value => {
    if (some(this.state.selectedAgents, ['name', value.name])) {
      let newAgents = this.state.selectedAgents.filter(
        item => item.name !== value.name
      );
      await this.props.setSelectedAgents(newAgents);
      await this.setState({
        selectedAgents: newAgents
      });
    } else {
      let newAgents = [...this.state.selectedAgents, value];
      await this.props.setSelectedAgents(newAgents);
      await this.setState({
        selectedAgents: newAgents
      });
    }

    this.props.fetchAllData();
  };

  checkAllAgents = async () => {
    if (this.state.selectedAgents.length === this.props.agents.length) {
      await this.props.setSelectedAgents([]);
      await this.setState({
        selectedAgents: []
      });
    } else {
      await this.props.setSelectedAgents(this.props.agents);
      await this.setState({
        selectedAgents: this.props.agents
      });
    }

    this.props.fetchAllData();
  };

  changeMode = async value => {
    await this.props.setMode(value);
    await this.setState({ mode: value });
    this.props.fetchAllData();
  };

  setConcepts = async data => {
    await this.props.setSelectedConcepts(data);
    await this.setState({
      concepts: data
    });
    this.props.fetchAllData();
  };

  render() {
    const { filterList, excludeList, searchQuery } = this.state;
    const { ontology } = this.props;

    let filteredFilterList = this.filterListOnCountAndQuery(
      ontology,
      searchQuery
    );

    return (
      <div
        id="filter"
        className={
          this.props.filterCollapsed
            ? 'px-0 col-0 filter no-scroll'
            : !this.props.filterExpanded
            ? 'px-0 col-2 filter'
            : 'px-0 col-3 filter'
        }
      >
        {!this.props.filterCollapsed && (
          <div id="accordion">
            <div className="card rounded-0 border-bottom-0">
              <div className="card-header" id="headingOne">
                <h6 className="mb-0" style={{ float: 'left' }}>
                  Filters
                </h6>
                <div
                  className={
                    this.state.filtersOpen
                      ? `super-treeview-triangle-btn-down`
                      : `super-treeview-triangle-btn-up`
                  }
                  onClick={() => this.collapseFilters()}
                />
              </div>

              {this.state.filtersOpen && (
                <div>
                  <form onSubmit={e => e.preventDefault()}>
                    <div className="row mt-2">
                      <div className="col">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item border-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Find concepts"
                              onSubmit={this.prevent}
                              onChange={this.handleChange}
                            />
                          </li>
                          <li className="list-group-item border-0 switch-container">
                            <div className="row no-negative-margin">
                              <label
                                htmlFor=""
                                className="text-muted and-label"
                              >
                                AND
                              </label>
                              <label
                                htmlFor=""
                                className="font-weight-light m-0 p-0 mt-1 switch-label"
                              >
                                <Switch
                                  height={18}
                                  width={36}
                                  onChange={this.changeMode}
                                  checked={this.state.mode}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                />
                              </label>
                              <label htmlFor="" className="text-muted or-label">
                                OR
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form>

                  <div className="list-group px-3">
                    <div>
                      {filterList.map(node => {
                        return (
                          <button
                            key={node.id}
                            type="button"
                            className="btn btn-outline-success btn-sm ml-1 mt-1"
                          >
                            <span key={node.id}>{node.label}</span>
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-x"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() =>
                                this.removeFromIncludeList(node.id)
                              }
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              ></path>
                            </svg>
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  <div className="list-group px-3">
                    <div>
                      {excludeList.map(node => {
                        return (
                          <button
                            type="button"
                            key={node.id}
                            className="btn btn-outline-danger btn-sm ml-1 mt-1"
                          >
                            <span key={node.id}>{node.label}</span>
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-x"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() =>
                                this.removeFromExcludeList(node.id)
                              }
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              ></path>
                            </svg>
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body px-0 pt-1">
                      <div className="list-group list-group-flush">
                        <div className="list-group-item border-0 mt-0 py-0">
                          <div className="custom-control pl-3">
                            <label className="text-muted"></label>
                            <label className="text-muted float-right small">
                              {`${this.state.filterList.length +
                                this.state.excludeList.length} of ${
                                filteredFilterList.totalConcepts
                              }`}{' '}
                              selected
                            </label>
                            {ontology.length > 0 && (
                              <FilterTree
                                ontology={filteredFilterList.list}
                                includeList={this.state.filterList}
                                excludeList={this.state.excludeList}
                                expandedList={this.state.expandedList}
                                bubbleUpFilterList={value =>
                                  this.grabFilterList(value)
                                }
                                bubbleUpExcludeNode={value =>
                                  this.grabExcludeNode(value)
                                }
                                bubbleUpExpandedNodes={value =>
                                  this.grabExpandedNodes(value)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="card rounded-0 border-bottom-0">
              <div className="card-header" id="headingTwo">
                <h6 className="mb-0" style={{ float: 'left' }}>
                  Data sources
                </h6>
                <div
                  className={
                    this.state.dataSourcesOpen
                      ? `super-treeview-triangle-btn-down`
                      : `super-treeview-triangle-btn-up`
                  }
                  onClick={() => this.collapseDataSources()}
                />
              </div>

              {this.state.dataSourcesOpen && (
                <div>
                  <div className="list-group px-3">
                    <div>
                      {this.state.selectedDataSources.map(node => {
                        return (
                          <button
                            key={node.name}
                            type="button"
                            className="btn btn-outline-success btn-sm ml-1 mt-1"
                          >
                            <span>{node.value}</span>
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-x"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => this.checkDataSource(node)}
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              ></path>
                            </svg>
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    id="collapseTwo"
                    className="collapse show"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body facets">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item border-0 mt-0 py-0">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="allFacets"
                            onChange={() => this.checkAllDataSources()}
                            checked={this.props.dataSources
                              .map(item => item.name)
                              .every(val => {
                                return (
                                  this.state.selectedDataSources
                                    .map(item => item.name)
                                    .indexOf(val) >= 0
                                );
                              })}
                          />
                          <label className="text-muted" htmlFor="allFacets">
                            All
                          </label>
                          <label className="text-muted float-right small">
                            {this.state.selectedDataSources.length} of{' '}
                            {this.props.dataSources.length}
                          </label>
                        </li>
                        {this.props.dataSources.map(facet => {
                          return (
                            <li
                              className="list-group-item border-0 mt-0 py-0"
                              key={facet.name}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={facet.name}
                                onChange={() => this.checkDataSource(facet)}
                                checked={some(this.state.selectedDataSources, [
                                  'name',
                                  facet.name
                                ])}
                              />
                              <label className="" htmlFor={facet.name}>
                                {facet.value} ({facet.count})
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="card rounded-0 border-bottom-0">
              <div className="card-header" id="headingTwo">
                <h6 className="mb-0" style={{ float: 'left' }}>
                  Agents
                </h6>
                <div
                  className={
                    this.state.agentsOpen
                      ? `super-treeview-triangle-btn-down`
                      : `super-treeview-triangle-btn-up`
                  }
                  onClick={() => this.collapseAgents()}
                />
              </div>
              {this.state.agentsOpen && (
                <div>
                  <div className="list-group px-3">
                    <div>
                      {this.state.selectedAgents.map(node => {
                        return (
                          <button
                            key={node.name}
                            type="button"
                            className="btn btn-outline-success btn-sm ml-1 mt-1"
                          >
                            <span>{node.value}</span>
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-x"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => this.checkAgent(node)}
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              ></path>
                            </svg>
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    id="collapseTwo"
                    className="collapse show"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body facets">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item border-0 mt-0 py-0">
                          {/* <div className="custom-control custom-checkbox"> */}
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="allAgents"
                            onChange={() => this.checkAllAgents()}
                            checked={this.props.agents
                              .map(item => item.name)
                              .every(val => {
                                return (
                                  this.state.selectedAgents
                                    .map(item => item.name)
                                    .indexOf(val) >= 0
                                );
                              })}
                          />
                          <label className="text-muted" htmlFor="allAgents">
                            All
                          </label>
                          <label className="text-muted float-right small">
                            {this.state.selectedAgents.length} of{' '}
                            {this.props.agents.length}
                          </label>
                          {/* </div> */}
                        </li>
                        {this.props.agents.map(facet => {
                          return (
                            <li
                              className="list-group-item border-0 mt-0 py-0"
                              key={facet.name}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={facet.name}
                                onChange={() => this.checkAgent(facet)}
                                checked={some(this.state.selectedAgents, [
                                  'name',
                                  facet.name
                                ])}
                              />
                              <label className="" htmlFor={facet.name}>
                                {facet.value} ({facet.count})
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const getOntology = state => state.ontology.ontology || [];
const getDataSources = state => state.timeline.dataSources || [];
const getAgents = state => state.timeline.agents || [];

const mapStateToProps = createSelector(
  getOntology,
  getDataSources,
  getAgents,
  (ontology, dataSources, agents) => ({
    ontology,
    dataSources,
    agents
  })
);

const mapDispatchToProps = {
  getTimeline: actions.timeline.getTimeline,
  setSelectedConcepts: actions.query.setSelectedConcepts,
  setMode: actions.query.setMode,
  setSelectedDataSources: actions.query.setSelectedDataSources,
  setSelectedAgents: actions.query.setSelectedAgents
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
