import * as types from './actionTypes';
import { filter, map } from 'lodash';

var processedNodes = [];

const reducer = (
  state = { ontology: [], languages: [], alertTimelineOpen: false },
  action
) => {
  switch (action.type) {
    case types.SET_ONTOLOGY_SUCCESS: {
      processedNodes = [];
      const allNodes = action.payload.ontology.concepts;
      let topNodes = filter(allNodes || [], nodes => nodes.top);

      let treeData = map(topNodes, node => {
        processedNodes.push(node.id);

        if (node.children) {
          node.children = loadChildNodes(node.children, allNodes, 0);
        }

        return node;
      });
      return {
        ...state,
        ontology: treeData
      };
    }
    case types.SET_CONCEPTS_COUNT: {
      const distinctValues =
        action.payload['values-response']['distinct-value'];
      let ontologyClone = state.ontology.map(item => {
        if (distinctValues) {
          let number = distinctValues.find(
            value => value['_value'] === item.id
          );
          if (number) {
            item.count = number.frequency;
          } else {
            if (item.count) {
              delete item.count;
            }
          }
        } else {
          if (item.count) {
            delete item.count;
          }
        }

        if (item.children) {
          let childrenClone = addCountToChildNodes(
            item.children,
            distinctValues
          );
          item.children = childrenClone;
        }

        return item;
      });

      return {
        ...state,
        ontology: ontologyClone
      };
    }
    case types.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload
      };
    case types.SET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload
      };
    case types.SET_UPDATE_INTERVAL: {
      return {
        ...state,
        updateInterval: action.payload
      };
    }
    case types.SET_ALERT_TIMELINE:
      return {
        ...state,
        alertTimelineOpen: action.payload
      };
    default:
      return state;
  }
};

const loadChildNodes = (children, allNodes, level) => {
  if (level > 10) {
    return children;
  }

  if (!Array.isArray(children)) {
    children = [children];
  }

  let childNodes = filter(allNodes || [], (node, key) => {
    return (
      children.indexOf(key) !== -1 && processedNodes.indexOf(node.id) === -1
    );
  });

  childNodes = map(childNodes, node => {
    processedNodes.push(node.id);

    if (node.children) {
      node.children = loadChildNodes(node.children, allNodes, level + 1);
    }

    return node;
  });

  return childNodes;
};

const addCountToChildNodes = (children, distinctValues) => {
  let ontologyChildrenClone = children.map(item => {
    if (distinctValues) {
      let number = distinctValues.find(value => value['_value'] === item.id);
      if (number) {
        item.count = number.frequency;
      } else {
        if (item.count) {
          delete item.count;
        }
      }
    } else {
      delete item.count;
    }

    if (item.children) {
      let childrenClone = addCountToChildNodes(item.children, distinctValues);
      item.children = childrenClone;
    }

    return item;
  });

  return ontologyChildrenClone;
};

const ontology = state => state.ontology;
const languages = state => state.languages || [];
const selectedLanguage = state => state.selectedLanguage || '';
const updateInterval = state => state.updateInterval || 300;
const alertTimelineOpen = state => state.alertTimelineOpen || false;

const ontologySelectors = {
  ontology,
  languages,
  selectedLanguage,
  updateInterval,
  alertTimelineOpen
};

export default reducer;
export { ontologySelectors };
