import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
} from 'mapbox-gl-draw-circle';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import actions from '../../../store/appActions';
import { createSelector } from 'reselect';

import MouseLocation from './MouseLocation';
import QueryBuilder from '../../../query-builder';

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selected: '-',
      mapStyle: 'streets',
      selectedLayers: ['Observations', 'Tracks', 'Agents'],
      selectedDataSources: [],
      selectedAgents: [],
      concepts: [],
      timelineLength: 0,
      observationClickSet: false,
      alertClickSet: false,
      trackClickSet: false,
      agentClickSet: false,
      initialObservationLayerSet: false,
      initialTracksLayerSet: false,
      initialAgentsLayerSet: false,
      updateMapLayersCalled: false,
      isStyleLoaded: false,
      filterCollapsed: false,
      filterExpanded: false,
      resultsCollapsed: false,
      mode: true,
      mouseLat: '',
      mouseLong: '',
      loadingMapLayers: {},
      toastOpen: true
    };
    this.map = null;
    this.mouseLocationRef = React.createRef();
    this.gettingLKP = false;
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.startTimer();
    if (this.props.mapLayers.length > 0 && this.props.filterTreeFetched) {
      this.initiateComponent();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.mapLayers.length === 0 &&
      this.props.mapLayers.length > 0 &&
      this.props.filterTreeFetched
    ) {
      this.initiateComponent();
    }

    if (
      prevProps.filterTreeFetched !== this.props.filterTreeFetched &&
      this.props.mapLayers.length > 0
    ) {
      this.initiateComponent();
    }

    if (prevProps.mapLayers !== this.props.mapLayers) {
      if (
        this.state.isStyleLoaded &&
        this.state.updateMapLayersCalled == false
      ) {
        await this.setMapSources();
      }
      if (this.props.mapLayers && prevProps.mapLayers) {
        this.props.mapLayers.forEach(mapLayer => {
          const prevMapLayer = prevProps.mapLayers.find(
            element => element.name === mapLayer.name
          );

          if (prevMapLayer && prevMapLayer.features != mapLayer.features) {
            this.setState({
              loadingMapLayers: {
                ...this.state.loadingMapLayers,
                [mapLayer.name]: false
              }
            });
          }
        });
      }
      this.updateMapLayers();
    }

    if (
      prevProps.filterCollapsed !== this.props.filterCollapsed ||
      prevProps.filterExpanded !== this.props.filterExpanded
    ) {
      this.map.resize();
    }

    if (prevState.mapStyle !== this.state.mapStyle) {
      this.props.getAllLayerFeatures();
    }

    if (prevProps.selectedLanguage !== this.props.selectedLanguage) {
      await this.props.getFilterTreeList();
      this.props.fetchAllData();
    }

    if (this.props.alertTimelineOpen !== prevProps.alertTimelineOpen) {
      this.map.setLayoutProperty(
        'alert',
        'visibility',
        this.props.alertTimelineOpen ? 'visible' : 'none'
      );

      if (this.props.alertTimelineOpen) {
        this.props.clearSelectedItem();
        this.props.clearEntireTrack();

        // If alertTimelineOpen is true, hide all layers and store their previous visibility state
        this.prevLayerVisibility = this.props.selectedLayers.map(layer => {
          const visibility = this.map.getLayoutProperty(layer, 'visibility');
          this.map.setLayoutProperty(layer, 'visibility', 'none');
          return { layer, visibility };
        });

        // Update setSelectedLayers to an empty array as all layers are hidden
        this.props.setSelectedLayers(['Observations']);
      } else {
        // If alertTimelineOpen is false, restore the layers to their previous visibility state
        this.prevLayerVisibility.forEach(({ layer, visibility }) => {
          this.map.setLayoutProperty(layer, 'visibility', visibility);
        });

        // Update setSelectedLayers to include only layers that are visible
        const visibleLayers = this.prevLayerVisibility
          .filter(({ visibility }) => visibility === 'visible')
          .map(({ layer }) => layer);
        this.props.setSelectedLayers(visibleLayers);
      }
    }
  }

  initiateComponent = async () => {
    let lat = localStorage.getItem('lat');
    let lng = localStorage.getItem('lng');
    let zoom = localStorage.getItem('zoom');

    if (lat != null && lng != null && zoom != null) {
      // Set existing location
      const userCoordinates = [lng, lat];
      await this.props.setMapCoordinates(
        lng,
        lat,
        null,
        null,
        null,
        null,
        zoom
      );
      this.setState({
        mouseLat: lat,
        mouseLong: lng
      });
      this.setupMap(userCoordinates, zoom);
    } else {
      // Get current location
      navigator.geolocation.getCurrentPosition(
        position => {
          const userCoordinates = [
            position.coords.longitude,
            position.coords.latitude
          ];
          this.props.setMapCoordinates(
            position.coords.latitude,
            position.coords.longitude,
            null,
            null,
            null,
            null,
            13
          );
          this.setState({
            mouseLat: position.coords.latitude,
            mouseLong: position.coords.longitude
          });
          this.setupMap(userCoordinates, 13);
        },
        error => {
          this.setupMap();
        },
        {
          timeout: 5000
        }
      );
    }
  };

  setupMap = async (userCoordinates = [0, 0], zoom = 2) => {
    // Create map
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/jankees/cleijg9zb000t01pn0thsmz0q',
      center: userCoordinates,
      zoom: zoom,
      attributionControl: false
    });

    var pulsingRedDot = this.getPulsingDot('30, 136, 229', 300, 5000, this.map);

    var largePulsingRedDot = this.getPulsingDot(
      '30, 136, 229',
      500,
      5000,
      this.map
    );

    var pulsingOrangeDot = this.getPulsingDot(
      '255, 171, 0',
      300,
      2000,
      this.map
    );

    var largePulsingOrangeDot = this.getPulsingDot(
      '255, 171, 0',
      500,
      2000,
      this.map
    );

    var pulsingGreenDot = this.getPulsingDot(
      '228, 58, 53',
      300,
      2000,
      this.map
    );

    var largePulsingGreenDot = this.getPulsingDot(
      '228, 58, 53',
      500,
      2000,
      this.map
    );

    // Add controls
    this.map
      .addControl(
        new mapboxgl.NavigationControl({
          showCompass: false
        })
      )
      .addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          fitBoundsOptions: {
            maxZoom: 10
          },
          showAccuracyCircle: false,
          showUserLocation: false
        })
      )
      .addControl(
        new mapboxgl.AttributionControl({
          compact: false
        })
      );

    // Initialize draw toolbox
    var draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
        draw_rectangle: DrawRectangle
      },
      controls: {
        polygon: true,
        circle: true,
        trash: true
      }
    });

    let updateDrawnGeometry = e => {
      const localSelectedAreas = this.props.selectedAreas;
      if (e.type === 'draw.delete') {
        const updatedArray = localSelectedAreas.filter(
          item => item.id !== e.features[0].id
        );
        this.props.setSelectedAreas(updatedArray);
        this.props.fetchAllData();
      } else if (e.type === 'draw.create') {
        const data = draw.getAll();

        if (data.features.length > 0) {
          this.props.setSelectedAreas(data.features);

          this.props.fetchAllData();
        } else {
          if (e.type !== 'draw.delete')
            alert('Use the draw tools to draw a polygon!');
        }
      }
    };

    document.getElementById('button_circle').onclick = function() {
      draw.changeMode('drag_circle');
    };

    document.getElementById('button_rectangle').onclick = function() {
      draw.changeMode('draw_rectangle');
    };

    this.map.on('load', () => {
      this.map.addControl(draw);
      this.map.addControl(new mapboxgl.ScaleControl());
      this.map.on('draw.create', updateDrawnGeometry);
      this.map.on('draw.delete', updateDrawnGeometry);
      this.map.on('draw.update', updateDrawnGeometry);
      this.map.on('draw.combine', updateDrawnGeometry);
      this.map.on('draw.uncombine', updateDrawnGeometry);
    });

    this.map.on('moveend', async e => {
      await this.props.setMapCoordinates(
        this.map.getCenter().lng.toFixed(4),
        this.map.getCenter().lat.toFixed(4),
        this.map.getBounds().getNorth(),
        this.map.getBounds().getEast(),
        this.map.getBounds().getSouth(),
        this.map.getBounds().getWest(),
        this.map.getZoom().toFixed(2)
      );

      localStorage.setItem('lng', this.map.getCenter().lng.toFixed(4));
      localStorage.setItem('lat', this.map.getCenter().lat.toFixed(4));
      localStorage.setItem('zoom', this.map.getZoom().toFixed(2));

      this.props.fetchAllData();
    });

    this.map.on('mousemove', e => {
      this.mouseLocationRef.current.changeLocation(e);
    });

    this.map.once('load', async () => {
      await this.props.setMapCoordinates(
        this.map.getCenter().lng.toFixed(4),
        this.map.getCenter().lat.toFixed(4),
        this.map.getBounds().getNorth(),
        this.map.getBounds().getEast(),
        this.map.getBounds().getSouth(),
        this.map.getBounds().getWest(),
        this.map.getZoom().toFixed(2)
      );

      await this.props.fetchAllData();
    });

    // Add layers
    this.map.on('style.load', async () => {
      this.map.addImage('pulsing-red-dot', pulsingRedDot, { pixelRatio: 2 });
      this.map.addImage('large-pulsing-red-dot', largePulsingRedDot, {
        pixelRatio: 2
      });
      this.map.addImage('pulsing-orange-dot', pulsingOrangeDot, {
        pixelRatio: 2
      });
      this.map.addImage('large-pulsing-orange-dot', largePulsingOrangeDot, {
        pixelRatio: 2
      });
      this.map.addImage('pulsing-green-dot', pulsingGreenDot, {
        pixelRatio: 2
      });
      this.map.addImage('large-pulsing-green-dot', largePulsingGreenDot, {
        pixelRatio: 2
      });

      if (this.props.mapLayers.length > 2) {
        await this.setMapSources();

        if (this.state.updateMapLayersCalled == false) {
          this.updateMapLayers();
        }
      }
      this.setState({ isStyleLoaded: true });
    });
  };

  // Update layers with new data
  updateMapLayers = () => {
    if (this.map) {
      this.props.mapLayers.forEach(layer => {
        if (layer.features) {
          if (this.map.getLayer(layer.name)) {
            let data = {
              type: layer.type,
              features: layer.features
                .filter(item => {
                  if (
                    moment().diff(
                      moment(item.properties.LastSeenDateTime),
                      'days'
                    ) < 7
                  ) {
                    return true;
                  }
                  return false;
                })
                .map(item => {
                  delete item.DocId;
                  item.properties.observation = 'observation';
                  return item;
                })
            };

            this.map.getSource(layer.name).setData(data);
          } else if (layer.projectId != 'default') {
            this.setGeoJsonLayer(layer);
          }
        }
      });

      if (this.map.getSource('alert') && this.props.alertFeatures) {
        this.map.getSource('alert').setData({
          type: 'FeatureCollection',
          features: this.props.alertFeatures.map(item => {
            item.properties.observation = 'observation';
            return item;
          })
        });
      }

      if (
        this.map.getSource('actualTracks') ||
        this.map.getSource('startOfTracks')
      ) {
        this.updateActualTracksLayers();
      }
    }
  };

  updateActualTracksLayers = () => {
    let data = {
      type: this.props.trackLayer.type,
      features: this.props.trackLayer.features
    };

    let startFeature = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates:
              data.features.length > 0 &&
              data.features[0].geometry.coordinates.length > 0
                ? data.features[0].geometry.coordinates[
                    data.features[0].geometry.coordinates.length - 1
                  ]
                : []
          }
        }
      ]
    };

    this.map.getSource('actualTracks').setData(data);
    this.map.getSource('startOfTracks').setData(startFeature);
  };

  getPulsingDot = (color, size, duration, localMap) => {
    let dot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd: function() {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // called once before every frame where the icon will be used
      render: function() {
        // var duration = 5000;
        var t = (performance.now() % duration) / duration;

        var radius = (size / 2) * 0.3;
        var outerRadius = (size / 2) * 0.7 * t + radius;
        var context = this.context;

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(' + color + ',' + (1 - t) + ')';
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = 'rgba(' + color + ', 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 4 + 8 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        localMap.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    };

    return dot;
  };

  // Set map sources and layers
  setMapSources = () => {
    if (
      this.state.updateMapLayersCalled == false &&
      this.props.mapLayers.length > 0
    ) {
      this.setState({ updateMapLayersCalled: true });
    }
    if (!this.map.getSource('actualTracks')) {
      this.map.addSource('actualTracks', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
    }

    if (!this.map.getSource('alert')) {
      this.map.addSource('alert', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
    }

    if (!this.map.getSource('startOfTracks')) {
      this.map.addSource('startOfTracks', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
    }

    this.props.mapLayers.forEach(mapLayer => {
      if (!this.map.getSource(mapLayer.name)) {
        this.map.addSource(mapLayer.name, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        if (mapLayer.projectId === 'default') {
          switch (mapLayer.id) {
            case 0:
              this.setObservationLayer(mapLayer);
              this.setAlertLayer(mapLayer);
              break;
            case 1:
              this.setActualTrackLayer(mapLayer);
              this.setTracksLayer(mapLayer);
              break;
            case 2:
              this.setPersonsLayer(mapLayer);
              break;
            case 3:
              this.setMediaLayer(mapLayer);
              break;
            default:
              break;
          }
        } else {
          this.setGeoJsonLayer(mapLayer);
        }
      }
    });
  };

  setObservationLayer = async mapLayer => {
    this.map.addLayer({
      id: mapLayer.name,
      source: mapLayer.name,
      type: 'symbol',
      layout: {
        visibility: 'visible',
        'icon-image': [
          'match',
          ['get', 'EntityType'],
          ['offence'],
          'humanactivity',
          ['human activity'],
          'humanactivity',
          ['infrastructure'],
          'infrastructure',
          ['Point of Interest'],
          'Point of Interest',
          ['animal'],
          'animalsighting',
          ['community'],
          'community',
          ['community_work'],
          'community',
          ['hwc'],
          'hwc',
          ['animalsighting_photo'],
          'animalsighting_photo',
          ['humanactivity_photo'],
          'humanactivity_photo',
          ''
        ],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.05, 22, 0.5],
        'icon-padding': 0,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true
      },
      paint: {}
    });

    this.map.addLayer({
      id: mapLayer.name + '_clicked',
      source: mapLayer.name,
      type: 'symbol',
      layout: {
        visibility: 'visible',
        'icon-image': [
          'match',
          ['get', 'EntityType'],
          ['offence_clicked'],
          'humanactivity_clicked',
          ['offence_selected'],
          'humanactivity_clicked',
          ['human activity_clicked'],
          'humanactivity_clicked',
          ['human activity_selected'],
          'humanactivity_clicked',
          ['infrastructure_clicked'],
          'infrastructure_clicked',
          ['infrastructure_selected'],
          'infrastructure_clicked',
          ['Point of Interest_clicked'],
          'Point of Interest_clicked',
          ['Point of Interest_selected'],
          'Point of Interest_clicked',
          ['animal_clicked'],
          'animalsighting_clicked',
          ['animal_selected'],
          'animalsighting_clicked',
          ['community_clicked'],
          'community_clicked',
          ['community_selected'],
          'community_clicked',
          ['community_work_clicked'],
          'community_clicked',
          ['community_work_selected'],
          'community_clicked',
          ['hwc_clicked'],
          'hwc_clicked',
          ['hwc_selected'],
          'hwc_clicked',
          ''
        ],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.15, 22, 0.6],
        'icon-padding': 0,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true
      },
      paint: {}
    });

    if (this.map.getLayer(mapLayer.name + '_clicked')) {
      this.map.moveLayer(mapLayer.name + '_clicked');
    }
    if (this.map.getLayer('Agents')) {
      this.map.moveLayer('Agents');
    }

    if (!this.state.observationClickSet) {
      this.map.on('click', mapLayer.name, e => {
        let f = this.map.queryRenderedFeatures(e.point);
        if (f.length && f[0].layer.source === mapLayer.name) {
          var id = e.features[0].properties.EntityId;

          // Check if is already in timeline
          const observationInTimeline = this.props.timelineResults.find(
            item => item.id === id
          );

          if (observationInTimeline) {
            this.props.setSelectedItem(
              observationInTimeline.extracted.content.filter(
                c => c.Observation
              )[0].Observation
            );
          } else {
            this.props.getSingleItem(id);
          }
          this.props.clearEntireTrack();
        }
      });

      this.map.on('click', mapLayer.name + '_clicked', e => {
        let f = this.map.queryRenderedFeatures(e.point);
        if (f.length && f[0].layer.source === mapLayer.name) {
          this.props.clearSelectedItem();
        }
      });

      this.setState({ observationClickSet: true });
    }

    this.map.on(
      'mouseenter',
      [mapLayer.name, mapLayer.name + '_clicked'],
      () => {
        this.map.getCanvas().style.cursor = 'pointer';
      }
    );

    this.map.on(
      'mouseleave',
      [mapLayer.name, mapLayer.name + '_clicked'],
      () => {
        this.map.getCanvas().style.cursor = '';
      }
    );
  };

  setAlertLayer = async mapLayer => {
    let name = 'alert';
    this.map.addLayer({
      id: name,
      source: name,
      type: 'symbol',
      layout: {
        visibility: 'none',
        'icon-image': [
          'match',
          ['get', 'EntityType'],
          ['offence'],
          'humanactivity',
          ['human activity'],
          'humanactivity',
          ['infrastructure'],
          'infrastructure',
          ['Point of Interest'],
          'Point of Interest',
          ['animal'],
          'animalsighting',
          ['community'],
          'community',
          ['community_work'],
          'community',
          ['hwc'],
          'hwc',
          ['animalsighting_photo'],
          'animalsighting_photo',
          ['humanactivity_photo'],
          'humanactivity_photo',
          ''
        ],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.05, 22, 0.5],
        'icon-padding': 0,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true
      },
      paint: {}
    });

    this.map.addLayer({
      id: name + '_clicked',
      source: name,
      type: 'symbol',
      layout: {
        visibility: 'visible',
        'icon-image': [
          'match',
          ['get', 'EntityType'],
          ['offence_clicked'],
          'humanactivity_clicked',
          ['offence_selected'],
          'humanactivity_clicked',
          ['human activity_clicked'],
          'humanactivity_clicked',
          ['human activity_selected'],
          'humanactivity_clicked',
          ['infrastructure_clicked'],
          'infrastructure_clicked',
          ['infrastructure_selected'],
          'infrastructure_clicked',
          ['Point of Interest_clicked'],
          'Point of Interest_clicked',
          ['Point of Interest_selected'],
          'Point of Interest_clicked',
          ['animal_clicked'],
          'animalsighting_clicked',
          ['animal_selected'],
          'animalsighting_clicked',
          ['community_clicked'],
          'community_clicked',
          ['community_selected'],
          'community_clicked',
          ['community_work_clicked'],
          'community_clicked',
          ['community_work_selected'],
          'community_clicked',
          ['hwc_clicked'],
          'hwc_clicked',
          ['hwc_selected'],
          'hwc_clicked',
          ''
        ],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.15, 22, 0.6],
        'icon-padding': 0,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true
      },
      paint: {}
    });

    if (this.map.getLayer(name + '_clicked')) {
      this.map.moveLayer(name + '_clicked');
    }

    if (!this.state.alertClickSet) {
      this.map.on('click', name, e => {
        let f = this.map.queryRenderedFeatures(e.point);
        if (f.length && f[0].layer.source === name) {
          var id = e.features[0].properties.EntityId;

          // Check if is already in timeline
          this.props.getSingleItem(id);
          this.props.clearEntireTrack();
        }
      });

      this.map.on('click', name + '_clicked', e => {
        let f = this.map.queryRenderedFeatures(e.point);
        if (f.length && f[0].layer.source === name) {
          this.props.clearSelectedItem();
        }
      });

      this.setState({ alertClickSet: true });
    }

    this.map.on('mouseenter', [name, name + '_clicked'], () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', [name, name + '_clicked'], () => {
      this.map.getCanvas().style.cursor = '';
    });
  };

  setTracksLayer = async mapLayer => {
    this.map.addLayer({
      id: mapLayer.name,
      source: mapLayer.name,
      type: 'symbol',
      layout: {
        visibility: this.props.selectedLayers.includes(mapLayer.name)
          ? 'visible'
          : 'none',
        'icon-image': [
          'match',
          ['get', 'EntityType'],
          ['track/onDuty'],
          'onduty',
          ['track/onFootPatrol'],
          'onfootpatrol',
          ['track/onVehiclePatrol'],
          'onvehiclepatrol',
          ['track/onAirPatrol'],
          'onairpatrol',
          ['track/onHumanSpoor'],
          'onhumanspoor',
          ['track/onAnimalSpoor'],
          'onanimalspoor',
          ['track/onSafari'],
          'onsafari',
          ['track/onCensus'],
          'oncensus',
          ['track/onK9Patrol'],
          'onk9patrol',
          ['track/onFenceInspection'],
          'onfenceinspection',
          ['track/onPerimeterTrack'],
          'onperimetertrack',
          ['track/onSnareSweep'],
          'onsnaresweep',
          ['track/onSearch'],
          'onsearch',
          ['track/onBoatPatrol'],
          'onboatpatrol',
          ['track/onDronePatrol'],
          'ondronepatrol',
          ['track/onFastResponse'],
          'onfastresponse',
          ['track/onTraining'],
          'ontraining',
          ['track/onBikePatrol'],
          'onbikepatrol',
          ''
        ],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.05, 22, 0.5],
        'icon-padding': 0,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true
      }
    });

    this.map.addLayer({
      id: mapLayer.name + '_clicked',
      source: mapLayer.name,
      type: 'symbol',
      layout: {
        visibility: this.props.selectedLayers.includes(mapLayer.name)
          ? 'visible'
          : 'none',
        'icon-image': [
          'match',
          ['get', 'EntityType'],
          ['track/onDuty_clicked'],
          'onduty_clicked',
          ['track/onFootPatrol_clicked'],
          'onfootpatrol_clicked',
          ['track/onVehiclePatrol_clicked'],
          'onvehiclepatrol_clicked',
          ['track/onAirPatrol_clicked'],
          'onairpatrol_clicked',
          ['track/onHumanSpoor_clicked'],
          'onhumanspoor_clicked',
          ['track/onAnimalSpoor_clicked'],
          'onanimalspoor_clicked',
          ['track/onSafari_clicked'],
          'onsafari_clicked',
          ['track/onCensus_clicked'],
          'oncensus_clicked',
          ['track/onK9Patrol_clicked'],
          'onk9patrol_clicked',
          ['track/onFenceInspection_clicked'],
          'onfenceinspection_clicked',
          ['track/onPerimeterTrack_clicked'],
          'onperimetertrack_clicked',
          ['track/onSnareSweep_clicked'],
          'onsnaresweep_clicked',
          ['track/onSearch_clicked'],
          'onsearch_clicked',
          ['track/onBoatPatrol_clicked'],
          'onboatpatrol_clicked',
          ['track/onDronePatrol_clicked'],
          'ondronepatrol_clicked',
          ['track/onFastResponse_clicked'],
          'onfastresponse_clicked',
          ['track/onTraining_clicked'],
          'ontraining_clicked',
          ['track/onBikePatrol_clicked'],
          'onbikepatrol_clicked',
          ''
        ],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.15, 22, 0.6],
        'icon-padding': 0,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true
      }
    });

    if (this.map.getLayer(mapLayer.name + '_clicked')) {
      this.map.moveLayer(mapLayer.name + '_clicked');

      if (this.map.getLayer('Observations_clicked')) {
        this.map.moveLayer(mapLayer.name, 'Observations_clicked');
      }
    }
    if (this.map.getLayer('Agents')) {
      this.map.moveLayer('Agents');
    }

    if (!this.state.trackClickSet) {
      this.map.on('click', mapLayer.name, e => {
        let f = this.map.queryRenderedFeatures(e.point);
        if (f.length && f[0].layer.source === mapLayer.name) {
          var id = e.features[0].properties.EntityId;
          // Check if is already in timeline
          const trackInTimeline = this.props.timelineResults.find(
            item => item.id === id
          );
          this.setSelectedTrackItem(id, trackInTimeline);
        }
      });

      this.map.on('click', mapLayer.name + '_clicked', e => {
        let f = this.map.queryRenderedFeatures(e.point);
        if (f.length && f[0].layer.source === mapLayer.name) {
          this.props.clearSelectedItem();
        }
      });

      this.setState({ trackClickSet: true });
    }

    this.map.on(
      'mouseenter',
      [mapLayer.name, mapLayer.name + '_clicked'],
      () => {
        this.map.getCanvas().style.cursor = 'pointer';
      }
    );

    this.map.on(
      'mouseleave',
      [mapLayer.name, mapLayer.name + '_clicked'],
      () => {
        this.map.getCanvas().style.cursor = '';
      }
    );
  };

  setSelectedTrackItem = async (id, trackInTimeline) => {
    let query = new QueryBuilder.SearchQuery().withQueryText(
      'entityId:"' + id + '"'
    );

    await this.props.getEntireTrack('track', '0', query);
    await this.props.getTrackInfo(id);
    this.props.getTrackObservations(id);

    if (trackInTimeline) {
      this.props.setSelectedItem(
        trackInTimeline.extracted.content.filter(c => c.GeoFeature)[0]
          .GeoFeature
      );
    } else {
      this.props.getSingleTrackItem(id);
    }
  };

  setActualTrackLayer = mapLayer => {
    this.map.addLayer({
      id: 'actualTracks',
      source: 'actualTracks',
      type: 'circle',
      layout: {
        visibility: this.props.selectedLayers.includes(mapLayer.name)
          ? 'visible'
          : 'none'
      },
      paint: {
        'circle-radius': 3,
        'circle-color': 'red',
        'circle-opacity': 1,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    });

    this.map.addLayer({
      id: 'startOfTracks',
      source: 'startOfTracks',
      type: 'circle',
      layout: {
        visibility: this.props.selectedLayers.includes(mapLayer.name)
          ? 'visible'
          : 'none'
      },
      paint: {
        'circle-radius': 6,
        'circle-color': 'green',
        'circle-opacity': 1,
        'circle-stroke-width': 3,
        'circle-stroke-color': '#fff'
      }
    });

    if (this.map.getLayer('actualTracks')) {
      this.map.moveLayer('actualTracks');
    }
    if (this.map.getLayer('startOfTracks')) {
      this.map.moveLayer('startOfTracks');
    }
    if (this.map.getLayer('Agents')) {
      this.map.moveLayer('Agents');
    }
  };

  setGeoJsonLayer = mapLayer => {
    if (mapLayer.geometryType == 'MultiPolygon') {
      let color = '';
      if (mapLayer.name == 'BHS None') {
        color = '#225C8D';
      } else if (mapLayer.name == 'BHS Low') {
        color = '#FB8C24';
      } else if (mapLayer.name == 'BHS Medium') {
        color = '#FDD835';
      } else if (mapLayer.name == 'BHS High') {
        color = '#7CB342';
      } else if (mapLayer.name == 'phase 1-2') {
        color = '#1BACC1';
      } else if (mapLayer.name == 'phase 3') {
        color = '#D81B60';
      } else if (mapLayer.name == 'Corridors') {
        color = '#225C8D';
      } else {
        color =
          '#' +
          Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, '0');
      }

      this.map.addLayer({
        id: mapLayer.name,
        source: mapLayer.name,
        type: 'fill',
        layout: {
          visibility: this.props.selectedLayers.includes(mapLayer.name)
            ? 'visible'
            : 'none'
        },
        paint: {
          'fill-color': color,
          'fill-opacity': 0.25,
          'fill-outline-color': '#fff'
        }
      });

      this.setLayerClickListener(mapLayer);
    }

    if (mapLayer.geometryType == 'Polygon') {
      // let color = "#FF0000"
      let color =
        '#' +
        Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0');

      this.map.addLayer({
        id: mapLayer.name,
        source: mapLayer.name,
        type: 'fill',
        layout: {
          visibility: this.props.selectedLayers.includes(mapLayer.name)
            ? 'visible'
            : 'none'
        },
        paint: {
          'fill-color': color,
          'fill-opacity': 0.25,
          'fill-outline-color': '#fff'
        }
      });

      this.setLayerClickListener(mapLayer);
    }

    if (
      mapLayer.geometryType == 'MultiLineString' ||
      mapLayer.geometryType == 'LineString'
    ) {
      let color = '';
      if (mapLayer.name == 'roads ph. 1') {
        color = '#76CDD9';
      } else if (mapLayer.name == 'roads ph. 2') {
        color = '#E285A9';
      } else {
        color = '#004D40';
      }
      this.map.addLayer({
        id: mapLayer.name,
        source: mapLayer.name,
        type: 'line',
        layout: {
          visibility: this.props.selectedLayers.includes(mapLayer.name)
            ? 'visible'
            : 'none',
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': color,
          'line-width': 3
        }
      });

      this.setLayerClickListener(mapLayer);
    }

    if (mapLayer.geometryType == 'Point') {
      this.map.addLayer({
        id: mapLayer.name,
        source: mapLayer.name,
        type: 'symbol',
        layout: {
          visibility: this.props.selectedLayers.includes(mapLayer.name)
            ? 'visible'
            : 'none',
          'icon-image': 'infrastructure',
          'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.05, 22, 0.5],
          'icon-padding': 0,
          'symbol-avoid-edges': true,
          'icon-allow-overlap': true
        },
        paint: {}
      });

      this.setLayerClickListener(mapLayer);
    }

    if (mapLayer.features) {
      let data = {
        type: mapLayer.type,
        features: mapLayer.features
      };

      if (this.map.getSource(mapLayer.name))
        this.map.getSource(mapLayer.name).setData(data);
    }

    this.map.getStyle().layers.forEach(layer => {
      if (layer.type === 'symbol' || layer.type === 'line') {
        this.map.moveLayer(layer.id);
      }
    });

    if (this.map.getLayer('actualTracks')) {
      this.map.moveLayer('actualTracks');
    }
    if (this.map.getLayer('startOfTracks')) {
      this.map.moveLayer('startOfTracks');
    }
    if (this.map.getLayer('Tracks')) {
      this.map.moveLayer('Tracks');
    }
    if (this.map.getLayer('Observations')) {
      this.map.moveLayer('Observations');
    }
    if (this.map.getLayer('Agents')) {
      this.map.moveLayer('Agents');
    }
  };

  setLayerClickListener = mapLayer => {
    this.map.on('click', mapLayer.name, e => {
      let f = this.map.queryRenderedFeatures(e.point);
      if (f.length && f[0].layer.source === mapLayer.name) {
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `${
              e.features[0].properties.MapLabel
                ? e.features[0].properties.MapLabel
                : e.features[0].properties.field_7
                ? e.features[0].properties.field_7
                : e.features[0].properties.Location
                ? e.features[0].properties.Location
                : e.features[0].properties.NAME
                ? e.features[0].properties.NAME
                : 'Unknown'
            }`
          )
          .addTo(this.map);
      }
    });

    this.map.on('mouseenter', mapLayer.name, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', mapLayer.name, () => {
      this.map.getCanvas().style.cursor = '';
    });
  };

  setPersonsLayer = async mapLayer => {
    this.map.addLayer({
      id: mapLayer.name,
      source: mapLayer.name,
      type: 'symbol',
      layout: {
        visibility: 'visible',
        'icon-image': [
          'match',
          ['get', 'age'],
          ['moreThanDay'],
          'pulsing-red-dot',
          ['moreThanDay_clicked'],
          'large-pulsing-red-dot',
          ['lessThanDay'],
          'pulsing-orange-dot',
          ['lessThanDay_clicked'],
          'large-pulsing-orange-dot',
          ['lessThanHour'],
          'pulsing-green-dot',
          ['lessThanHour_clicked'],
          'large-pulsing-green-dot',
          'pulsing-red-dot'
        ],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.05, 22, 0.5],
        'icon-padding': 0,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true
        // "pulsing-red-dot"
      }
    });

    if (this.map.getLayer(mapLayer.name)) {
      this.map.moveLayer(mapLayer.name);
    }

    if (!this.state.agentClickSet) {
      this.map.on('click', mapLayer.name, e => {
        let f = this.map.queryRenderedFeatures(e.point);
        if (f.length && f[0].layer.source === mapLayer.name) {
          var id = e.features[0].properties.EntityId;

          // Check if is already in timeline
          if (
            this.props.selectedItem.properties &&
            this.props.selectedItem.properties.EntityId === id
          ) {
            this.props.clearSelectedItem();
          } else {
            this.props.setSelectedItem(e.features[0]);
            this.props.clearEntireTrack();
          }
        }
      });

      this.setState({ agentClickSet: true });
    }

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.map.on('mouseenter', mapLayer.name, e => {
      this.map.getCanvas().style.cursor = 'pointer';

      const coordinates = e.features[0].geometry.coordinates.slice();
      const name = e.features[0].properties.Name;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup
        .setLngLat(coordinates)
        .setHTML(name)
        .addTo(this.map);
    });

    this.map.on('mouseleave', mapLayer.name, () => {
      this.map.getCanvas().style.cursor = '';
      popup.remove();
    });

    // if (this.props.selectedLayers.includes(mapLayer.name)) {
    //   // Get the latest layer data
    //   this.createQuery(false, false, false, false, true);
    // }
  };

  setMediaLayer = mapLayer => {
    this.map.addLayer({
      id: mapLayer.name,
      source: mapLayer.name,
      type: 'circle',
      paint: {
        'circle-radius': 5,
        'circle-color': 'green',
        'circle-opacity': 1
      }
    });
  };

  startTimer() {
    if (this.timer == 0) {
      this.timer = setInterval(this.countDown, 500);
    }
  }

  countDown = () => {
    if (
      moment().diff(moment(this.props.LKPUpdate), 'seconds') >=
        this.props.updateInterval &&
      !this.gettingLKP
    ) {
      this.gettingLKP = true;
      this.props.fetchAllData();
    }

    if (
      moment().diff(moment(this.props.LKPUpdate), 'seconds') <
        this.props.updateInterval &&
      this.gettingLKP
    ) {
      this.gettingLKP = false;
    }
  };

  toggleMapMode = style => {
    if (style === 'satellite') {
      this.map.setStyle('mapbox://styles/jankees/cleijxlii005901qghunc7bis');
      this.setState({ mapStyle: 'satellite' });
    } else if (style == 'streets') {
      this.map.setStyle('mapbox://styles/jankees/cleijg9zb000t01pn0thsmz0q');
      this.setState({ mapStyle: 'streets' });
    } else if (style == 'grid') {
      this.map.setStyle('mapbox://styles/jankees/cldx1uw20000a01n0xjer8fax');
      this.setState({ mapStyle: 'grid' });
    }
  };

  toggleMapLayer = async clickedLayer => {
    if (!this.props.selectedLayers.includes(clickedLayer)) {
      this.props.setSelectedLayers([
        ...this.props.selectedLayers,
        clickedLayer
      ]);
    } else {
      this.props.setSelectedLayers([
        ...this.props.selectedLayers.filter(item => item !== clickedLayer)
      ]);
    }

    if (this.props.alertTimelineOpen) {
      if (clickedLayer === 'Observations') {
        clickedLayer = 'alert';
      } else if (clickedLayer === 'alert') {
        clickedLayer = 'Observations';
      }
    }

    // Check if the layer exists
    let layerExists = this.map.getLayer(clickedLayer);
    if (typeof layerExists !== 'undefined') {
      // Check visibility and set accordingly
      let visibility = this.map.getLayoutProperty(clickedLayer, 'visibility');
      if (visibility === 'visible') {
        if (this.props.alertTimelineOpen) {
          this.map.setLayoutProperty('alert', 'visibility', 'none');
        } else {
          this.map.setLayoutProperty(clickedLayer, 'visibility', 'none');
        }
        if (clickedLayer === 'Tracks') {
          this.map.setLayoutProperty('actualTracks', 'visibility', 'none');
          this.map.setLayoutProperty('startOfTracks', 'visibility', 'none');
          this.props.clearEntireTrack();
        }
        this.props.clearSelectedItem();
      } else {
        this.setState({
          loadingMapLayers: {
            ...this.state.loadingMapLayers,
            [clickedLayer]: true
          }
        });

        if (this.props.alertTimelineOpen) {
          this.map.setLayoutProperty('alert', 'visibility', 'visible');
        } else {
          this.map.setLayoutProperty(clickedLayer, 'visibility', 'visible');
        }

        if (clickedLayer === 'Tracks') {
          this.map.setLayoutProperty('actualTracks', 'visibility', 'visible');
          this.map.setLayoutProperty('startOfTracks', 'visibility', 'visible');
        }

        const layer = this.props.mapLayers.find(
          item => item.name === clickedLayer
        );

        if (layer) this.props.getSpecificLayer(layer);
      }
    } else {
      // Layer does not yet exist

      // Find layer in redux and add layer to map with source
      const layer = this.props.mapLayers.find(
        item => item.name === clickedLayer
      );

      this.props.getSpecificLayer(layer);
    }
  };

  collapseResults = async () => {
    await this.setState({ resultsCollapsed: !this.state.resultsCollapsed });
    this.map.resize();
  };

  render() {
    const { ...props } = this.props;
    return (
      <div
        className={
          (props.filterCollapsed && this.state.resultsCollapsed
            ? 'col-12'
            : '') +
          (props.filterCollapsed && !this.state.resultsCollapsed
            ? 'col-9'
            : '') +
          (!props.filterCollapsed &&
          !props.filterExpanded &&
          this.state.resultsCollapsed
            ? 'col-10'
            : '') +
          (!props.filterCollapsed &&
          props.filterExpanded &&
          this.state.resultsCollapsed
            ? 'col-9'
            : '') +
          (!props.filterCollapsed &&
          !props.filterExpanded &&
          !this.state.resultsCollapsed
            ? 'col-7'
            : '') +
          (!props.filterCollapsed &&
          props.filterExpanded &&
          !this.state.resultsCollapsed
            ? 'col-6'
            : '')
        }
        style={{ height: '100%' }}
      >
        {!props.filterCollapsed && (
          <div className="btn-collapse-filter">
            <button
              type="button"
              data-toggle="collapse"
              href="#multiCollapseExample2"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
              onClick={() => {
                if (!props.filterExpanded) {
                  this.props.handleCollapseFilter();
                } else {
                  this.props.handleExpandFilter();
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </button>
          </div>
        )}
        {!props.filterExpanded && (
          <div
            className={
              props.filterCollapsed
                ? 'btn-collapse-filter'
                : 'btn-expand-filter'
            }
          >
            <button
              type="button"
              data-toggle="collapse"
              href="#multiCollapseExample2"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
              onClick={() => {
                if (!props.filterCollapsed) {
                  this.props.handleExpandFilter();
                } else {
                  this.props.handleCollapseFilter();
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </div>
        )}

        <div className="btn-collapse-results">
          <button
            type="button"
            data-toggle="collapse"
            href="#multiCollapseExample2"
            aria-expanded="false"
            aria-controls="multiCollapseExample1"
            onClick={() => {
              this.collapseResults();
            }}
          >
            {this.state.resultsCollapsed && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            )}
            {!this.state.resultsCollapsed && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            )}
          </button>
        </div>

        <div ref={el => (this.mapContainer = el)} className="map-container" />
        <div className="custom-control-container">
          <button id="button_circle">
            <svg height="15" width="20">
              <circle
                cx="8"
                cy="8"
                r="6"
                stroke="black"
                strokeWidth="1.3"
                fill="none"
              />
              O
            </svg>
          </button>
          <button id="button_rectangle" className=" border-top">
            <svg height="15" width="20">
              <rect
                x="2"
                y="1"
                width="11"
                height="11"
                stroke="black"
                strokeWidth="1.3"
                fill="none"
              />
              \ R
            </svg>
          </button>
        </div>

        <div className="dropdown dropleft" id="map-menu">
          <button
            className="svg-button"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23 13H13v10h10zm-9 9v-5h5v2h-2v1h2v2zm8 0h-2v-5h2zm0-6h-8v-2h8zM11 1H1v10h10zm-.519 7.085l-.1-.008c-.133-.01-.252-.039-.381-.056V10H5.956c.019.067.043.13.058.2H4.981c-.023-.071-.062-.131-.089-.2H2V7.266a3.707 3.707 0 0 0-.108-.046l-.093-.035-.166-1.129.367.138V2h2.053a7.315 7.315 0 0 1-.094-.422l-.016-.1.989-.155.015.1c.007.04.042.254.126.577H10v5.014c.152.024.299.054.46.067l.1.008zm-.021-1.004l.1.008-.079.996-.1-.008c-.133-.01-.252-.039-.381-.056C5.759 7.455 4.385 3.332 4.053 2a7.315 7.315 0 0 1-.094-.422l-.016-.1.989-.155.015.1c.007.04.042.254.126.577C5.42 3.328 6.603 6.488 10 7.014c.152.024.299.054.46.067zM5.956 10c.019.067.043.13.058.2H4.981c-.023-.071-.062-.131-.089-.2A5.654 5.654 0 0 0 2 7.266a3.707 3.707 0 0 0-.108-.046l-.093-.035-.166-1.129.611.229c.14.052 2.995 1.168 3.712 3.715zM23 9V1H13v10h10zm-1-7v6h-4V7h2V5h1V2zm-3 3v1h-5V4h3v1zm1-3v2h-2V2zm-6 0h3v1h-3zm0 8V7h3v2h5v1zM1 23h10V13H1zm1-1v-1.614A4.076 4.076 0 0 0 3.313 20a2.44 2.44 0 0 0 .6-1.413c.125-1.22.36-1.595 1.65-1.586a1.976 1.976 0 0 1 1.8 1.003c1.01.879 1.552 1.282 2.292 1.048a3.123 3.123 0 0 1 .345-.08V22zm8-8v3.937a9.113 9.113 0 0 0-.646.161c-.501.159-.765-.247-1.528-.99a2.738 2.738 0 0 0-2.224-1.066 2.538 2.538 0 0 0-2.39 1.045c-.306.453.01 1.248-.5 2.038a1.199 1.199 0 0 1-.712.192V14z" />
              <path fill="none" d="M0 0h24v24H0z" />
            </svg>
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div className="dropdown-item">
              <input
                id="streets-v11"
                type="radio"
                name="rtoggle"
                value="streets"
                onChange={() => this.toggleMapMode('streets')}
                checked={this.state.mapStyle === 'streets'}
              />
              <label className="radio-label" htmlFor="streets-v11">
                Map
              </label>
            </div>
            <div className="dropdown-item">
              <input
                id="satellite-v9"
                type="radio"
                name="rtoggle"
                value="satellite"
                onChange={() => this.toggleMapMode('satellite')}
                checked={this.state.mapStyle === 'satellite'}
              />
              <label className="radio-label" htmlFor="satellite-v9">
                Satellite
              </label>
            </div>
            <div className="dropdown-item">
              <input
                id="grid-v9"
                type="radio"
                name="rtoggle"
                value="grid"
                onChange={() => this.toggleMapMode('grid')}
                checked={this.state.mapStyle === 'grid'}
              />
              <label className="radio-label" htmlFor="grid-v9">
                Outdoors
              </label>
            </div>
          </div>
        </div>

        {props.mapLayers.filter(
          item =>
            item.projectId !== '123456' &&
            item.name !== 'Observations' &&
            item.name !== 'Tracks' &&
            item.name !== 'Agents'
        ).length > 0 && (
          <div id="layers-menu" className="dropdown dropleft">
            <button
              className="svg-button"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg width="20" height="20" viewBox="0 0 900.000000 800.000000">
                <g
                  transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2255 6874 c-1227 -614 -2234 -1119 -2238 -1124 -5 -4 1002 -513
2238 -1131 l2246 -1123 2245 1122 c1235 617 2243 1126 2240 1130 -8 13 -4471
2242 -4487 2241 -8 0 -1017 -502 -2244 -1115z m3748 -375 l1502 -752 -1503
-751 -1502 -751 -1502 751 -1503 751 1500 751 c825 413 1501 751 1503 751 1 1
678 -337 1505 -750z"
                  />
                  <path
                    d="M453 4287 c-244 -122 -443 -224 -443 -227 0 -3 1010 -510 2245 -1128
l2245 -1122 2245 1122 c1235 618 2245 1125 2245 1128 0 3 -200 105 -445 228
l-445 222 -1800 -900 -1800 -900 -1800 900 c-990 495 -1801 900 -1802 899 -2
0 -202 -100 -445 -222z"
                  />
                  <path
                    d="M453 2597 c-244 -122 -443 -224 -443 -227 0 -3 1011 -510 2246 -1127
l2245 -1123 2244 1123 c1235 617 2245 1125 2245 1129 0 3 -201 106 -446 229
l-447 222 -1793 -896 c-987 -494 -1798 -897 -1804 -897 -5 0 -816 403 -1800
895 -984 492 -1793 895 -1797 895 -5 0 -207 -100 -450 -223z"
                  />
                </g>
              </svg>
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style={{ maxHeight: '250px', overflow: 'scroll' }}
            >
              {_.orderBy(
                _.sortBy(
                  props.mapLayers.filter(
                    item =>
                      item.projectId !== '123456' &&
                      item.name !== 'Observations' &&
                      item.name !== 'Tracks' &&
                      item.name !== 'Agents'
                  ),
                  function(sortItem) {
                    return sortItem.projectId == 'default' &&
                      (sortItem.id == 0 || sortItem.id == 1)
                      ? 0
                      : 1;
                  }
                ),
                [mapLayer => mapLayer.name.toLowerCase()],
                ['asc']
              ).map((item, key) => (
                <div key={key} className="dropdown-item">
                  <input
                    id={'layer-input-' + key}
                    type="checkbox"
                    name="rtoggle"
                    value={item.name}
                    onChange={() => this.toggleMapLayer(item.name)}
                    checked={this.props.selectedLayers.includes(item.name)}
                  />
                  <label className="radio-label" htmlFor={'layer-input-' + key}>
                    {item.name}
                  </label>
                  {this.state.loadingMapLayers[item.name] == true && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div id="layers-menu-common" className="p-2 mr-3">
          {_.sortBy(
            props.mapLayers.filter(
              item =>
                item.projectId !== '123456' &&
                (item.name === 'Observations' ||
                  item.name === 'Tracks' ||
                  item.name === 'Agents')
            ),
            function(sortItem) {
              return sortItem.projectId == 'default' &&
                (sortItem.id == 0 || sortItem.id == 1)
                ? 0
                : 1;
            }
          ).map((item, key) => (
            <div key={key}>
              <input
                id={'common-layer-input-' + key}
                type="checkbox"
                name="rtoggle"
                value={item.name}
                onChange={() => this.toggleMapLayer(item.name)}
                checked={this.props.selectedLayers.includes(item.name)}
              />
              <label
                className="radio-label pl-1"
                htmlFor={'common-layer-input-' + key}
              >
                {item.name}
              </label>
            </div>
          ))}
        </div>

        <MouseLocation
          ref={this.mouseLocationRef}
          lat={this.state.mouseLat}
          long={this.state.mouseLong}
        />

        <div
          aria-live="polite"
          aria-atomic="true"
          className="d-flex justify-content-center align-items-center"
        >
          <div
            className={
              this.state.toastOpen
                ? 'toast custom-toast show'
                : 'toast custom-toast hide'
            }
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header">
              <strong className="mr-auto">Audio</strong>
              <button
                type="button"
                className="ml-2 mb-1 close"
                data-dismiss="toast"
                aria-label="Close"
                onClick={() => this.setState({ toastOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="toast-body custom-toast-body">
              <span className="toast-description">
                Allow audio to play for Alert notifications
              </span>
              <button
                type="button"
                className="btn btn-primary btn-sm float-right custom-btn"
                onClick={() => {
                  this.props.allowAlertSounds();
                  this.setState({ toastOpen: false });
                }}
              >
                Allow
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm float-right custom-btn"
                onClick={() => this.setState({ toastOpen: false })}
              >
                Disallow
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const getMapLayers = state => state.layer.layers ?? [];
const getSelectedLayers = state => state.layer.selectedLayers || [];
const getTrackLayer = state => state.layer.trackLayer || {};
const getSelectedLanguage = state => state.ontology.selectedLanguage || 'en';
const getUpdateInterval = state => state.ontology.updateInterval || 300;
const getSelectedAreas = state => state.query.areas || [];
const getTimelineResults = state => state.timeline.results || [];
const getSelectedItem = state => state.timeline.selectedItem || {};
const getLKPUpdate = state => state.user.lastKnownPosition;
const getAlertFeatures = state => state.layer.alertFeatures || [];
const getAlertTimelineOpen = state => state.ontology.alertTimelineOpen || false;

const mapStateToProps = createSelector(
  getMapLayers,
  getSelectedLayers,
  getTrackLayer,
  getSelectedLanguage,
  getSelectedAreas,
  getTimelineResults,
  getSelectedItem,
  getLKPUpdate,
  getUpdateInterval,
  getAlertFeatures,
  getAlertTimelineOpen,
  (
    mapLayers,
    selectedLayers,
    trackLayer,
    selectedLanguage,
    selectedAreas,
    timelineResults,
    selectedItem,
    LKPUpdate,
    updateInterval,
    alertFeatures,
    alertTimelineOpen
  ) => ({
    mapLayers,
    selectedLayers,
    trackLayer,
    selectedLanguage,
    selectedAreas,
    timelineResults,
    selectedItem,
    LKPUpdate,
    updateInterval,
    alertFeatures,
    alertTimelineOpen
  })
);

const mapDispatchToProps = {
  getFilterTreeList: actions.ontology.getFilterTreeList,
  getAllLayerFeatures: actions.user.getAllLayerFeatures,
  getAlertLayerFeature: actions.user.getAlertLayerFeature,
  clearEntireTrack: actions.layer.clearEntireTrack,
  clearSelectedItem: actions.timeline.clearSelectedItem,
  getLayerFeatures: actions.user.getLayerFeatures,
  setSelectedLayers: actions.layer.setSelectedLayers,
  getSpecificLayer: actions.user.getSpecificLayer,
  setMapCoordinates: actions.query.setMapCoordinates,
  setSelectedAreas: actions.query.setSelectedAreas,
  setSelectedItem: actions.timeline.setSelectedItem,
  getEntireTrack: actions.layer.getEntireTrack,
  getTrackInfo: actions.layer.getTrackInfo,
  getSingleTrackItem: actions.timeline.getSingleTrackItem,
  getSingleItem: actions.timeline.getSingleItem,
  getTrackObservations: actions.layer.getTrackObservations,
  allowAlertSounds: actions.timeline.allowAlertSounds
};

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);
