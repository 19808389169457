import * as types from './actionTypes';

const setSearchQuery = query => ({
  type: types.SET_SEARCH_QUERY,
  payload: { query }
});

const setMapCoordinates = (lng, lat, north, east, south, west, zoom) => ({
  type: types.SET_MAP_COORDINATES,
  payload: { lng, lat, north, east, south, west, zoom }
});

const setSelectedAreas = selectedAreas => ({
  type: types.SET_SELECTED_AREAS,
  payload: { selectedAreas }
});

const setSelectedConcepts = selectedConcepts => ({
  type: types.SET_CONCEPTS,
  payload: { selectedConcepts }
});

const setMode = mode => ({
  type: types.SET_MODE,
  payload: { mode }
});

const setSelectedDataSources = selectedDataSources => ({
  type: types.SET_DATA_SOURCES,
  payload: { selectedDataSources }
});

const setSelectedAgents = selectedAgents => ({
  type: types.SET_AGENTS,
  payload: { selectedAgents }
});

const setStartEnd = (start, end) => ({
  type: types.SET_START_END,
  payload: { start, end }
});

export default {
  setSearchQuery,
  setMapCoordinates,
  setSelectedAreas,
  setSelectedConcepts,
  setMode,
  setSelectedDataSources,
  setSelectedAgents,
  setStartEnd
};
