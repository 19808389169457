import * as types from './actionTypes';

const openImageModal = data => ({
  type: types.OPEN_IMAGE_MODAL,
  payload: data
});

const closeImageModal = () => ({
  type: types.CLOSE_IMAGE_MODAL,
  payload: null
});

const openObservationModal = data => ({
  type: types.OPEN_OBSERVATION_MODAL,
  payload: data
});

const closeObservationModal = () => ({
  type: types.CLOSE_OBSERVATION_MODAL,
  payload: null
});

const openTrackModal = data => ({
  type: types.OPEN_TRACK_MODAL,
  payload: data
});

const closeTrackModal = () => ({
  type: types.CLOSE_TRACK_MODAL,
  payload: null
});

export default {
  openImageModal,
  closeImageModal,
  openObservationModal,
  closeObservationModal,
  openTrackModal,
  closeTrackModal
};
