import React, { Component } from 'react';

import moment from 'moment';

import './Observation.scss';

const capitalize = s => (s ? s[0].toUpperCase() + s.slice(1) : '');

class ObservationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      photosOpen: true,
      graphOpen: false,
      detailsOpen: true,
      allDataOpen: false,
      filesOpen: false,
      UTM: false
    };
  }

  hasMediaImage = media => {
    return media
      ? media.findIndex(element => element.Media.mediaType === 'jpeg')
      : -1;
  };

  handleSwitch = value => {
    this.setState({ UTM: value });
  };

  render() {
    const { item, ...props } = this.props;
    if (Array.isArray(item.where)) {
      item.where = item.where[0]; // [GJo] not supposed to be an Array
    }
    return (
      <div
        id={item.observationId}
        className={`result-item card container rounded-0 border-left-0 border-top-0 border-right-0 border-bottom p-0 ${
          props.selected ? 'selected' : ''
        }`}
        onClick={() => props.setSelectedItem(item)}
      >
        <div className="row no-gutters">
          <div className="col-auto  pl-2 pr-3 pt-3 pb-3 ml-1 photoItem">
            <div className="attachment-container">
              <span className="number">
                {item.media ? item.media.length : 0}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="card-block pl-0 pr-3 py-2 pt-3">
              <h6 className="card-title mb-2 mt-1 font-weight-bold pointer">
                {item.observationClass ? (
                  <span>{capitalize(item.observationClass)} - </span>
                ) : (
                  <span></span>
                )}
                {item.observationType === 'animal' && (
                  <span>
                    <img
                      src="./assets/img/icons/icon_animal.svg"
                      className="observation-type"
                      alt="animal"
                    />
                    Animal sighting
                    <span className="text-danger d-none">(!)</span>
                  </span>
                )}
                {item.observationType === 'infrastructure' && (
                  <span>
                    <img
                      src="./assets/img/icons/icon_infrastructure.svg"
                      className="observation-type"
                      alt="Infrastructure"
                    />
                    Point of interest
                  </span>
                )}
                {item.observationType === 'hwc' && (
                  <span>
                    <img
                      src="./assets/img/icons/icon_hwc.svg"
                      className="observation-type"
                      alt="Human wildlife conflict"
                    />
                    Human wildlife conflict
                  </span>
                )}
                {(item.observationType === 'offence' ||
                  item.observationType === 'human activity') && (
                  <span>
                    <img
                      src="./assets/img/icons/icon_humanactivity.svg"
                      className="observation-type"
                      alt="Human activity"
                    />
                    Human activity
                  </span>
                )}
                {item.observationType === 'a-find' && <span>A find</span>}
                {(item.observationType === 'community' ||
                  item.observationType === 'community_work') && (
                  <span>
                    <img
                      src="./assets/img/icons/icon_community.svg"
                      className="observation-type"
                      alt="Community work"
                    />
                    Community work
                  </span>
                )}
                {[
                  'animal',
                  'infrastructure',
                  'hwc',
                  'offence',
                  'human activity',
                  'a-find',
                  'community',
                  'community_work'
                ].indexOf(item.observationType) < 0 && (
                  <span>{capitalize(item.observationType)}</span>
                )}
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm float-right btn-collapse"
                  data-toggle="collapse"
                  href="#multiCollapseExample2"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                  onClick={e => {
                    e.stopPropagation();
                    props.setSelectedItem(item);
                    props.openObservation(item);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                    />
                  </svg>
                </button>
              </h6>
              <p className="card-text mb-1 text-muted font-weight-light">
                <span>{moment(item.when).format('DD MMM YYYY - HH:mm')}</span>
                {item.agent && item.agent.agentName ? (
                  <span> • {capitalize(item.agent.agentName)}</span>
                ) : (
                  <span></span>
                )}
              </p>
              <p className="card-text mb-2 text-muted font-weight-light">
                {item.description || 'No description'}
              </p>
              {Array.isArray(item.concepts) &&
                item.concepts.map((item, key) => (
                  <span
                    key={key}
                    className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase"
                  >
                    {item.label}
                  </span>
                ))}
              {!Array.isArray(item.concepts) && (
                <span className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase">
                  {item.concepts}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ObservationItem;
