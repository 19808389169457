import React, { Component } from 'react';
import _ from 'lodash';

import SearchBar from './header/SearchBar';
import Filter from './filter/Filter';
import MapComponent from './map/MapComponent';
import ObservationComponent from './observation/ObservationComponent';

import './map/Map.scss';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      filterExpanded: false,
      resultsCollapsed: false,
      selectedAreas: [],
      selectedDataSources: [],
      selectedAgents: [],
      start: null,
      end: null,
      filterTreeFetched: false
    };

    this.fetchAllData = _.debounce(this.fetchAllData, 500);
  }

  async componentDidMount() {
    await this.props.getAggregates();
    await this.props.getFilterTreeList();
    this.setState({ filterTreeFetched: true });
  }

  getTimeline = async () => {
    await this.props.setScrollToTop(true);
    await this.props.getTimeline(false);
    await this.props.setScrollToTop(false);
  };

  fetchAllData = async () => {
    await this.getTimeline();
    await this.props.getAllLayerFeatures();
    await this.props.getConceptsCount();
    await this.props.getDataSources();
    await this.props.getAgents();
    await this.props.getAlertTimeline();
    await this.props.getAlertLayerFeature();
  };

  collapseFilter = async () => {
    await this.setState({ filterCollapsed: !this.state.filterCollapsed });
  };

  expandFilter = async () => {
    await this.setState({ filterExpanded: !this.state.filterExpanded });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <SearchBar
          setTimePeriod={this.setTimePeriod}
          toggleMapLayer={this.toggleMapLayer}
          fetchAllData={this.fetchAllData}
        />
        <div className="row map no-gutters">
          <Filter
            filterCollapsed={this.state.filterCollapsed}
            filterExpanded={this.state.filterExpanded}
            fetchAllData={this.fetchAllData}
          />
          <MapComponent
            filterCollapsed={this.state.filterCollapsed}
            filterExpanded={this.state.filterExpanded}
            handleExpandFilter={this.expandFilter}
            handleCollapseFilter={this.collapseFilter}
            fetchAllData={this.fetchAllData}
            filterTreeFetched={this.state.filterTreeFetched}
          />
          <ObservationComponent
            resultsCollapsed={this.state.resultsCollapsed}
          />
        </div>
      </div>
    );
  }
}

export default Home;
