import React, { Component } from 'react';

class FileItem extends Component {
  render() {
    return (
      <div className="card-body px-1 pb-0">
        <div className="row">
          <div className="col"></div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm float-right"
            >
              {this.props.file.name}
            </button>
          </div>
        </div>
        <img
          src={this.props.file.data}
          className="img-fluid rounded"
          alt=""
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
    );
  }
}

export default FileItem;
