export const SET_AGGREGATES = 'timeline/SET_AGGREGATES';
export const SET_TIMELINE_SUCCESS = 'timeline/SET_TIMELINE_SUCCESS';
export const START_TIMELINE_FETCH = 'timeline/START_TIMELINE_FETCH';
export const SET_OBSERVATION_MEDIA = 'timeline/SET_OBSERVATION_MEDIA';
export const SET_SELECTED_ITEM = 'timeline/SET_SELECTED_ITEM';
export const SET_SELECTED_OBSERVATION_MEDIA =
  'timeline/SET_SELECTED_OBSERVATION_MEDIA';
export const CLEAR_SELECTED_ITEM = 'timeline/CLEAR_SELECTED_ITEM';
export const SET_DATA_SOURCES = 'timeline/SET_DATA_SOURCES';
export const SET_AGENTS = 'timeline/SET_AGENTS';
export const SET_SCROLL_TO_TOP = 'timeline/SET_SCROLL_TO_TOP';
export const SET_ALERTS = 'timeline/SET_ALERTS';
export const ALLOW_ALERT_SOUNDS = 'timeline/ALLOW_ALERT_SOUNDS';
export const DISABLE_ALERT_SOUNDS = 'timeline/DISABLE_ALERT_SOUNDS';
