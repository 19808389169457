import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

import ObservationList from './ObservationList';
import ObservationView from './ObservationView';
import ImageModal from '../../modals/ImageModal';
import ObservationModal from '../../modals/ObservationModal';
import TrackModal from '../../modals/TrackModal';
import actions from '../../../store/appActions';

import './Observation.scss';
import AlertsList from './AlertsList';

class ObservationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { ...props } = this.props;

    return (
      <div
        className={
          this.props.resultsCollapsed
            ? 'px-0 col-0 timeline border-right-0 h-100'
            : 'px-0 col-3 timeline border-right-0 h-100'
        }
      >
        {_.isEmpty(props.timelineSelectedItem) &&
          (props.alertTimelineOpen ? (
            <AlertsList resultsCollapsed={props.resultsCollapsed} />
          ) : (
            <ObservationList resultsCollapsed={props.resultsCollapsed} />
          ))}
        {!_.isEmpty(props.timelineSelectedItem) && (
          <ObservationView
            item={props.timelineSelectedItem}
            resultsCollapsed={props.resultsCollapsed}
          />
        )}
        <ImageModal
          close={props.closeImageModal}
          open={props.imageModalOpen}
          data={props.imageModalData}
        />
        <ObservationModal
          close={props.closeObservationModal}
          open={props.observationModalOpen}
          data={props.observationModalData}
          openImage={props.openImageModal}
        />
        <TrackModal
          close={props.closeTrackModal}
          open={props.trackModalOpen}
          data={props.trackModalData}
          openImage={props.openImageModal}
        />
      </div>
    );
  }
}

const getTimelineSelectedItem = state => state.timeline.selectedItem || {};
const getImageModalOpen = state => state.modals.imageModal.open || false;
const getImageModalData = state => state.modals.imageModal.data || {};
const getObservationModalOpen = state =>
  state.modals.observationModal.open || false;
const getObservationModalData = state =>
  state.modals.observationModal.data || {};
const getTrackModalOpen = state => state.modals.trackModal.open || false;
const getTrackModalData = state => state.modals.trackModal.data || {};

const getAlertTimelineOpen = state => state.ontology.alertTimelineOpen || false;

const mapStateToProps = createSelector(
  getTimelineSelectedItem,
  getImageModalOpen,
  getImageModalData,
  getObservationModalOpen,
  getObservationModalData,
  getTrackModalOpen,
  getTrackModalData,
  getAlertTimelineOpen,
  (
    timelineSelectedItem,
    imageModalOpen,
    imageModalData,
    observationModalOpen,
    observationModalData,
    trackModalOpen,
    trackModalData,
    alertTimelineOpen
  ) => ({
    timelineSelectedItem,
    imageModalOpen,
    imageModalData,
    observationModalOpen,
    observationModalData,
    trackModalOpen,
    trackModalData,
    alertTimelineOpen
  })
);

const mapDispatchToProps = {
  openImageModal: actions.modals.openImageModal,
  closeImageModal: actions.modals.closeImageModal,
  openObservationModal: actions.modals.openObservationModal,
  closeObservationModal: actions.modals.closeObservationModal,
  openTrackModal: actions.modals.openTrackModal,
  closeTrackModal: actions.modals.closeTrackModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationComponent);
