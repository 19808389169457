import util from './util.js';

let ABORT_CONTROLLERS = new Map();

const provider = (function() {
  const provide = function(config = {}) {
    // needed for offline testing with Jest
    const headers = config.cookie ? { Cookie: config.cookie } : {};

    // returns overview of all projects and layers per project that are visible to the current user
    const describe = function(signalKey) {
      return fetch(
        util.buildUrl(
          '/api/map/' + config.searchType + '/describe',
          null,
          config.baseURI
        ),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin',
          signal: signalKey
            ? util.abortAndGetSignal(signalKey, ABORT_CONTROLLERS)
            : null
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    // returns list of features for particular layer in project, filtered by given search constraints, paged per 500
    const features = function(
      signalKey,
      projectId,
      layerId,
      searchQuery = {},
      start = 1,
      pageLength = 500
    ) {
      return fetch(
        util.buildUrl(
          '/api/map/' +
            config.searchType +
            '/' +
            encodeURIComponent(projectId) +
            '/' +
            encodeURIComponent(layerId) +
            '/features',
          null,
          config.baseURI
        ),
        {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            ...searchQuery,
            start,
            pageLength
          }),
          credentials: 'same-origin',
          signal: signalKey
            ? util.abortAndGetSignal(signalKey, ABORT_CONTROLLERS)
            : null
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    return {
      name: 'MapClient',
      describe,
      features
    };
  };

  return provide;
})();

export default provider;
