import * as types from './actionTypes';
import * as authTypes from '../user/actionTypes';
// import GeoFeatureActions from '../geoFeature/actions'
import UserActions from '../user/actions';

import SearchClient from '../../focus-client/search-client';
import ObservationClient from '../../focus-client/crud-client/observation-client';
import GeoFeatureClient from '../../focus-client/crud-client/geofeature-client';

import { createQuery } from '../../utils/queryUtil';

const getAggregates = () => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return dispatch => {
    let search = SearchClient(config);

    return search.aggregates({}).then(response => {
      if (!response.isError) {
        dispatch(setAggregates(response));
      } else if (response.error.status === 401) {
        dispatch(localLogout());
      }
    });
  };
};

const localLogout = () => ({
  type: authTypes.LOCAL_LOGOUT
});

const setAggregates = values => ({
  type: types.SET_AGGREGATES,
  payload: values
});

const startTimelineFetch = () => ({
  type: types.START_TIMELINE_FETCH,
  payload: null
});

const setScrollToTop = value => ({
  type: types.SET_SCROLL_TO_TOP,
  payload: value
});

const getAlertTimeline = scroll => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let search = SearchClient(config);

    let alertQuery = createQuery(
      scroll,
      true,
      true,
      true,
      true,
      getState().timeline.results.length,
      null,
      getState().query.areas,
      [],
      getState().query.mode,
      [],
      []
    );

    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);

    alertQuery.withDateTimeRange(now, oneHourAgo);
    // get alerts

    return search
      .results(alertQuery, getState().ontology.selectedLanguage, 'get-alerts')
      .then(alertResponse => {
        if (!alertResponse.isError) {
          alertResponse.results = alertResponse.results.filter(item => {
            let foundObservation = item.extracted.content.find(val => {
              return val.Observation?.observationClass === 'alert';
            });
            return !!foundObservation;
          });
          alertResponse.total = alertResponse.results.length;

          dispatch(setAlerts(alertResponse));
        } else if (alertResponse.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const getTimeline = scroll => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let search = SearchClient(config);
    let observation = ObservationClient(config);
    let coordinates = {
      north: getState().query.north,
      east: getState().query.east,
      south: getState().query.south,
      west: getState().query.west
    };

    const query = createQuery(
      scroll,
      true,
      true,
      true,
      true,
      getState().timeline.results.length,
      coordinates,
      getState().query.areas,
      getState().query.concepts,
      getState().query.mode,
      getState().query.dataSources,
      getState().query.agents
    );
    if (getState().query.searchQuery !== '')
      query.withQueryText(getState().query.searchQuery);
    if (getState().query.start !== null && getState().query.end !== null)
      query.withDateTimeRange(getState().query.end, getState().query.start);

    return search
      .results(query, getState().ontology.selectedLanguage, 'get-timeline')
      .then(response => {
        if (!response.isError) {
          dispatch(setTimeline(response));
          dispatch(UserActions.setLastKnownUpdate());

          response.results.forEach(item => {
            observation
              .media(item.id, getState().ontology.selectedLanguage)
              .then(mediaResponse => {
                if (mediaResponse.length > 0) {
                  dispatch(setObservationMedia(item.id, mediaResponse));
                }
              });
          });
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const setTimeline = values => ({
  type: types.SET_TIMELINE_SUCCESS,
  payload: values
});

const setAlerts = values => ({
  type: types.SET_ALERTS,
  payload: values
});

const setObservationMedia = (observationId, values) => ({
  type: types.SET_OBSERVATION_MEDIA,
  payload: { observationId, values }
});

const getSingleItem = value => {
  var config = {
    baseURI: document.baseURI
  };

  return (dispatch, getState) => {
    let observation = ObservationClient(config);

    return observation
      .read(value, getState().ontology.selectedLanguage)
      .then(response => {
        if (!response.isError) {
          dispatch(setSelectedItem(response.envelope.instance.Observation));

          observation
            .media(value, getState().ontology.selectedLanguage)
            .then(mediaResponse => {
              if (mediaResponse.length > 0) {
                dispatch(setSelectedObservationMedia(mediaResponse));
              }
            });
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const setSelectedObservationMedia = values => ({
  type: types.SET_SELECTED_OBSERVATION_MEDIA,
  payload: values
});

const getSingleTrackItem = value => {
  var config = {
    baseURI: document.baseURI
  };

  return (dispatch, getState) => {
    let geoFeature = GeoFeatureClient(config);

    return geoFeature
      .read(value, getState().ontology.selectedLanguage)
      .then(response => {
        if (!response.isError) {
          dispatch(setSelectedItem(response.envelope.instance.GeoFeature));
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const getDataSources = () => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let search = SearchClient(config);
    let coordinates = {
      north: getState().query.north,
      east: getState().query.east,
      south: getState().query.south,
      west: getState().query.west
    };

    const query = createQuery(
      false,
      true,
      false,
      true,
      true,
      null,
      coordinates,
      getState().query.areas,
      getState().query.concepts,
      getState().query.mode,
      getState().query.dataSources,
      getState().query.agents
    );
    if (getState().query.searchQuery !== '')
      query.withQueryText(getState().query.searchQuery);
    if (getState().query.start !== null && getState().query.end !== null)
      query.withDateTimeRange(getState().query.end, getState().query.start);

    return search.facets(query, 'get-data-sources').then(response => {
      if (!response.isError) {
        dispatch(setDataSources(response));
      } else if (response.error.status === 401) {
        dispatch(localLogout());
      }
    });
  };
};

const setDataSources = value => ({
  type: types.SET_DATA_SOURCES,
  payload: value
});

const getAgents = () => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let search = SearchClient(config);
    let coordinates = {
      north: getState().query.north,
      east: getState().query.east,
      south: getState().query.south,
      west: getState().query.west
    };

    const query = createQuery(
      false,
      true,
      true,
      true,
      false,
      null,
      coordinates,
      getState().query.areas,
      getState().query.concepts,
      getState().query.mode,
      getState().query.dataSources,
      getState().query.agents
    );
    if (getState().query.searchQuery !== '')
      query.withQueryText(getState().query.searchQuery);
    if (getState().query.start !== null && getState().query.end !== null)
      query.withDateTimeRange(getState().query.end, getState().query.start);

    return search.facets(query, 'get-agents').then(response => {
      if (!response.isError) {
        dispatch(setAgents(response));
      } else if (response.error.status === 401) {
        dispatch(localLogout());
      }
    });
  };
};

const setAgents = value => ({
  type: types.SET_AGENTS,
  payload: value
});

const setSelectedItem = value => (dispatch, getState) => {
  const alertTimelineOpen = getState().ontology.alertTimelineOpen;
  dispatch({
    type: types.SET_SELECTED_ITEM,
    payload: value,
    alertTimelineOpen: alertTimelineOpen
  });
};

const clearSelectedItem = () => ({
  type: types.CLEAR_SELECTED_ITEM,
  payload: null
});

const allowAlertSounds = () => ({
  type: types.ALLOW_ALERT_SOUNDS,
  payload: null
});

const disableAlertSounds = () => ({
  type: types.DISABLE_ALERT_SOUNDS,
  payload: null
});

export default {
  getAggregates,
  getTimeline,
  getAlertTimeline,
  startTimelineFetch,
  setScrollToTop,
  getSingleItem,
  getSingleTrackItem,
  // getFacets,
  getDataSources,
  getAgents,
  setSelectedItem,
  clearSelectedItem,
  allowAlertSounds,
  disableAlertSounds
};
