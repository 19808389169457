import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import Switch from 'react-switch';

import moment from 'moment';

import { getUTM } from '../../../utils/locationUtil';

import './Observation.scss';

class TrackItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      UTM: false
    };
  }

  handleSwitch = value => {
    this.setState({ UTM: value });
  };

  render() {
    const { item, ...props } = this.props;
    return (
      <div
        id={item.featureId}
        className={`result-item card container rounded-0 border-left-0 border-top-0 border-right-0 border-bottom p-0 ${
          props.selected ? 'selected' : ''
        }`}
      >
        <div className="row no-gutters">
          <div className="col-auto pl-2 pr-3 pt-3 pb-3 ml-1 photoItem">
            {/* <div className="attachment-container"> */}
            <img
              src="./assets/img/photos/track-placeholder.jpg"
              className="img-fluid rounded img"
              alt=""
              style={{ width: '3rem', height: '3rem' }}
            />
            {/* </div> */}
          </div>
          <div className="col">
            <div className="card-block pl-0 pr-3 py-2 pt-3">
              <h6 className="card-title mb-2 mt-1 font-weight-bold pointer">
                {item.featureType === 'track/onPatrol' && (
                  <span
                    className=""
                    onClick={() => props.setSelectedItem(item)}
                  >
                    Track - On Patrol
                  </span>
                )}
                {item.featureType === 'track/onDuty' && (
                  <span
                    className=""
                    onClick={() => props.setSelectedItem(item)}
                  >
                    Track - On Duty
                  </span>
                )}
                {item.featureType === 'track/onFootPatrol' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - Foot Patrol
                  </span>
                )}
                {item.featureType === 'track/onVehiclePatrol' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - Vehicle Patrol
                  </span>
                )}
                {item.featureType === 'track/onAirPatrol' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - Air Patrol
                  </span>
                )}
                {item.featureType === 'track/onHumanSpoor' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Human Spoor
                  </span>
                )}
                {item.featureType === 'track/onAnimalSpoor' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Animal Spoor
                  </span>
                )}
                {item.featureType === 'track/onSafari' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Safari
                  </span>
                )}
                {item.featureType === 'track/onCensus' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - Census
                  </span>
                )}
                {item.featureType === 'track/onFenceInspection' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - Fence Inspection
                  </span>
                )}
                {item.featureType === 'track/onK9Patrol' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On K9 Patrol
                  </span>
                )}
                {item.featureType === 'track/onPerimeterTrack' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Perimeter Track
                  </span>
                )}
                {item.featureType === 'track/onSearch' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Search
                  </span>
                )}
                {item.featureType === 'track/onSnareSweep' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Snare Sweep
                  </span>
                )}
                {item.featureType === 'track/onBoatPatrol' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Boat Patrol
                  </span>
                )}
                {item.featureType === 'track/onDronePatrol' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Drone Patrol
                  </span>
                )}
                {item.featureType === 'track/onFastResponse' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - On Fast Response
                  </span>
                )}
                {item.featureType === 'track/onTraining' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - Training
                  </span>
                )}
                {item.featureType === 'track/onBikePatrol' && (
                  <span onClick={() => props.setSelectedItem(item)}>
                    Track - Bike Patrol
                  </span>
                )}
                {item.featureType === 'track/undefined' && (
                  <span onClick={() => props.setSelectedItem(item)}>Track</span>
                )}
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm float-right btn-collapse"
                  data-toggle="collapse"
                  href="#multiCollapseTrack"
                  aria-expanded="false"
                  aria-controls="multiCollapseTrack"
                  onClick={() => {
                    props.setSelectedItem(item);
                    props.openTrack(item);
                    // this.setState({ open: !this.state.open})
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                    />
                  </svg>
                </button>
              </h6>
              <div className="card-text mb-2 text-muted font-weight-light ">
                {item.agent && (
                  <div className="mb-1">
                    <span className="">Agent name • </span>
                    <span className="">{item.agent.agentName}</span>
                  </div>
                )}
                {item.startWhen && (
                  <div className="mb-1">
                    <span className="">Start track • </span>
                    <span className="">
                      {moment(item.startWhen).format('DD MMM YYYY - HH:mm')}
                    </span>
                  </div>
                )}
                {item.endWhen && (
                  <div className="mb-1">
                    <span className="">End track • </span>
                    <span className="">
                      {moment(item.endWhen).format('DD MMM YYYY - HH:mm')}
                    </span>
                  </div>
                )}
                {item['length'] != undefined && (
                  <div className="mb-1">
                    <span className="">Length • </span>
                    <span className="">{item.length.toFixed(3)} KM</span>
                  </div>
                )}
                {Array.isArray(item.concepts) &&
                  item.concepts.map((item, key) => (
                    <span
                      key={key}
                      className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase"
                    >
                      {item.label}
                    </span>
                  ))}
                {!Array.isArray(item.concepts) && (
                  <span className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase">
                    {item.concepts}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col mt-0">
            <Collapse in={this.state.open}>
              <div id="multiCollapseTrack">
                <div className="card card-body rounded-0 border  m-3 mt-0 pb-0 bg-light">
                  <ul className="list-group ">
                    <form>
                      <div className="form-group">
                        {item.agent && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Name
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {item.agent.agentName}
                            </label>
                          </div>
                        )}

                        {item.startWhen && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-12 col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Start track
                            </label>
                            <label
                              htmlFor=""
                              className="col-12 col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {moment(item.startWhen).format(
                                'DD MMM YYYY - HH:mm'
                              )}
                            </label>
                          </div>
                        )}

                        {item.endWhen && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              End track
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {moment(item.endWhen).format(
                                'DD MMM YYYY - HH:mm'
                              )}
                            </label>
                          </div>
                        )}

                        {item.group && item.group.groupName && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Group
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {item.group.groupName}
                            </label>
                          </div>
                        )}

                        {item['length'] != undefined && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Length
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {item.length.toFixed(2)} KM
                            </label>
                          </div>
                        )}

                        {item.attributes.find(
                          attribute => attribute.key === 'track_description'
                        ) != undefined && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Description
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {
                                item.attributes.find(
                                  attribute =>
                                    attribute.key === 'track_description'
                                ).value
                              }
                            </label>
                          </div>
                        )}

                        {item.attributes.find(
                          attribute => attribute.key === 'track_team_members'
                        ) != undefined && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Team members
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {
                                item.attributes.find(
                                  attribute =>
                                    attribute.key === 'track_team_members'
                                ).value
                              }
                            </label>
                          </div>
                        )}

                        {item.startWhere && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Start point
                            </label>
                            {!this.state.UTM && (
                              <label
                                htmlFor=""
                                className="col-md-6 col-lg-9 col-form-label font-weight-light"
                              >
                                {item.startWhere.coordinates[0]},{' '}
                                {item.startWhere.coordinates[1]}
                              </label>
                            )}
                            {this.state.UTM && (
                              <label
                                htmlFor=""
                                className="col-md-6 col-lg-9 col-form-label font-weight-light"
                              >
                                {getUTM(
                                  item.startWhere.coordinates[1],
                                  item.startWhere.coordinates[0]
                                )}
                              </label>
                            )}
                          </div>
                        )}

                        {item.endWhere && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              End point
                            </label>
                            {!this.state.UTM && (
                              <label
                                htmlFor=""
                                className="col-md-6 col-lg-9 col-form-label font-weight-light"
                              >
                                {item.endWhere.coordinates[0]},{' '}
                                {item.endWhere.coordinates[1]}
                              </label>
                            )}
                            {this.state.UTM && (
                              <label
                                htmlFor=""
                                className="col-md-6 col-lg-9 col-form-label font-weight-light"
                              >
                                {getUTM(
                                  item.endWhere.coordinates[1],
                                  item.endWhere.coordinates[0]
                                )}
                              </label>
                            )}
                          </div>
                        )}

                        {(item.startWhere || item.endWhere) && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              WGS84
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-2 col-form-label font-weight-light m-0 p-0 mt-1"
                            >
                              <Switch
                                height={21}
                                width={42}
                                onChange={this.handleSwitch}
                                checked={this.state.UTM}
                                uncheckedIcon={false}
                                checkedIcon={false}
                              />{' '}
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              UTM
                            </label>
                          </div>
                        )}

                        {item.featureId && (
                          <div className="row no-gutters">
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-3 col-form-label text-muted"
                            >
                              Track ID
                            </label>
                            <label
                              htmlFor=""
                              className="col-md-6 col-lg-9 col-form-label font-weight-light"
                            >
                              {item.featureId}
                            </label>
                          </div>
                        )}
                      </div>
                    </form>
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

export default TrackItem;
