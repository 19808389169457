import * as types from './actionTypes';
import * as authTypes from '../user/actionTypes';

import OntologyClient from '../../focus-client/ontology-client';

import { createQuery } from '../../utils/queryUtil';

const getLanguages = () => {
  var config = {
    baseURI: document.baseURI
  };

  return dispatch => {
    let ontology = OntologyClient(config);

    return ontology.languages().then(response => {
      if (!response.isError) {
        dispatch(setLanguages(response));
      } else if (response.error.status === 401) {
        dispatch(localLogout());
      }
    });
  };
};

const getFilterTreeList = (values, optionalArgs = {}) => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let ontology = OntologyClient(config);

    return ontology
      .hierarchy(getState().ontology.selectedLanguage)
      .then(response => {
        if (!response.isError) {
          dispatch(setFilterTreeList(response));
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const getConceptsCount = () => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let ontology = OntologyClient(config);
    let coordinates = {
      north: getState().query.north,
      east: getState().query.east,
      south: getState().query.south,
      west: getState().query.west
    };

    const query = createQuery(
      false,
      false,
      true,
      true,
      true,
      true,
      coordinates,
      getState().query.areas,
      getState().query.concepts,
      getState().query.mode,
      getState().query.dataSources,
      getState().query.agents
    );
    if (getState().query.searchQuery !== '')
      query.withQueryText(getState().query.searchQuery);
    if (getState().query.start !== null && getState().query.end !== null)
      query.withDateTimeRange(getState().query.end, getState().query.start);

    delete query.options;
    return ontology.counts(query, 'concepts-count').then(response => {
      if (!response.isError) {
        dispatch(setConceptsCount(response));
      } else if (response.error.status === 401) {
        dispatch(localLogout());
      }
    });
  };
};

const localLogout = () => ({
  type: authTypes.LOCAL_LOGOUT
});

const setLanguages = languages => ({
  type: types.SET_LANGUAGES,
  payload: languages
});

const setSelectedLanguage = selectedLanguage => ({
  type: types.SET_SELECTED_LANGUAGE,
  payload: selectedLanguage
});

const setUpdateInterval = interval => ({
  type: types.SET_UPDATE_INTERVAL,
  payload: interval
});

const setAlertTimeline = value => ({
  type: types.SET_ALERT_TIMELINE,
  payload: value
});

const setFilterTreeList = ontology => ({
  type: types.SET_ONTOLOGY_SUCCESS,
  payload: { ontology }
});

const setConceptsCount = count => ({
  type: types.SET_CONCEPTS_COUNT,
  payload: count
});

export default {
  getLanguages,
  getFilterTreeList,
  getConceptsCount,
  setSelectedLanguage,
  setUpdateInterval,
  setAlertTimeline
};
