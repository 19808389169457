import * as types from './actionTypes';
import LayerActions from '../layer/actions';

import MapClient from '../../focus-client/map-client';
import AuthClient from '../../focus-client/auth-client';

import { createQuery } from '../../utils/queryUtil';

const completeLogin = user => ({
  type: types.NETWORK_LOGIN_SUCCESS,
  payload: { user }
});

const unhappyLogin = message => ({
  type: types.NETWORK_LOGIN_FAILURE,
  payload: { message }
});

const setCurrentUser = username => ({
  type: types.SET_CURRENT_USER,
  payload: { username }
});

const submitLogin = (username, password, extraArgs = {}) => {
  var config = {
    baseURI: document.baseURI
  };

  return dispatch => {
    let auth = AuthClient(config);

    return auth.login(username, password).then(json => {
      if (json.authenticated) {
        dispatch(setCurrentUser(username));
        dispatch(completeLogin({ username }));
      } else if (json.isError) {
        dispatch(unhappyLogin(json.error.message));
      }
    });
  };
};

const completeNetworkLogout = username => ({
  type: types.NETWORK_LOGOUT_SUCCESS,
  payload: { username }
});

const submitLogout = (username, extraArgs = {}) => {
  var config = {
    baseURI: document.baseURI
  };

  return dispatch => {
    let auth = AuthClient(config);

    return auth.logout(username).then(response => {
      if (response.ok) {
        dispatch(completeNetworkLogout(username));
      }
    });
  };
};

const localLogout = () => ({
  type: types.LOCAL_LOGOUT
});

const getAuthenticationStatus = (extraArgs = {}) => {
  var config = {
    baseURI: document.baseURI
  };

  return dispatch => {
    let auth = AuthClient(config);

    return auth.status().then(response => {
      if (response.authenticated) {
        dispatch({
          type: types.FETCH_AUTHSTATUS_SUCCESS,
          payload: { user: response }
        });
      }
    });
  };
};

const describeMap = () => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return dispatch => {
    let map = MapClient(config);

    return map.describe('describe-map').then(response => {
      if (!response.isError) {
        dispatch(LayerActions.setProjectLayers(response.models));
      } else if (response.error.status === 401) {
        dispatch(localLogout());
      }
    });
  };
};

const getAlertLayerFeature = () => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let mapLayer = getState().layer.layers.find(
      item => item.name === 'Observations'
    );
    let alertQuery = createQuery(
      false,
      false,
      false,
      false,
      false,
      null,
      null,
      getState().query.areas,
      [],
      getState().query.mode,
      [],
      []
    );

    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);

    alertQuery.withDateTimeRange(now, oneHourAgo);

    let map = MapClient(config);

    return map
      .features(
        mapLayer.name,
        mapLayer.projectId,
        mapLayer.id,
        alertQuery,
        1,
        500
      )
      .then(response => {
        if (!response.isError) {
          response.features = response.features.filter(item => {
            if (item.properties.EntityClass === 'alert') {
              return item;
            }
          });
          let selectedItem = null;
          if (getState().timeline.selectedItem.observationId) {
            selectedItem = getState().timeline.selectedItem.observationId;
          } else if (getState().timeline.selectedItem.featureId) {
            selectedItem = getState().timeline.selectedItem.featureId;
          }

          let selectedTrackObservations = null;
          if (getState().timeline.selectedTrackObservations.length > 0) {
            selectedTrackObservations = getState().timeline.selectedTrackObservations.map(
              item => item.Observation.observationId
            );
          }
          dispatch(
            LayerActions.setAlertFeatures(
              mapLayer.projectId,
              mapLayer.id,
              response,
              selectedItem,
              selectedTrackObservations
            )
          );
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const getAllLayerFeatures = () => {
  return (dispatch, getState) => {
    let coordinates = {
      north: getState().query.north,
      east: getState().query.east,
      south: getState().query.south,
      west: getState().query.west
    };
    getState()
      .layer.layers.filter(item => item.projectId !== '123456')
      .forEach(mapLayer => {
        let query = null;
        if (mapLayer.name !== 'Agents') {
          if (mapLayer.projectId == 'default') {
            query = createQuery(
              false,
              true,
              true,
              false,
              true,
              null,
              coordinates,
              getState().query.areas,
              getState().query.concepts,
              getState().query.mode,
              getState().query.dataSources,
              getState().query.agents
            );
          } else {
            query = createQuery(
              false,
              false,
              false,
              false,
              false,
              null,
              coordinates,
              getState().query.areas,
              getState().query.concepts,
              getState().query.mode,
              getState().query.dataSources,
              getState().query.agents
            );
          }
        } else if (mapLayer.name === 'Agents') {
          if (mapLayer.projectId == 'default') {
            query = createQuery(
              false,
              false,
              false,
              false,
              false,
              null,
              coordinates,
              getState().query.areas,
              getState().query.concepts,
              getState().query.mode,
              getState().query.dataSources,
              getState().query.agents
            );
          } else {
            query = createQuery(
              false,
              false,
              false,
              false,
              false,
              null,
              coordinates,
              getState().query.areas,
              getState().query.concepts,
              getState().query.mode,
              getState().query.dataSources,
              getState().query.agents
            );
          }
        }

        if (mapLayer.projectId == 'default') {
          if (getState().query.searchQuery !== '' && mapLayer.name !== 'Agents')
            query.withQueryText(getState().query.searchQuery);
          if (
            getState().query.start !== null &&
            getState().query.end !== null &&
            mapLayer.name !== 'Agents'
          )
            query.withDateTimeRange(
              getState().query.end,
              getState().query.start
            );
        }
        delete query.options;
        if (getState().layer.selectedLayers.includes(mapLayer.name)) {
          dispatch(
            getLayerFeatures(
              mapLayer.name,
              mapLayer.projectId,
              mapLayer.id,
              query,
              1,
              500
            )
          );
        }
      });
  };
};

const getSpecificLayer = layer => {
  return (dispatch, getState) => {
    let coordinates = {
      north: getState().query.north,
      east: getState().query.east,
      south: getState().query.south,
      west: getState().query.west
    };
    let query = null;
    if (layer.projectId == 'default') {
      query = createQuery(
        false,
        true,
        true,
        false,
        true,
        null,
        coordinates,
        getState().query.areas,
        getState().query.concepts,
        getState().query.mode,
        getState().query.dataSources,
        getState().query.agents
      );
    } else {
      query = createQuery(
        false,
        false,
        false,
        false,
        false,
        null,
        coordinates,
        getState().query.areas,
        getState().query.concepts,
        getState().query.mode,
        getState().query.dataSources,
        getState().query.agents
      );
    }
    if (layer.projectId == 'default') {
      if (getState().query.searchQuery !== '')
        query.withQueryText(getState().query.searchQuery);
      if (
        getState().query.start !== null &&
        getState().query.end !== null &&
        layer.name !== 'Agents'
      )
        query.withDateTimeRange(getState().query.end, getState().query.start);
    }

    delete query.options;

    dispatch(
      getLayerFeatures(layer.name, layer.projectId, layer.id, query, 1, 500)
    );
  };
};

const getLayerFeatures = (
  signalKey,
  projectId,
  layerId,
  searchQuery,
  start,
  pageLength
) => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let map = MapClient(config);

    return map
      .features(signalKey, projectId, layerId, searchQuery, start, pageLength)
      .then(response => {
        if (!response.isError) {
          let selectedItem = null;
          if (getState().timeline.selectedItem.observationId) {
            selectedItem = getState().timeline.selectedItem.observationId;
          } else if (getState().timeline.selectedItem.featureId) {
            selectedItem = getState().timeline.selectedItem.featureId;
          }

          let selectedTrackObservations = null;
          if (getState().timeline.selectedTrackObservations.length > 0) {
            selectedTrackObservations = getState().timeline.selectedTrackObservations.map(
              item => item.Observation.observationId
            );
          }
          dispatch(
            LayerActions.setLayerFeatures(
              projectId,
              layerId,
              response,
              selectedItem,
              selectedTrackObservations
            )
          );
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const setLastKnownUpdate = () => ({
  type: types.LAST_KNOWN_UPDATE,
  payload: {}
});

export default {
  submitLogin,
  submitLogout,
  getAuthenticationStatus,
  localLogout,
  describeMap,
  getLayerFeatures,
  getAllLayerFeatures,
  getAlertLayerFeature,
  getSpecificLayer,
  setLastKnownUpdate
};
