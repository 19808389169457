import React, { useEffect, useState, useRef } from 'react';
import TreeView from './TreeView';

function TreeFilter({
  ontology,
  includeList,
  excludeList,
  expandedList,
  bubbleUpFilterList,
  bubbleUpExcludeNode,
  bubbleUpExpandedNodes,
  compareLists
}) {
  const [data, setData] = useState([]);
  const [filterParameters, setFilterParameters] = useState(null);
  const [excludeParameter, setExcludeParameters] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState(null);

  const initialRender = useRef(true);

  useEffect(() => {
    setData(ontology);
  }, [ontology]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      bubbleUpFilterList(filterParameters);
    }
  }, [filterParameters]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      bubbleUpExcludeNode(excludeParameter);
    }
  }, [excludeParameter]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      bubbleUpExpandedNodes(expandedNodes);
    }
  }, [expandedNodes]);

  return (
    <TreeView
      data={data}
      includeList={includeList}
      excludeList={excludeList}
      expandedList={expandedList}
      onUpdateCb={updatedData => setData(updatedData)}
      onUpdateExclude={excludeNode => setExcludeParameters(excludeNode)}
      onUpdateFilterParameters={updatedFilters => {
        return setFilterParameters(updatedFilters);
      }}
      onUpdateExpanded={expandNode => setExpandedNodes(expandNode)}
      onCheckToggleCb={nodes => {
        const checkState = nodes[0].isChecked;

        applyCheckStateTo(nodes, checkState, 0);

        function applyCheckStateTo(nodes) {
          nodes.forEach(node => {
            node.isChecked = checkState;
          });
        }
      }}
      onDeleteCb={(node, updatedData, depth) => {
        alert(`${node.name} will be deleted`);
        return true;
      }}
    />
  );
}

export default TreeFilter;
