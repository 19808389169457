import React, { Component } from 'react';
import moment from 'moment';

class PhotoItem extends Component {
  render() {
    const { ...props } = this.props;

    return (
      <div className="card-body px-1 py-2 photoItem">
        <div className="row ">
          <div className="col">
            <h6 className="card-title mb-1 font-weight-bold">
              {props.image.Media.name}
            </h6>
            <p className="card-text mb-2 text-muted small">
              {moment(props.image.Media.when).format('DD MMM YYYY - HH:mm')}
            </p>
          </div>
        </div>
        <div className="photo-item-container">
          <img
            src={props.image.url}
            className="img-fluid rounded"
            alt=""
            onClick={() => props.openImage(props.image)}
          />
        </div>
      </div>
    );
  }
}

export default PhotoItem;
