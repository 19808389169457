import './Header.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Switch from 'react-switch';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSince: '00:00',
      language: 'en',
      timeInSeconds: 300
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleLogoutSubmission = this.handleLogoutSubmission.bind(this);
  }

  componentDidMount() {
    this.startTimer();
    let storageLanguage = localStorage.getItem('language');
    let storageTime = localStorage.getItem('updateInterval');
    if (storageTime) {
      this.setState({ timeInSeconds: storageTime });
      this.props.setUpdateInterval(storageTime);
    }
    if (storageLanguage) {
      this.setState({ language: storageLanguage });
      this.props.setSelectedLanguage(storageLanguage);
    } else {
      this.props.setSelectedLanguage('en');
    }
  }

  startTimer() {
    if (this.timer == 0) {
      this.timer = setInterval(this.countDown, 500);
    }
  }

  countDown = async () => {
    this.setState({
      timeSince: this.props.LKPUpdate
        ? moment
            .utc(moment().diff(moment(this.props.LKPUpdate)))
            .format('mm:ss')
        : '00:00'
    });
  };

  handleLogoutSubmission = e => {
    e.preventDefault();
    this.props.submitLogout();
  };

  onLanguageChange = e => {
    this.setState({ language: e.target.value });
    this.props.setSelectedLanguage(e.target.value);
    localStorage.setItem('language', e.target.value);
  };

  onIntervalChange = e => {
    this.setState({ timeInSeconds: e.target.value });
    this.props.setUpdateInterval(e.target.value);
    localStorage.setItem('updateInterval', e.target.value);
  };

  handleSwitch = value => {
    if (value) {
      this.props.allowAlertSounds();
    } else {
      this.props.disableAlertSounds();
    }
  };

  render() {
    const { ...props } = this.props;
    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-primary py-3">
        <a className="navbar-brand" href="/">
          <img
            src="/assets/img/logo/logo_white.png"
            width="auto"
            height="30"
            alt="Sensing Clues"
          />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {props.isAuthenticated && (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link active" href="/">
                  Map
                </a>
              </li>
            </ul>

            {/* <ul className="navbar-nav flex-row ml-md-auto d-md-flex">
              <li className="nav-item dropdown mr-3">
                <span className="nav-item nav-link">
                 BE: { props.pingInfo.backend ? props.pingInfo.backend.version : "n/a" }
                </span>
              </li>
              <li className="nav-item dropdown mr-3">
                <span className="nav-item nav-link">
                 MT: { props.pingInfo.version ? props.pingInfo.version : "n/a"  }
                </span>
              </li>
              <li className="nav-item dropdown mr-3">
                <span className="nav-item nav-link">
                 FE: { process.env.REACT_APP_VERSION }
                </span>
              </li>
            </ul> */}

            <div className="col-auto pr-2">
              {props.alertsTotal > 0 && (
                <span
                  type="button"
                  className="btn btn-danger float-right font-weight-bold pulse-button"
                  data-toggle="collapse"
                  href="#multiCollapseExample2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                  onClick={() => this.props.setAlertTimeline(true)}
                >
                  {props.alertsTotal} Alert{props.alertsTotal > 1 ? 's' : ''}
                </span>
              )}
              <span className="float-right text-light p-2 mr-1 font-weight-bold tabular-numbs">
                Last update: {this.state.timeSince} min ago
              </span>
            </div>
            <div className="dropdown">
              <a
                className="btn btn-light dropdown-toggle"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg
                  className="bi bi-people-circle"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 008 15a6.987 6.987 0 005.468-2.63z" />
                  <path
                    fillRule="evenodd"
                    d="M8 9a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M8 1a7 7 0 100 14A7 7 0 008 1zM0 8a8 8 0 1116 0A8 8 0 010 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuLink"
              >
                <h6 className="dropdown-header">Thesaurus language</h6>
                {props.languages.map((item, key) => (
                  <div key={key} className="form-check pl-5 pr-3 dropdown-item">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={item}
                      id={item}
                      value={item}
                      checked={this.state.language === item}
                      onChange={this.onLanguageChange}
                    />
                    <label className="form-check-label" htmlFor={item}>
                      {item}
                    </label>
                  </div>
                ))}
                {/* <div className="form-check pl-5 pr-3 dropdown-item">
                  <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    Dutch
                  </label>
                </div>
                <div className="form-check pl-5 pr-3  dropdown-item">
                  <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" />
                  <label className="form-check-label" htmlFor="gridRadios2">
                    Hindi
                  </label>
                </div>
                <div className="form-check pl-5 pr-3 dropdown-item">
                  <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios3" value="option3" />
                  <label className="form-check-label" htmlFor="gridRadios3">
                    English
                  </label>
                </div> */}
                <div role="separator" className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  href="https://sensingclues.freshdesk.com/support/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
                <div role="separator" className="dropdown-divider"></div>
                <h6 className="dropdown-header">Update time interval</h6>
                {[
                  { title: '1 Min', time: 60 },
                  { title: '5 Min', time: 300 },
                  { title: '10 Min', time: 600 }
                ].map((item, key) => (
                  <div key={key} className="form-check pl-5 pr-3 dropdown-item">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={item.title}
                      id={item.title}
                      value={item.time}
                      checked={Number(this.state.timeInSeconds) === item.time}
                      onChange={this.onIntervalChange}
                    />
                    <label className="form-check-label" htmlFor={item.title}>
                      {item.title}
                    </label>
                  </div>
                ))}
                <div role="separator" className="dropdown-divider"></div>
                <h6 className="dropdown-header">Audio</h6>
                <div className="row no-gutters pl-4">
                  <span className="mr-2">Allow</span>
                  <Switch
                    height={21}
                    width={42}
                    onClick={e => console.log(e)}
                    onChange={this.handleSwitch}
                    checked={this.props.alertSounds}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
                <div role="separator" className="dropdown-divider"></div>
                <small className="text-muted mx-4">
                  {props.currentUsername}
                </small>
                <div
                  className="dropdown-item"
                  style={{ cursor: 'pointer' }}
                  onClick={this.handleLogoutSubmission}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
    );
  }
}

Header.propTypes = {
  submitLogout: PropTypes.func
};

export default Header;
