import * as types from './actionTypes';
import * as timelineTypes from '../timeline/actionTypes';
import _ from 'lodash';
import moment from 'moment';

const reducer = (
  state = {
    layers: [],
    trackLayer: {
      type: 'FeatureCollection',
      features: []
    },
    alertFeatures: [],
    selectedLayers: ['Observations', 'Tracks', 'Agents']
  },
  action
) => {
  let clonedLayers = [];
  let clonedAlertFeatures = [];

  switch (action.type) {
    case types.SET_PROJECT_LAYERS_SUCCESS: {
      let mapLayers = [];
      Object.keys(action.payload).forEach(key => {
        if (key !== 'track' && key !== 'test') {
          action.payload[key].layers.forEach(layer => {
            layer.projectId = key;
            mapLayers.push(layer);
          });
        }
      });
      return {
        ...state,
        layers: mapLayers
      };
    }
    case types.SET_ALERT_FEATURES_SUCCESS: {
      clonedAlertFeatures = action.payload.data.features.map(feature => {
        if (feature.properties.EntityId === action.payload.selectedItem) {
          feature.properties.EntityType =
            feature.properties.EntityType + '_clicked';
        }

        return feature;
      });

      return {
        ...state,
        alertFeatures: clonedAlertFeatures
      };
    }
    case types.SET_LAYER_FEATURES_SUCCESS: {
      clonedLayers = state.layers.map(layer => {
        if (
          layer.projectId === action.payload.projectId &&
          layer.id === action.payload.layerId
        ) {
          let newData = action.payload.data;
          if (newData && newData.features) {
            newData.features.map(item => {
              if (item.properties.EntityId === action.payload.selectedItem) {
                item.properties.EntityType =
                  item.properties.EntityType + '_clicked';
              }

              if (layer.name === 'Agents') {
                if (
                  moment().diff(
                    moment(item.properties.LastSeenDateTime),
                    'hours'
                  ) < 1
                ) {
                  item.properties.age = 'lessThanHour';
                } else if (
                  moment().isSameOrBefore(
                    moment(item.properties.LastSeenDateTime),
                    'day'
                  )
                ) {
                  item.properties.age = 'lessThanDay';
                } else {
                  item.properties.age = 'moreThanDay';
                }
              }

              if (
                layer.name === 'Observations' &&
                action.payload.selectedTrackObservations !== null
              ) {
                if (
                  action.payload.selectedTrackObservations.includes(
                    item.properties.EntityId
                  )
                ) {
                  item.properties.EntityType =
                    item.properties.EntityType + '_selected';
                }
              }
              return item;
            });
            return { ...layer, ...newData };
          }
        }
        return layer;
      });

      return {
        ...state,
        layers: clonedLayers
      };
    }
    case timelineTypes.SET_SELECTED_ITEM:
      if (action.payload.observationId) {
        clonedAlertFeatures = state.alertFeatures.map(feature => {
          if (
            feature.properties.EntityId === action.payload.observationId &&
            !feature.properties.EntityType.includes('_clicked')
          ) {
            if (feature.properties.EntityType.includes('_selected')) {
              feature.properties.EntityType =
                feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 9
                ) + '_clicked';
            } else {
              feature.properties.EntityType =
                feature.properties.EntityType + '_clicked';
            }
          } else if (feature.properties.EntityType.includes('_clicked')) {
            feature.properties.EntityType = feature.properties.EntityType.substring(
              0,
              feature.properties.EntityType.length - 8
            );
          } else if (feature.properties.EntityType.includes('_selected')) {
            feature.properties.EntityType = feature.properties.EntityType.substring(
              0,
              feature.properties.EntityType.length - 9
            );
          }
          return feature;
        });
        clonedLayers = state.layers.map(layer => {
          if (layer.name === 'Observations' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (
                feature.properties.EntityId === action.payload.observationId &&
                !feature.properties.EntityType.includes('_clicked')
              ) {
                if (feature.properties.EntityType.includes('_selected')) {
                  feature.properties.EntityType =
                    feature.properties.EntityType.substring(
                      0,
                      feature.properties.EntityType.length - 9
                    ) + '_clicked';
                } else {
                  feature.properties.EntityType =
                    feature.properties.EntityType + '_clicked';
                }
              } else if (feature.properties.EntityType.includes('_clicked')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 8
                );
              } else if (feature.properties.EntityType.includes('_selected')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 9
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          if (layer.name === 'Tracks' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (feature.properties.EntityType.includes('_clicked')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 8
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          if (layer.name === 'Agents' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (feature.properties.age.includes('_clicked')) {
                feature.properties.age = feature.properties.age.substring(
                  0,
                  feature.properties.age.length - 8
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          return layer;
        });
      } else if (action.payload.featureId) {
        clonedLayers = state.layers.map(layer => {
          if (layer.name === 'Tracks' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (
                feature.properties.EntityId === action.payload.featureId &&
                !feature.properties.EntityType.includes('_clicked')
              ) {
                feature.properties.EntityType =
                  feature.properties.EntityType + '_clicked';
              } else if (feature.properties.EntityType.includes('_clicked')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 8
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          if (layer.name === 'Observations' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (feature.properties.EntityType.includes('_clicked')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 8
                );
              } else if (feature.properties.EntityType.includes('_selected')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 9
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          if (layer.name === 'Agents' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (feature.properties.age.includes('_clicked')) {
                feature.properties.age = feature.properties.age.substring(
                  0,
                  feature.properties.age.length - 8
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          return layer;
        });
      } else {
        clonedLayers = state.layers.map(layer => {
          if (layer.name === 'Agents' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (
                feature.properties.EntityId ===
                  action.payload.properties.EntityId &&
                !feature.properties.age.includes('_clicked')
              ) {
                feature.properties.age = feature.properties.age + '_clicked';
              } else if (feature.properties.age.includes('_clicked')) {
                feature.properties.age = feature.properties.age.substring(
                  0,
                  feature.properties.age.length - 8
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          if (layer.name === 'Observations' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (feature.properties.EntityType.includes('_clicked')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 8
                );
              } else if (feature.properties.EntityType.includes('_selected')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 9
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          if (layer.name === 'Tracks' && layer.features) {
            let clonedFeatures = layer.features.map(feature => {
              if (feature.properties.EntityType.includes('_clicked')) {
                feature.properties.EntityType = feature.properties.EntityType.substring(
                  0,
                  feature.properties.EntityType.length - 8
                );
              }
              return feature;
            });
            layer.features = clonedFeatures;
          }
          return layer;
        });
      }
      return {
        ...state,
        layers: clonedLayers,
        alertFeatures: clonedAlertFeatures
      };
    case timelineTypes.CLEAR_SELECTED_ITEM:
      clonedLayers = state.layers.map(layer => {
        if (layer.name === 'Observations' && layer.features) {
          clonedAlertFeatures = state.alertFeatures.map(feature => {
            if (
              feature.properties.EntityType &&
              feature.properties.EntityType.includes('_clicked')
            ) {
              feature.properties.EntityType = feature.properties.EntityType.substring(
                0,
                feature.properties.EntityType.length - 8
              );
            } else if (
              feature.properties.EntityType &&
              feature.properties.EntityType.includes('_selected')
            ) {
              feature.properties.EntityType = feature.properties.EntityType.substring(
                0,
                feature.properties.EntityType.length - 9
              );
            }
            return feature;
          });

          let clonedFeatures = layer.features.map(feature => {
            if (
              feature.properties.EntityType &&
              feature.properties.EntityType.includes('_clicked')
            ) {
              feature.properties.EntityType = feature.properties.EntityType.substring(
                0,
                feature.properties.EntityType.length - 8
              );
            } else if (
              feature.properties.EntityType &&
              feature.properties.EntityType.includes('_selected')
            ) {
              feature.properties.EntityType = feature.properties.EntityType.substring(
                0,
                feature.properties.EntityType.length - 9
              );
            }
            return feature;
          });
          layer.features = clonedFeatures;
        } else if (layer.name === 'Tracks') {
          let clonedFeatures = layer.features.map(feature => {
            if (
              feature.properties.EntityType &&
              feature.properties.EntityType.includes('_clicked')
            ) {
              feature.properties.EntityType = feature.properties.EntityType.substring(
                0,
                feature.properties.EntityType.length - 8
              );
            } else if (
              feature.properties.EntityType &&
              feature.properties.EntityType.includes('_selected')
            ) {
              feature.properties.EntityType = feature.properties.EntityType.substring(
                0,
                feature.properties.EntityType.length - 9
              );
            }
            return feature;
          });
          layer.features = clonedFeatures;
        } else if (layer.name === 'Agents' && layer.features) {
          let clonedFeatures = layer.features.map(feature => {
            if (
              feature.properties &&
              feature.properties.age.includes('_clicked')
            ) {
              feature.properties.age = feature.properties.age.substring(
                0,
                feature.properties.age.length - 8
              );
            }
            return feature;
          });
          layer.features = clonedFeatures;
        }
        return layer;
      });
      return {
        ...state,
        layers: clonedLayers,
        alertFeatures: clonedAlertFeatures,
        trackLayer: {
          type: 'FeatureCollection',
          features: []
        }
      };
    case types.SET_ENTIRE_TRACK:
      return {
        ...state,
        trackLayer: action.payload
      };
    case types.CLEAR_ENTIRE_TRACK:
      return {
        ...state,
        trackLayer: {
          type: 'FeatureCollection',
          features: []
        }
      };
    case types.SET_TRACK_OBSERVATIONS: {
      const arrayOfIds = action.payload.map(
        item => item.Observation.observationId
      );
      clonedLayers = state.layers.map(layer => {
        if (layer.name === 'Observations' && layer.features) {
          let clonedFeatures = layer.features.map(feature => {
            if (arrayOfIds.includes(feature.properties.EntityId)) {
              feature.properties.EntityType =
                feature.properties.EntityType + '_selected';
            }
            return feature;
          });
          layer.features = clonedFeatures;
        }
        return layer;
      });
      return {
        ...state,
        layers: clonedLayers
      };
    }
    case types.SET_SELECTED_LAYERS: {
      return {
        ...state,
        selectedLayers: action.payload
      };
    }
    default:
      return state;
  }
};

const data = state =>
  _.orderBy(state.layers, [layer => layer.name.toLowerCase()], ['asc']);
const trackLayer = state => state.trackLayer;
const alertFeatures = state => state.alertFeatures;
const layerDataSelectors = {
  data,
  trackLayer,
  alertFeatures
};

export default reducer;
export { layerDataSelectors };
