import './Filter.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty, isEqual, find, get, cloneDeep } from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import _ from 'lodash';

class TreeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      excludeData: [],
      lastCheckToggledNodeIndex: null
    };

    this.handleUpdate = this.handleUpdate.bind(this);

    this.printNodes = this.printNodes.bind(this);
    this.printChildren = this.printChildren.bind(this);

    this.printCheckbox = this.printCheckbox.bind(this);
    this.printDeleteButton = this.printDeleteButton.bind(this);
    this.printExpandButton = this.printExpandButton.bind(this);
    this.printNoChildrenMessage = this.printNoChildrenMessage.bind(this);

    this.handleCheckToggle = this.handleCheckToggle.bind(this);
    this.handleExclude = this.handleExclude.bind(this);
    this.handleExpandToggle = this.handleExpandToggle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.data, this.props.data)) {
      this.setState({ data: nextProps.data });
    }
  }

  handleUpdate(updatedData) {
    const { depth, onUpdateCb } = this.props;
    onUpdateCb(updatedData, depth);
  }

  handleCheckToggle(node, e) {
    const { onCheckToggleCb, onUpdateFilterParameters, depth } = this.props;
    const { lastCheckToggledNodeIndex } = this.state;
    const data = cloneDeep(this.state.data);
    const currentNode = find(data, node);
    const currentNodeIndex = data.indexOf(currentNode);
    const toggledNodes = [];
    if (e.shiftKey && !isNil(lastCheckToggledNodeIndex)) {
      node.isChecked = e.target.checked;
      toggledNodes.push(node);
    } else {
      currentNode.isChecked = e.target.checked;
      toggledNodes.push(currentNode);
    }

    onUpdateFilterParameters(toggledNodes[0]);
    onCheckToggleCb(toggledNodes, depth);
    this.setState({ lastCheckToggledNodeIndex: currentNodeIndex });
    this.handleUpdate(data);
  }

  handleExclude(node) {
    const { onUpdateExclude } = this.props;
    const data = cloneDeep(this.state.data);
    const currentNode = find(data, node);
    if (_.some(this.state.excludeData, ['id', node.id])) {
      this.setState({ excludeData: data.filter(item => !item.id === node.id) });
      currentNode.isExcluded = false;
    } else {
      this.setState({ excludeData: [...data, currentNode] });
      currentNode.isExcluded = true;
      currentNode.isChecked = false;
    }
    onUpdateExclude(currentNode);
    this.handleUpdate(data);
  }

  handleExpandToggle(node) {
    const { onUpdateExpanded } = this.props;
    const data = cloneDeep(this.state.data);
    const currentNode = find(data, node);

    currentNode.isExpanded = !currentNode.isExpanded;

    onUpdateExpanded(currentNode);
    this.handleUpdate(data);
  }

  printCheckbox(node) {
    const { isCheckable, keywordLabel, depth } = this.props;
    if (isCheckable(node, depth)) {
      return (
        <input
          type="checkbox"
          name={node[keywordLabel]}
          onChange={e => this.handleCheckToggle(node, e)}
          checked={this.props.includeList.some(value => value.id === node.id)}
          id={node.id}
        />
      );
    }
  }

  printDeleteButton(node) {
    const { isDeletable, depth, deleteElement } = this.props;

    if (isDeletable(node, depth)) {
      return (
        <div
          className={
            this.props.excludeList.some(value => value.id === node.id)
              ? 'delete-btn-selected'
              : 'delete-btn'
          }
          onClick={() => {
            this.handleExclude(node);
          }}
        >
          {deleteElement}
        </div>
      );
    }
  }

  printExpandButton(node) {
    const className = this.props.expandedList
      ? this.props.expandedList.some(value => value.id === node.id)
        ? 'super-treeview-triangle-btn-down'
        : 'super-treeview-triangle-btn-right'
      : 'super-treeview-triangle-btn-right';
    if (node.children) {
      return (
        <div
          className={`super-treeview-triangle-btn ${className}`}
          onClick={() => {
            this.handleExpandToggle(node);
          }}
        />
      );
    } else {
      return (
        <div
          className={`super-treeview-triangle-btn super-treeview-triangle-btn-none`}
        />
      );
    }
  }

  printNoChildrenMessage() {
    const { transitionExitTimeout, noChildrenAvailableMessage } = this.props;
    const noChildrenTransitionProps = {
      classNames: 'super-treeview-no-children-transition',
      key: 'super-treeview-no-children',
      style: {
        transitionDuration: `${transitionExitTimeout}ms`,
        transitionDelay: `${transitionExitTimeout}ms`
      },
      timeout: {
        enter: transitionExitTimeout
      },
      exit: false
    };

    return (
      <CSSTransition {...noChildrenTransitionProps}>
        <div className="super-treeview-no-children">
          <div className="super-treeview-no-children-content">
            {noChildrenAvailableMessage}
          </div>
        </div>
      </CSSTransition>
    );
  }

  printNodes(nodeArray) {
    const {
      keywordKey,
      depth,
      transitionEnterTimeout,
      transitionExitTimeout,
      getStyleClassCb
    } = this.props;
    const {
      printExpandButton,
      printCheckbox,
      printDeleteButton,
      printChildren
    } = this;

    const nodeTransitionProps = {
      classNames: 'super-treeview-node-transition',
      style: {
        transitionDuration: `${transitionEnterTimeout}ms`
      },
      timeout: {
        enter: transitionEnterTimeout,
        exit: transitionExitTimeout
      }
    };

    return (
      <TransitionGroup>
        {isEmpty(nodeArray)
          ? this.printNoChildrenMessage()
          : _.orderBy(
              nodeArray,
              [node => node.label.toLowerCase()],
              ['asc']
            ).map((node, index) => {
              //   const nodeText = get(node, keywordLabel, '');

              return (
                <CSSTransition
                  {...nodeTransitionProps}
                  key={node[keywordKey] || index}
                >
                  <div
                    className={'super-treeview-node' + getStyleClassCb(node)}
                  >
                    <div className="super-treeview-node-content">
                      {printExpandButton(node, depth)}
                      {printCheckbox(node, depth)}
                      <label
                        htmlFor={node.id}
                        title={node.label + ' (' + node.count + ')'}
                        className="super-treeview-text"
                      >
                        {node.label} {node.count && <span>({node.count})</span>}
                      </label>
                      {printDeleteButton(node, depth)}
                    </div>
                    {printChildren(node)}
                  </div>
                </CSSTransition>
              );
            })}
      </TransitionGroup>
    );
  }

  printChildren(node) {
    if (!this.props.expandedList.some(value => value.id === node.id)) {
      return null;
    }

    const { keywordChildren, keywordChildrenLoading, depth } = this.props;
    const isChildrenLoading = get(node, keywordChildrenLoading, false);
    let childrenElement;

    if (isChildrenLoading) {
      childrenElement = get(this.props, 'loadingElement');
    } else {
      childrenElement = (
        <TreeView
          {...this.props}
          data={node[keywordChildren] || []}
          depth={depth + 1}
          onUpdateCb={onChildrenUpdateCb.bind(this)}
        />
      );
    }

    return (
      <div className="super-treeview-children-container">{childrenElement}</div>
    );

    function onChildrenUpdateCb(updatedData) {
      const data = cloneDeep(this.state.data);
      const currentNode = find(data, node);

      currentNode[keywordChildren] = updatedData;
      this.handleUpdate(data);
    }
  }

  render() {
    return (
      <div className="super-treeview">{this.printNodes(this.state.data)}</div>
    );
  }
}

TreeView.propTypes = {
  data: PropTypes.array.isRequired,
  depth: PropTypes.number,

  deleteElement: PropTypes.element,

  getStyleClassCb: PropTypes.func,

  isCheckable: PropTypes.func,
  isDeletable: PropTypes.func,
  isExpandable: PropTypes.func,

  keywordChildren: PropTypes.string,
  keywordChildrenLoading: PropTypes.string,
  keywordKey: PropTypes.string,
  keywordLabel: PropTypes.string,

  loadingElement: PropTypes.element,
  noChildrenAvailableMessage: PropTypes.string,

  onCheckToggleCb: PropTypes.func,
  onDeleteCb: PropTypes.func,
  onExpandToggleCb: PropTypes.func,
  onUpdateCb: PropTypes.func,

  transitionEnterTimeout: PropTypes.number,
  transitionExitTimeout: PropTypes.number
};

TreeView.defaultProps = {
  depth: 0,

  deleteElement: (
    <div>
      <svg
        width="1.4em"
        height="1.4em"
        viewBox="2 3 15 15"
        className="bi bi-x"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </div>
  ),

  getStyleClassCb: (/* node, depth */) => {
    return '';
  },
  isCheckable: (/* node, depth */) => {
    return true;
  },
  isDeletable: (/* node, depth */) => {
    return true;
  },
  isExpandable: (/* node, depth */) => {
    return true;
  },

  keywordChildren: 'children',
  keywordChildrenLoading: 'isChildrenLoading',
  keywordLabel: 'name',
  keywordKey: 'id',

  loadingElement: <div>loading...</div>,

  noChildrenAvailableMessage: 'No data found',

  onCheckToggleCb: (/* Array of nodes, depth */) => {},
  onDeleteCb: (/* node, updatedData, depth */) => {
    return true;
  },
  onExpandToggleCb: (/* node, depth */) => {},
  onUpdateCb: (/* updatedData, depth */) => {},

  transitionEnterTimeout: 1200,
  transitionExitTimeout: 1200
};

export default TreeView;
