import { pingSelectors } from './ping/reducers';
import { userSelectors } from './user/reducers';
import { timelineSelectors } from './timeline/reducers';
import { geoFeatureSelectors } from './geoFeature/reducers';
import { ontologySelectors } from './ontology/reducers';
import { layerDataSelectors } from './layer/reducers';
import { modalSelectors } from './modals/reducers';
import { querySelectors } from './query/reducers';

const data = {
  ping: pingSelectors,
  user: userSelectors,
  timeline: timelineSelectors,
  geoFeature: geoFeatureSelectors,
  ontology: ontologySelectors,
  layer: layerDataSelectors,
  modals: modalSelectors,
  query: querySelectors
};

export default data;
