import * as types from './actionTypes';
import * as layerTypes from '../layer/actionTypes';

const reducer = (
  state = {
    fetchingList: false,
    selectedItem: {},
    selectedTrackObservations: [],
    results: [],
    total: 0,
    dataSources: [],
    agents: [],
    scrollToTop: false,
    mounted: false,
    alerts: { results: [], start: 0 },
    allowAlertSounds: false, // initialize alerts here
    audio: new Audio('FocusAlert.wav')
  },
  action
) => {
  switch (action.type) {
    case types.SET_AGGREGATES:
      return {
        ...state,
        aggregates: action.payload
      };
    case types.START_TIMELINE_FETCH:
      return {
        ...state,
        fetchingList: true
      };
    case types.SET_TIMELINE_SUCCESS:
      if (action.payload.start === 1) {
        let newState = { ...state, ...action.payload };
        newState.fetchingList = false;
        return newState;
      } else {
        return {
          ...state,
          results: state.results.concat(action.payload.results),
          start: action.payload.start,
          fetchingList: false
        };
      }
    case types.SET_ALERTS:
      if (
        state.mounted &&
        action.payload.results.length > state.alerts.results.length &&
        state.allowAlertSounds
      ) {
        state.audio.play();

        let playCount = 1;

        const intervalId = setInterval(() => {
          state.audio.play();
          playCount++;
          if (playCount >= 3) {
            clearInterval(intervalId);
          }
        }, 5000);
      }
      if (action.payload.start === 1) {
        let newState = { ...state, alerts: action.payload, mounted: true };
        return newState;
      } else {
        return {
          ...state,
          alerts: {
            results: state.alerts.results.concat(action.payload.results),
            start: action.payload.start
          },
          fetchingList: false,
          mounted: true
        };
      }
    case types.SET_OBSERVATION_MEDIA:
      return {
        ...state,
        results: state.results.map(item => {
          if (item.id === action.payload.observationId) {
            // Find observation object
            item.extracted.content.map(content => {
              if (content.Observation) {
                content.Observation.media = action.payload.values;
              }
              return content;
            });
          }
          return item;
        })
      };
    case types.SET_DATA_SOURCES: {
      const dataSources = action.payload.facets
        ? action.payload.facets.dataSources
          ? action.payload.facets.dataSources.facetValues
            ? action.payload.facets.dataSources.facetValues
            : []
          : []
        : [];
      return {
        ...state,
        dataSources: dataSources
      };
    }
    case types.SET_AGENTS: {
      const agents = action.payload.facets
        ? action.payload.facets.agents
          ? action.payload.facets.agents.facetValues
            ? action.payload.facets.agents.facetValues
            : []
          : []
        : [];
      return {
        ...state,
        agents: agents
      };
    }
    case types.SET_SELECTED_ITEM: {
      let newSelectedItem = {};
      if (
        (state.selectedItem.observationId &&
          action.payload.observationId &&
          state.selectedItem.observationId == action.payload.observationId) ||
        (state.selectedItem.featureId &&
          action.payload.featureId &&
          state.selectedItem.featureId == action.payload.featureId)
      ) {
        newSelectedItem = { ...state.selectedItem, ...action.payload };
      } else {
        newSelectedItem = action.payload;
      }
      return {
        ...state,
        selectedItem: newSelectedItem,
        selectedTrackObservations: []
      };
    }
    case types.SET_SELECTED_OBSERVATION_MEDIA:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          media: action.payload
        }
      };
    case types.CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: {},
        selectedTrackObservations: []
      };
    case layerTypes.SET_TRACK_INFO:
      return {
        ...state,
        results: state.results.map(item => {
          if (
            action.payload.instance &&
            action.payload.instance.GeoFeature &&
            item.id === action.payload.instance.GeoFeature.featureId
          ) {
            // Find observation object
            item.extracted.content.map(content => {
              if (content.GeoFeature) {
                content.GeoFeature = action.payload.instance.GeoFeature;
                content.GeoFeature.projectName =
                  action.payload.headers.projectName;
              }
              return content;
            });
          }
          return item;
        }),
        selectedItem: {
          ...state.selectedItem,
          projectName: action.payload.headers.projectName
        }
      };
    case layerTypes.SET_TRACK_OBSERVATIONS:
      return {
        ...state,
        selectedTrackObservations: action.payload
      };
    case layerTypes.SET_TRACK_OBSERVATIONS_MEDIA:
      return {
        ...state,
        selectedTrackObservations: state.selectedTrackObservations.map(item => {
          if (item.Observation.observationId === action.payload.observationId) {
            // Find observation object
            item.Observation.media = action.payload.values;
          }
          return item;
        })
      };
    case types.SET_SCROLL_TO_TOP:
      return {
        ...state,
        scrollToTop: action.payload
      };
    case types.ALLOW_ALERT_SOUNDS:
      if (state.mounted && state.alerts.results.length > 0) {
        state.audio.play();

        let playCount = 1;

        const intervalId = setInterval(() => {
          state.audio.play();
          playCount++;
          if (playCount >= 3) {
            clearInterval(intervalId);
          }
        }, 5000);
      }
      return {
        ...state,
        allowAlertSounds: true
      };
    case types.DISABLE_ALERT_SOUNDS:
      return {
        ...state,
        allowAlertSounds: false
      };
    default:
      return state;
  }
};

const aggregates = state => state.aggregates || {};
const timelineResults = state => state.results || [];
const timelineTotal = state => state.total || 0;
const timelineFetching = state => state.fetchingList;
const timelineSelectedItem = state => state.selectedItem || {};
const timelineSelectedTrackObservations = state =>
  state.selectedTrackObservations || [];
const dataSources = state => state.dataSources || [];
const agents = state => state.agents || [];

const alerts = state => state.alerts || {};
const alertsTotal = state => state.alerts.total || 0;
const alertSounds = state => state.allowAlertSounds || false;

const timelineSelectors = {
  aggregates,
  timelineResults,
  timelineTotal,
  timelineFetching,
  timelineSelectedItem,
  timelineSelectedTrackObservations,
  dataSources,
  agents,
  alerts,
  alertsTotal,
  alertSounds
};

export default reducer;
export { timelineSelectors };
