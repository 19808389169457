import util from './util';

const provider = (function() {
  const provide = function(config = {}) {
    // needed for offline testing with Jest
    const headers = config.cookie ? { Cookie: config.cookie } : {};

    const status = function() {
      return fetch(util.buildUrl('/api/auth/status', null, config.baseURI), {
        method: 'GET',
        headers: {
          ...headers,
          Accept: 'application/json'
        },
        credentials: 'same-origin'
      }).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const login = function(user, pass, returnCookie = false) {
      // returnCookie is needed for offline testing with Jest
      return fetch(util.buildUrl('/api/auth/login', null, config.baseURI), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          username: '' + user,
          password: '' + pass
        }),
        credentials: 'same-origin'
      }).then(
        response => {
          if (response.status === 200) {
            if (returnCookie) {
              // Note: not intended for browser context!
              return response.json().then(json => {
                let setCookie;
                if (response.headers.get) {
                  setCookie = response.headers.get('set-cookie');
                } else {
                  setCookie = response.headers['set-cookie'];
                }
                const cookie = setCookie.substr(0, setCookie.indexOf(';'));
                return { ...json, cookie };
              });
            } else {
              return response.json();
            }
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error: error };
        }
      );
    };

    const logout = function() {
      return fetch(util.buildUrl('/api/auth/logout', null, config.baseURI), {
        method: 'POST',
        headers: headers,
        credentials: 'same-origin'
      }).then(
        response => {
          if (response.status === 204) {
            return { ok: true };
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error: error };
        }
      );
    };

    const profile = function(profile) {
      if (profile) {
        // update
        return fetch(util.buildUrl('/api/auth/profile', null, config.baseURI), {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(profile),
          credentials: 'same-origin'
        }).then(
          response => {
            if (response.status === 201 || response.status === 204) {
              return {};
            } else {
              return response.json().then(error => ({ isError: true, error }));
            }
          },
          error => {
            return { isError: true, error };
          }
        );
      } else {
        // get
        return fetch(util.buildUrl('/api/auth/profile', null, config.baseURI), {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }).then(
          response => {
            if (response.status === 200) {
              return response.json();
            } else if (response.status === 204 || response.status === 404) {
              return {};
            } else {
              return response.json().then(error => ({ isError: true, error }));
            }
          },
          error => {
            return { isError: true, error };
          }
        );
      }
    };

    return {
      name: 'AuthClient',
      status,
      login,
      logout,
      profile
    };
  };

  return provide;
})();

export default provider;
