const factory = (function() {
  class SearchQuery {
    constructor() {
      this.filters = {};
      this.options = {};
    }
    displayName() {
      return 'SearchQuery';
    }
    withQueryText(queryText) {
      this.filters.queryText = queryText || '';
      return this;
    }
    withDateTimeRange(to, from) {
      to = new Date(to);
      from = new Date(from);
      this.filters.dateTimeRange = {
        to: to.toISOString(),
        from: from.toISOString()
      };
      return this;
    }
    withConcepts(concepts, mode) {
      concepts = concepts || [];
      if (!Array.isArray(concepts)) {
        concepts = [concepts];
      }
      if (mode) {
        this.filters.concepts = {
          type: 'concepts',
          value: concepts,
          mode
        };
      } else {
        this.filters.concepts = concepts;
      }
      return this;
    }
    withDataSources(dataSources, mode) {
      dataSources = dataSources || [];
      if (!Array.isArray(dataSources)) {
        dataSources = [dataSources];
      }
      if (mode) {
        this.filters.dataSources = {
          type: 'dataSources',
          value: dataSources,
          mode
        };
      } else {
        this.filters.dataSources = dataSources;
      }
      return this;
    }
    withAgents(agents, mode) {
      agents = agents || [];
      if (!Array.isArray(agents)) {
        agents = [agents];
      }
      if (mode) {
        this.filters.agents = {
          type: 'agents',
          value: agents,
          mode
        };
      } else {
        this.filters.agents = agents;
      }
      return this;
    }
    withEntities(entities, mode) {
      entities = entities || [];
      if (!Array.isArray(entities)) {
        entities = [entities];
      }
      if (mode) {
        this.filters.entities = {
          type: 'entities',
          value: entities,
          mode
        };
      } else {
        this.filters.entities = entities;
      }
      return this;
    }
    withGeoQuery(geoQuery) {
      if (geoQuery.displayName() !== 'GeoQuery') {
        throw new Error('Not a GeoQuery argument');
      }
      this.filters.geoQuery = geoQuery;
      return this;
    }
    withOptions(options) {
      if (options.displayName() !== 'SearchOptions') {
        throw new Error('Not a SearchOptions argument');
      }
      this.options = options;
      return this;
    }
    toString() {
      return JSON.stringify(this, null, 2);
    }
  }

  class Box {
    constructor(south = 0, west = 0, north = 0, east = 0) {
      this.south = south;
      this.west = west;
      this.north = north;
      this.east = east;
      this.validate();
    }
    displayName() {
      return 'Box';
    }
    withBounds(south, west, north, east) {
      this.south = south;
      this.west = west;
      this.north = north;
      this.east = east;
      this.validate();
      return this;
    }
    validate() {
      if (this.south > 90 || this.south <= -90) {
        throw new Error('South out of range: ' + this.south);
      }
      if (this.west > 180 || this.west <= -180) {
        throw new Error('West out of range: ' + this.west);
      }
      if (this.north > 90 || this.north <= -90) {
        throw new Error('North out of range: ' + this.north);
      }
      if (this.east > 180 || this.east <= -180) {
        throw new Error('East out of range: ' + this.east);
      }
      if (this.south > this.north) {
        throw new Error('South cannot be greater than North');
      }
      if (this.west > this.east) {
        throw new Error('West cannot be greater than East');
      }
    }
    toJSON() {
      return this;
    }
    toString() {
      return JSON.stringify(this, null, 2);
    }
  }

  class Point {
    constructor(latitude = 0, longitude = 0) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.validate();
    }
    displayName() {
      return 'Point';
    }
    withLatitude(latitude) {
      this.latitude = latitude;
      this.validate();
      return this;
    }
    withLongitude(longitude) {
      this.longitude = longitude;
      this.validate();
      return this;
    }
    toJSON() {
      return this;
    }
    toString() {
      return JSON.stringify(this, null, 2);
    }
    validate() {
      if (this.latitude > 90 || this.latitude <= -90) {
        throw new Error('Latitude out of range: ' + this.latitude);
      }
      if (this.longitude > 180 || this.longitude <= -180) {
        throw new Error('Longitude out of range: ' + this.longitude);
      }
    }
    equals(point) {
      return (
        this.latitude === point.latitude && this.longitude === point.longitude
      );
    }
  }

  class Circle {
    constructor() {
      this.radius = 0;
      this.point = new Point();
    }
    displayName() {
      return 'Circle';
    }
    withRadius(radius) {
      radius = +radius;
      if (radius < 0) {
        throw new Error('Radius can not be negative');
      }
      this.radius = radius;
      return this;
    }
    withCenter(center) {
      this.point = center;
    }
    toJSON() {
      return this;
    }
    toString() {
      return JSON.stringify(this, null, 2);
    }
  }

  class Polygon {
    constructor(points = []) {
      points = points || [];
      if (!Array.isArray(points)) {
        points = [points];
      }
      this.point = points;
      if (points.length) {
        this.validate();
      }
    }
    displayName() {
      return 'Polygon';
    }
    withPoints(points) {
      points = points || [];
      if (!Array.isArray(points)) {
        points = [points];
      }
      this.point = points;
      this.validate();
      return this;
    }
    validate() {
      if (this.point.length < 2) {
        throw new Error('Need at least 3 points for a Polygon');
      }
      this.point.forEach(point => {
        if (point.displayName() !== 'Point') {
          throw new Error('Not a Point in Points');
        }
      });
      if (!this.point[0].equals(this.point[this.point.length - 1])) {
        throw new Error('First and last point need to be the same in Polygon');
      }
    }
    toJSON() {
      return this;
    }
    toString() {
      return JSON.stringify(this, null, 2);
    }
  }

  class GeoQuery {
    constructor() {
      this.operator = 'intersects';
      this.mapBounds = null;
      this.drawings = [];
    }
    displayName() {
      return 'GeoQuery';
    }
    withOperator(operator) {
      if (
        [
          'contains',
          'covered-by',
          'covers',
          'disjoint',
          'intersects',
          'overlaps',
          'within'
        ].indexOf(operator) < 0
      ) {
        throw new Error('Invalid operator: ' + operator);
      }
      this.operator = operator;
      return this;
    }
    withMapBounds(box) {
      if (box.displayName() !== 'Box') {
        throw new Error('Not a Box argument');
      }
      this.mapBounds = box;
      return this;
    }
    withDrawings(drawings) {
      drawings = drawings || [];
      if (!Array.isArray(drawings)) {
        drawings = [drawings];
      }
      drawings.forEach(drawing => {
        if (
          ['Point', 'Circle', 'Box', 'Polygon'].indexOf(drawing.displayName()) <
          0
        ) {
          throw new Error('Not a Point, Circle, Box or Polygon in Drawings');
        }
      });
      this.drawings = drawings;
      return this;
    }
    toJSON() {
      return this;
    }
    toString() {
      return JSON.stringify(this, null, 2);
    }
  }

  class SearchOptions {
    constructor(start = 1, pageLength = 10) {
      this.start = start;
      this.pageLength = pageLength;
    }
    displayName() {
      return 'SearchOptions';
    }
    withStart(start) {
      this.start = start;
      return this;
    }
    withPageLength(pageLength) {
      this.pageLength = pageLength;
      return this;
    }
    toJSON() {
      return this;
    }
    toString() {
      return JSON.stringify(this, null, 2);
    }
  }

  return {
    name: 'QueryBuilder',
    SearchQuery,
    Box,
    Point,
    Circle,
    Polygon,
    GeoQuery,
    SearchOptions
  };
})();

export default factory;
