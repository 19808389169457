import util from './util.js';

let ABORT_CONTROLLERS = new Map();

const provider = (function() {
  const provide = function(config = {}) {
    const headers = config.cookie ? { Cookie: config.cookie } : {};

    const configFunc = function() {
      return fetch(
        util.buildUrl(
          '/api/search/' + config.searchType + '/config',
          null,
          config.baseURI
        ),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const facets = function(searchQuery, signalKey) {
      return fetch(
        util.buildUrl(
          '/api/search/' + config.searchType + '/facets',
          null,
          config.baseURI
        ),
        {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(searchQuery),
          credentials: 'same-origin',
          signal: signalKey
            ? util.abortAndGetSignal(signalKey, ABORT_CONTROLLERS)
            : null
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const results = function(searchQuery, language, signalKey) {
      return fetch(
        util.buildUrl(
          '/api/search/' + config.searchType + '/results',
          { language },
          config.baseURI
        ),
        {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(searchQuery),
          credentials: 'same-origin',
          signal: signalKey
            ? util.abortAndGetSignal(signalKey, ABORT_CONTROLLERS)
            : null
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const aggregates = function(searchQuery) {
      return fetch(
        util.buildUrl(
          '/api/search/' + config.searchType + '/aggregates',
          null,
          config.baseURI
        ),
        {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(searchQuery),
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const exportFunc = function(searchQuery) {
      return fetch(
        util.buildUrl(
          '/api/search/' + config.searchType + '/export',
          null,
          config.baseURI
        ),
        {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            Accept: 'text/plain'
          },
          body: JSON.stringify(searchQuery),
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.text();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    return {
      name: 'SearchClient',
      config: configFunc,
      facets,
      results,
      aggregates,
      export: exportFunc
    };
  };

  return provide;
})();

export default provider;
