import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import ObservationItem from './ObservationItem';
import TrackItem from './TrackItem';
import AgentItem from './AgentItem';
import actions from '../../../store/appActions';

import './Observation.scss';

class ObservationView extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    const { ...props } = this.props;
    return (
      <div className="h-100">
        <div className="card border-bottom-0 rounded-0">
          <div className="card-header pb-2 pr-3 mr-0">
            <h6 className="float-left pt-1 pb-0">
              Selected item(s){' '}
              {1 + props.timelineSelectedTrackObservations.length}
            </h6>
            <button
              type="button"
              className="btn btn-outline-primary btn-sm float-right "
              aria-expanded="false"
              onClick={props.clearSelectedItem}
            >
              <span>Deselect</span>
            </button>
          </div>
        </div>
        <div ref={this.ref} className="list-container">
          <div style={{ overflowY: 'scroll' }}>
            {props.item.observationId && (
              <ObservationItem
                item={props.item}
                selected={false}
                setSelectedItem={() => {}}
                openImage={props.openImageModal}
                openObservation={props.openObservationModal}
              />
            )}
            {props.item.featureId && (
              <TrackItem
                item={props.item}
                selected={false}
                setSelectedItem={() => {}}
                openTrack={props.openTrackModal}
              />
            )}
            {props.item.source === 'Agents' && (
              <AgentItem
                item={props.item}
                selected={false}
                setSelectedItem={() => {}}
              />
            )}
            {props.timelineSelectedTrackObservations.length > 0 &&
              props.timelineSelectedTrackObservations.map(
                (observation, key) => {
                  return (
                    <ObservationItem
                      key={key}
                      item={observation.Observation}
                      selected={false}
                      setSelectedItem={() => {}}
                      openImage={props.openImageModal}
                      openObservation={props.openObservationModal}
                    />
                  );
                }
              )}
          </div>
        </div>
      </div>
    );
  }
}

const getTimelineSelectedTrackObservations = state =>
  state.timeline.selectedTrackObservations || [];

const mapStateToProps = createSelector(
  getTimelineSelectedTrackObservations,
  timelineSelectedTrackObservations => ({
    timelineSelectedTrackObservations
  })
);

const mapDispatchToProps = {
  setSelectedItem: actions.timeline.setSelectedItem,
  getEntireTrack: actions.layer.getEntireTrack,
  getTrackInfo: actions.layer.getTrackInfo,
  getTrackObservations: actions.layer.getTrackObservations,
  openImageModal: actions.modals.openImageModal,
  openObservationModal: actions.modals.openObservationModal,
  openTrackModal: actions.modals.openTrackModal,
  clearSelectedItem: actions.timeline.clearSelectedItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ObservationView);
