import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showErrorMessage: false
    };

    this.handleLoginSubmission = this.handleLoginSubmission.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.loginFailure.message !== this.props.loginFailure.message ||
        prevProps.loginFailure.attempts !== this.props.loginFailure.attempts) &&
      this.props.loginFailure.message !== ''
    ) {
      this.setState({ showErrorMessage: true });
    }
    if (
      prevProps.loginFailure.message !== this.props.loginFailure.message &&
      this.props.loginFailure.message === ''
    ) {
      this.setState({ showErrorMessage: false });
    }
  }

  handleLoginSubmission = e => {
    e.preventDefault();
    this.setState({ showErrorMessage: false });
    this.props.submitLogin(
      this.state.username.trim().replace(/ /g, ''),
      this.state.password
    );
  };

  setUsername = e => {
    this.setState({ username: e.target.value });
  };

  setPassword = e => {
    this.setState({ password: e.target.value });
  };

  closeErrorMessage = e => {
    e.preventDefault();
    this.setState({ showErrorMessage: false });
  };

  render() {
    const props = this.props;
    return (
      <div className="text-center">
        <form className="form-signin" onSubmit={this.handleLoginSubmission}>
          <img
            className="mb-4"
            src="/assets/img/logo/logo_black.png"
            alt=""
            width="auto"
            height="72"
          />
          <h1 className="h3 mb-3 font-weight-normal">Sign in to Focus</h1>
          {this.state.showErrorMessage && (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>Login failed:</strong> {props.loginFailure.message}.
              {/* <span>[{3 - props.loginFailure.attempts} attempt(s) left]</span>. */}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={this.closeErrorMessage}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <label htmlFor="userName" className="sr-only">
            Username
          </label>
          <input
            type="text"
            id="userName"
            className="form-control"
            placeholder="Username"
            required
            autoFocus={true}
            onChange={this.setUsername}
          />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="Password"
            required
            onChange={this.setPassword}
          />

          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Sign in
          </button>
          <p className="mt-2">
            <a
              className="text-muted"
              href="https://sensingclues.freshdesk.com/support/home/"
            >
              Forgot Password?
            </a>
          </p>
          <p className="mt-4 mb-3 text-muted">
            &copy; {moment().format('YYYY')} |{' '}
            <a href="https://www.sensingclues.nl/">Sensing Clues website</a>
          </p>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  submitLogin: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  loginFailure: PropTypes.object
};

export default Login;
