import * as types from './actionTypes';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.PING_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const pingInfo = state => state.user || {};

const pingSelectors = {
  pingInfo
};

export default reducer;
export { pingSelectors };
