import * as types from './actionTypes';

const reducer = (state = { geoFeatures: [] }, action) => {
  switch (action.type) {
    case types.SET_GEO_FEATURES_SUCCESS:
      return {
        ...state,
        geoFeatures: action.payload.geoFeatures
      };
    default:
      return state;
  }
};

const geoFeatures = state => state.geoFeatures;

const geoFeatureSelectors = {
  geoFeatures
};

export default reducer;
export { geoFeatureSelectors };
