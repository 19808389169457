import React, { Component } from 'react';
import moment from 'moment';
import Switch from 'react-switch';
import Modal from 'react-bootstrap/Modal';
import { isEmpty } from 'lodash';

import { getDegMinSec, getUTM, getWGS } from '../../utils/locationUtil';
import DraggableModalDialog from './DraggableModalDialog';

import '../views/observation/Observation.scss';

const capitalize = s => (s ? s[0].toUpperCase() + s.slice(1) : '');

class TrackModal extends Component {
  state = {
    genericDetailsOpen: false,
    attachmentsOpen: false,
    specificDetailsOpen: true,
    historyOpen: false,
    technicalDetailsOpen: false,
    UTM: false,
    copying: {},
    doneCopying: {}
  };

  handleSwitch = value => {
    this.setState({ UTM: value });
  };

  copyTextToClipboard = async text => {
    await this.setState({
      copying: {
        ...this.state.copying,
        [text]: true
      }
    });
    navigator.clipboard.writeText(text);

    await new Promise(resolve => setTimeout(resolve, 300));

    await this.setState({
      copying: {
        ...this.state.copying,
        [text]: false
      },
      doneCopying: {
        ...this.state.doneCopying,
        [text]: true
      }
    });

    await new Promise(resolve => setTimeout(resolve, 500));

    await this.setState({
      doneCopying: {
        ...this.state.doneCopying,
        [text]: false
      }
    });
  };

  render() {
    const { ...props } = this.props;

    return (
      <Modal
        dialogAs={DraggableModalDialog}
        show={props.open}
        onHide={props.close}
        centered={true}
        size="md"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            Track
          </h5>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="row no-gutters">
            <div className="col-auto  pl-2 pr-3 pt-3 pb-3 ml-1 photoItem">
              {props.data.media && (
                <img
                  src={props.data.media[0].url}
                  className="img-fluid rounded"
                  alt=""
                  style={{ width: '7.5rem', height: '7.5rem' }}
                  onClick={e => {
                    e.stopPropagation();
                    props.openImage(props.data.media[0]);
                  }}
                />
              )}
              {!props.data.media && (
                <img
                  src="./assets/img/photos/no-image-placeholder.jpg"
                  className="img-fluid rounded"
                  alt=""
                  style={{ width: '7.5rem', height: '7.5rem' }}
                />
              )}
            </div>
            <div className="col">
              <div className="card-block pl-0 pr-3 py-2 pt-3">
                <h6 className="card-title mb-1 mt-1 font-weight-bold pointer">
                  {props.data.featureType === 'track/onPatrol' && (
                    <span className="">On Patrol</span>
                  )}
                  {props.data.featureType === 'track/onDuty' && (
                    <span className="">On Duty</span>
                  )}
                  {props.data.featureType === 'track/onFootPatrol' && (
                    <span>Foot Patrol</span>
                  )}
                  {props.data.featureType === 'track/onVehiclePatrol' && (
                    <span>Vehicle Patrol</span>
                  )}
                  {props.data.featureType === 'track/onAirPatrol' && (
                    <span>Air Patrol</span>
                  )}
                  {props.data.featureType === 'track/onHumanSpoor' && (
                    <span>On Human Spoor</span>
                  )}
                  {props.data.featureType === 'track/onAnimalSpoor' && (
                    <span>On Animal Spoor</span>
                  )}
                  {props.data.featureType === 'track/onSafari' && (
                    <span>On Safari</span>
                  )}
                  {props.data.featureType === 'track/onCensus' && (
                    <span>Census</span>
                  )}
                  {props.data.featureType === 'track/onFenceInspection' && (
                    <span>Fence Inspection</span>
                  )}
                  {props.data.featureType === 'track/onK9Patrol' && (
                    <span>On K9 Patrol</span>
                  )}
                  {props.data.featureType === 'track/onPerimeterTrack' && (
                    <span>On Perimeter Track</span>
                  )}
                  {props.data.featureType === 'track/onSearch' && (
                    <span>On Search</span>
                  )}
                  {props.data.featureType === 'track/onSnareSweep' && (
                    <span>On Snare Sweep</span>
                  )}
                  {props.data.featureType === 'track/onTraining' && (
                    <span>Training</span>
                  )}
                  {props.data.featureType === 'track/onBikePatrol' && (
                    <span>Bike patrol</span>
                  )}
                </h6>
                <p className="card-text text-muted mb-1 font-weight-light">
                  {moment(props.data.startWhen).format('DD MMM YYYY - HH:mm')}{' '}
                  {props.data.agent ? (
                    <span> - {capitalize(props.data.agent.agentName)}</span>
                  ) : (
                    <span></span>
                  )}
                </p>
                <p className="card-text text-muted mb-2 font-weight-light">
                  {props.data.description || 'No description'}
                  {this.state.copying[props.data.description] && (
                    <div className="spinner-border copy-spinner" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {this.state.doneCopying[props.data.description] && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-lg check-icon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                    </svg>
                  )}
                  {!this.state.copying[props.data.description] &&
                    !this.state.doneCopying[props.data.description] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-copy copy-icon"
                        viewBox="0 0 16 16"
                        onClick={() =>
                          this.copyTextToClipboard(props.data.description)
                        }
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                        />
                      </svg>
                    )}
                </p>
                {Array.isArray(props.data.concepts) &&
                  props.data.concepts.map((item, key) => (
                    <span
                      key={key}
                      className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase"
                    >
                      {item.label}
                    </span>
                  ))}
                {!Array.isArray(props.data.concepts) && (
                  <span className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase">
                    {props.data.concepts}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="card rounded-0 border-bottom-0 card-full-width">
            <div
              className="card-header"
              id="headingTwo"
              onClick={() =>
                this.setState({
                  genericDetailsOpen: !this.state.genericDetailsOpen
                })
              }
            >
              <h6 className="mb-0" style={{ float: 'left' }}>
                Generic details
              </h6>
              <div
                className={
                  this.state.genericDetailsOpen
                    ? `super-treeview-triangle-btn-up`
                    : `super-treeview-triangle-btn-down`
                }
              />
            </div>

            {this.state.genericDetailsOpen && (
              <div>
                <div className="list-group px-3">
                  {props.data.group && props.data.group.groupName && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Group
                      </label>
                      <label
                        htmlFor=""
                        className="col-form-label font-weight-light"
                      >
                        {props.data.group.groupName}
                        {this.state.copying[props.data.group.groupName] && (
                          <div
                            className="spinner-border copy-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.state.doneCopying[props.data.group.groupName] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-lg check-icon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                        )}
                        {!this.state.copying[props.data.group.groupName] &&
                          !this.state.doneCopying[
                            props.data.group.groupName
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-copy copy-icon"
                              viewBox="0 0 16 16"
                              onClick={() =>
                                this.copyTextToClipboard(
                                  props.data.group.groupName
                                )
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          )}
                      </label>
                    </div>
                  )}
                  {props.data.where && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Degrees
                      </label>
                      <label
                        htmlFor=""
                        className="col-form-label font-weight-light"
                      >
                        {getDegMinSec(
                          props.data.where.coordinates[1],
                          props.data.where.coordinates[0]
                        )}
                        {this.state.copying[
                          getDegMinSec(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )
                        ] && (
                          <div
                            className="spinner-border copy-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.state.doneCopying[
                          getDegMinSec(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )
                        ] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-lg check-icon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                        )}
                        {!this.state.copying[
                          getDegMinSec(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )
                        ] &&
                          !this.state.doneCopying[
                            getDegMinSec(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-copy copy-icon"
                              viewBox="0 0 16 16"
                              onClick={() =>
                                this.copyTextToClipboard(
                                  getDegMinSec(
                                    props.data.where.coordinates[1],
                                    props.data.where.coordinates[0]
                                  )
                                )
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          )}
                      </label>
                    </div>
                  )}
                  {props.data.where && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Coordinates
                      </label>
                      {!this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {getWGS(props.data.where.coordinates)}
                          {this.state.copying[
                            getWGS(props.data.where.coordinates)
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getWGS(props.data.where.coordinates)
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getWGS(props.data.where.coordinates)
                          ] &&
                            !this.state.doneCopying[
                              getWGS(props.data.where.coordinates)
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getWGS(props.data.where.coordinates)
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                      {this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {getUTM(
                            props.data.where.coordinates[1],
                            props.data.where.coordinates[0]
                          )}
                          {this.state.copying[
                            getUTM(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getUTM(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getUTM(
                              props.data.where.coordinates[1],
                              props.data.where.coordinates[0]
                            )
                          ] &&
                            !this.state.doneCopying[
                              getUTM(
                                props.data.where.coordinates[1],
                                props.data.where.coordinates[0]
                              )
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getUTM(
                                      props.data.where.coordinates[1],
                                      props.data.where.coordinates[0]
                                    )
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                    </div>
                  )}
                  {props.data.where && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Coordinate type
                      </label>
                      <div>
                        <label htmlFor="" className="col-form-label text-muted">
                          WGS84
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light mx-2 p-0 mt-1"
                        >
                          <Switch
                            height={21}
                            width={42}
                            onChange={() =>
                              this.setState({ UTM: !this.state.UTM })
                            }
                            checked={this.state.UTM}
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />{' '}
                        </label>

                        <label htmlFor="" className="col-form-label text-muted">
                          UTM
                        </label>
                      </div>
                    </div>
                  )}
                  {props.data.attributes &&
                    props.data.attributes.find(
                      attr =>
                        attr.key === 'lastUpdateAt' && !isEmpty(attr.value)
                    ) != undefined && (
                      <div className="detail-container no-gutters mb-1">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Last updated at
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {moment(
                            props.data.attributes.find(
                              attr => attr.key === 'lastUpdateAt'
                            ).value
                          ).format('DD MMM YYYY - HH:mm')}
                        </label>
                      </div>
                    )}
                </div>

                <div
                  id="collapseTwo"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                ></div>
              </div>
            )}
          </div>

          <div className="card rounded-0 border-bottom-0 card-full-width">
            <div
              className="card-header"
              id="headingTwo"
              onClick={() =>
                this.setState({
                  specificDetailsOpen: !this.state.specificDetailsOpen
                })
              }
            >
              <h6 className="mb-0" style={{ float: 'left' }}>
                Specific details
              </h6>
              <div
                className={
                  this.state.specificDetailsOpen
                    ? `super-treeview-triangle-btn-up`
                    : `super-treeview-triangle-btn-down`
                }
              />
            </div>

            {this.state.specificDetailsOpen && (
              <div className="list-group px-3">
                <div>
                  {props.data.attributes != undefined &&
                    props.data.attributes.find(
                      attribute => attribute.key === 'track_description'
                    ) != undefined && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Description
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {
                            props.data.attributes.find(
                              attribute => attribute.key === 'track_description'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attribute => attribute.key === 'track_description'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attribute => attribute.key === 'track_description'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attribute => attribute.key === 'track_description'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attribute =>
                                  attribute.key === 'track_description'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attribute =>
                                        attribute.key === 'track_description'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                  {props.data.attributes != undefined &&
                    props.data.attributes.find(
                      attribute => attribute.key === 'track_team_members'
                    ) != undefined && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Team members
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {
                            props.data.attributes.find(
                              attribute =>
                                attribute.key === 'track_team_members'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attribute =>
                                attribute.key === 'track_team_members'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attribute =>
                                attribute.key === 'track_team_members'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attribute =>
                                attribute.key === 'track_team_members'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attribute =>
                                  attribute.key === 'track_team_members'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attribute =>
                                        attribute.key === 'track_team_members'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                  {props.data.startWhen && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Start track
                      </label>
                      <label
                        htmlFor=""
                        className="col-form-label font-weight-light"
                      >
                        {moment(props.data.startWhen).format(
                          'DD MMM YYYY - HH:mm'
                        )}
                        {this.state.copying[
                          moment(props.data.startWhen).format(
                            'DD MMM YYYY - HH:mm'
                          )
                        ] && (
                          <div
                            className="spinner-border copy-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.state.doneCopying[
                          moment(props.data.startWhen).format(
                            'DD MMM YYYY - HH:mm'
                          )
                        ] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-lg check-icon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                        )}
                        {!this.state.copying[
                          moment(props.data.startWhen).format(
                            'DD MMM YYYY - HH:mm'
                          )
                        ] &&
                          !this.state.doneCopying[
                            moment(props.data.startWhen).format(
                              'DD MMM YYYY - HH:mm'
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-copy copy-icon"
                              viewBox="0 0 16 16"
                              onClick={() =>
                                this.copyTextToClipboard(
                                  moment(props.data.startWhen).format(
                                    'DD MMM YYYY - HH:mm'
                                  )
                                )
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          )}
                      </label>
                    </div>
                  )}

                  {props.data.endWhen && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        End track
                      </label>
                      <label
                        htmlFor=""
                        className="col-form-label font-weight-light"
                      >
                        {moment(props.data.endWhen).format(
                          'DD MMM YYYY - HH:mm'
                        )}
                        {this.state.copying[
                          moment(props.data.endWhen).format(
                            'DD MMM YYYY - HH:mm'
                          )
                        ] && (
                          <div
                            className="spinner-border copy-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.state.doneCopying[
                          moment(props.data.endWhen).format(
                            'DD MMM YYYY - HH:mm'
                          )
                        ] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-lg check-icon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                        )}
                        {!this.state.copying[
                          moment(props.data.endWhen).format(
                            'DD MMM YYYY - HH:mm'
                          )
                        ] &&
                          !this.state.doneCopying[
                            moment(props.data.endWhen).format(
                              'DD MMM YYYY - HH:mm'
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-copy copy-icon"
                              viewBox="0 0 16 16"
                              onClick={() =>
                                this.copyTextToClipboard(
                                  moment(props.data.endWhen).format(
                                    'DD MMM YYYY - HH:mm'
                                  )
                                )
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          )}
                      </label>
                    </div>
                  )}

                  {props.data.length && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Length
                      </label>
                      <label
                        htmlFor=""
                        className="col-form-label font-weight-light"
                      >
                        {props.data.length.toFixed(3)} KM
                        {this.state.copying[props.data.length.toFixed(3)] && (
                          <div
                            className="spinner-border copy-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {this.state.doneCopying[
                          props.data.length.toFixed(3)
                        ] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-lg check-icon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                        )}
                        {!this.state.copying[props.data.length.toFixed(3)] &&
                          !this.state.doneCopying[
                            props.data.length.toFixed(3)
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-copy copy-icon"
                              viewBox="0 0 16 16"
                              onClick={() =>
                                this.copyTextToClipboard(
                                  props.data.length.toFixed(3)
                                )
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          )}
                      </label>
                    </div>
                  )}

                  {props.data.startWhere && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Start point
                      </label>
                      {!this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {getWGS(props.data.startWhere.coordinates)}
                          {this.state.copying[
                            getWGS(props.data.startWhere.coordinates)
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getWGS(props.data.startWhere.coordinates)
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getWGS(props.data.startWhere.coordinates)
                          ] &&
                            !this.state.doneCopying[
                              getWGS(props.data.startWhere.coordinates)
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getWGS(props.data.startWhere.coordinates)
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                      {this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {getUTM(
                            props.data.startWhere.coordinates[1],
                            props.data.startWhere.coordinates[0]
                          )}
                          {this.state.copying[
                            getUTM(
                              props.data.startWhere.coordinates[1],
                              props.data.startWhere.coordinates[0]
                            )
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getUTM(
                              props.data.startWhere.coordinates[1],
                              props.data.startWhere.coordinates[0]
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getUTM(
                              props.data.startWhere.coordinates[1],
                              props.data.startWhere.coordinates[0]
                            )
                          ] &&
                            !this.state.doneCopying[
                              getUTM(
                                props.data.startWhere.coordinates[1],
                                props.data.startWhere.coordinates[0]
                              )
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getUTM(
                                      props.data.startWhere.coordinates[1],
                                      props.data.startWhere.coordinates[0]
                                    )
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                    </div>
                  )}

                  {props.data.endWhere && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        End point
                      </label>
                      {!this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {getWGS(props.data.endWhere.coordinates)}
                          {this.state.copying[
                            getWGS(props.data.endWhere.coordinates)
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getWGS(props.data.endWhere.coordinates)
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getWGS(props.data.endWhere.coordinates)
                          ] &&
                            !this.state.doneCopying[
                              getWGS(props.data.endWhere.coordinates)
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getWGS(props.data.endWhere.coordinates)
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                      {this.state.UTM && (
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {getUTM(
                            props.data.endWhere.coordinates[1],
                            props.data.endWhere.coordinates[0]
                          )}
                          {this.state.copying[
                            getUTM(
                              props.data.endWhere.coordinates[1],
                              props.data.endWhere.coordinates[0]
                            )
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            getUTM(
                              props.data.endWhere.coordinates[1],
                              props.data.endWhere.coordinates[0]
                            )
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            getUTM(
                              props.data.endWhere.coordinates[1],
                              props.data.endWhere.coordinates[0]
                            )
                          ] &&
                            !this.state.doneCopying[
                              getUTM(
                                props.data.endWhere.coordinates[1],
                                props.data.endWhere.coordinates[0]
                              )
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    getUTM(
                                      props.data.endWhere.coordinates[1],
                                      props.data.endWhere.coordinates[0]
                                    )
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      )}
                    </div>
                  )}

                  {(props.data.startWhere || props.data.endWhere) && (
                    <div className="detail-container no-gutters">
                      <label
                        htmlFor=""
                        className="detail-container__title col-form-label text-muted"
                      >
                        Coordinate type
                      </label>
                      <div>
                        <label htmlFor="" className="col-form-label text-muted">
                          WGS84
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light mx-2 p-0 mt-1"
                        >
                          <Switch
                            height={21}
                            width={42}
                            onChange={() =>
                              this.setState({ UTM: !this.state.UTM })
                            }
                            checked={this.state.UTM}
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />{' '}
                        </label>

                        <label htmlFor="" className="col-form-label text-muted">
                          UTM
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="card rounded-0 border-bottom-0 card-full-width">
            <div
              className="card-header"
              id="headingTwo"
              onClick={() =>
                this.setState({
                  technicalDetailsOpen: !this.state.technicalDetailsOpen
                })
              }
            >
              <h6 className="mb-0" style={{ float: 'left' }}>
                Technical details
              </h6>
              <div
                className={
                  this.state.technicalDetailsOpen
                    ? `super-treeview-triangle-btn-up`
                    : `super-treeview-triangle-btn-down`
                }
              />
            </div>

            {this.state.technicalDetailsOpen && (
              <div className="list-group px-3">
                {props.data.featureId != undefined && (
                  <div className="detail-container no-gutters">
                    <label
                      htmlFor=""
                      className="detail-container__title col-form-label text-muted"
                    >
                      Track ID
                    </label>
                    <label
                      htmlFor=""
                      className="col-form-label font-weight-light"
                    >
                      {props.data.featureId}
                      {this.state.copying[props.data.featureId] && (
                        <div
                          className="spinner-border copy-spinner"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {this.state.doneCopying[props.data.featureId] && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-lg check-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                      )}
                      {!this.state.copying[props.data.featureId] &&
                        !this.state.doneCopying[props.data.featureId] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-copy copy-icon"
                            viewBox="0 0 16 16"
                            onClick={() =>
                              this.copyTextToClipboard(props.data.featureId)
                            }
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                          </svg>
                        )}
                    </label>
                  </div>
                )}
                {props.data.observationId && (
                  <div className="detail-container no-gutters">
                    <label
                      htmlFor=""
                      className="detail-container__title col-form-label text-muted"
                    >
                      Observation ID
                    </label>
                    <label
                      htmlFor=""
                      className="col-form-label font-weight-light"
                    >
                      {props.data.observationId}
                      {this.state.copying[props.data.observationId] && (
                        <div
                          className="spinner-border copy-spinner"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {this.state.doneCopying[props.data.observationId] && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-lg check-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                      )}
                      {!this.state.copying[props.data.observationId] &&
                        !this.state.doneCopying[props.data.observationId] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-copy copy-icon"
                            viewBox="0 0 16 16"
                            onClick={() =>
                              this.copyTextToClipboard(props.data.observationId)
                            }
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                          </svg>
                        )}
                    </label>
                  </div>
                )}

                {props.data.trackId && (
                  <div className="detail-container no-gutters">
                    <label
                      htmlFor=""
                      className="detail-container__title col-form-label text-muted"
                    >
                      Track ID
                    </label>
                    <label
                      htmlFor=""
                      className="col-form-label font-weight-light"
                    >
                      {props.data.trackId}
                      {this.state.copying[props.data.trackId] && (
                        <div
                          className="spinner-border copy-spinner"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {this.state.doneCopying[props.data.trackId] && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-lg check-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                      )}
                      {!this.state.copying[props.data.trackId] &&
                        !this.state.doneCopying[props.data.trackId] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-copy copy-icon"
                            viewBox="0 0 16 16"
                            onClick={() =>
                              this.copyTextToClipboard(props.data.trackId)
                            }
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                          </svg>
                        )}
                    </label>
                  </div>
                )}

                {props.data.agent && props.data.agent.agentId && (
                  <div className="detail-container no-gutters">
                    <label
                      htmlFor=""
                      className="detail-container__title col-form-label text-muted"
                    >
                      User ID of Creator
                    </label>
                    <label
                      htmlFor=""
                      className="col-form-label font-weight-light"
                    >
                      {props.data.agent.agentId}
                      {this.state.copying[props.data.agent.agentId] && (
                        <div
                          className="spinner-border copy-spinner"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {this.state.doneCopying[props.data.agent.agentId] && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-lg check-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                      )}
                      {!this.state.copying[props.data.agent.agentId] &&
                        !this.state.doneCopying[props.data.agent.agentId] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-copy copy-icon"
                            viewBox="0 0 16 16"
                            onClick={() =>
                              this.copyTextToClipboard(props.data.agent.agentId)
                            }
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                          </svg>
                        )}
                    </label>
                  </div>
                )}

                {props.data.group && props.data.group.groupId && (
                  <div className="detail-container no-gutters">
                    <label
                      htmlFor=""
                      className="detail-container__title col-form-label text-muted"
                    >
                      Group ID
                    </label>
                    <label
                      htmlFor=""
                      className="col-form-label font-weight-light"
                    >
                      {props.data.group.groupId}
                      {this.state.copying[props.data.group.groupId] && (
                        <div
                          className="spinner-border copy-spinner"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {this.state.doneCopying[props.data.group.groupId] && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-lg check-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                      )}
                      {!this.state.copying[props.data.group.groupId] &&
                        !this.state.doneCopying[props.data.group.groupId] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-copy copy-icon"
                            viewBox="0 0 16 16"
                            onClick={() =>
                              this.copyTextToClipboard(props.data.group.groupId)
                            }
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                          </svg>
                        )}
                    </label>
                  </div>
                )}

                {props.data.attributes && props.data.attributes.length > 0 && (
                  <div>
                    {props.data.attributes.findIndex(
                      attr => attr.key === 'editorId' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          User ID of Editor
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'editorId'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'editorId'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'editorId'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}

                    {props.data.attributes.findIndex(
                      attr => attr.key === 'appVersion' && !isEmpty(attr.value)
                    ) >= 0 && (
                      <div className="detail-container no-gutters">
                        <label
                          htmlFor=""
                          className="detail-container__title col-form-label text-muted"
                        >
                          Cluey version
                        </label>
                        <label
                          htmlFor=""
                          className="col-form-label font-weight-light"
                        >
                          {
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          }
                          {this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          ] && (
                            <div
                              className="spinner-border copy-spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {this.state.doneCopying[
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          ] && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg check-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          {!this.state.copying[
                            props.data.attributes.find(
                              attr => attr.key === 'appVersion'
                            ).value
                          ] &&
                            !this.state.doneCopying[
                              props.data.attributes.find(
                                attr => attr.key === 'appVersion'
                              ).value
                            ] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-copy copy-icon"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  this.copyTextToClipboard(
                                    props.data.attributes.find(
                                      attr => attr.key === 'appVersion'
                                    ).value
                                  )
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default TrackModal;
