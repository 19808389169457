import * as types from './actionTypes';

const reducer = (
  state = {
    imageModal: {
      open: false,
      data: {}
    },
    observationModal: {
      open: false,
      data: {}
    },
    trackModal: {
      open: false,
      data: {}
    }
  },
  action
) => {
  switch (action.type) {
    case types.OPEN_IMAGE_MODAL:
      return {
        ...state,
        imageModal: {
          open: true,
          data: action.payload
        }
      };
    case types.CLOSE_IMAGE_MODAL:
      return {
        ...state,
        imageModal: {
          open: false,
          data: {}
        }
      };
    case types.OPEN_OBSERVATION_MODAL:
      return {
        ...state,
        observationModal: {
          open: true,
          data: action.payload
        }
      };
    case types.CLOSE_OBSERVATION_MODAL:
      return {
        ...state,
        observationModal: {
          open: false,
          data: {}
        }
      };
    case types.OPEN_TRACK_MODAL:
      return {
        ...state,
        trackModal: {
          open: true,
          data: action.payload
        }
      };
    case types.CLOSE_TRACK_MODAL:
      return {
        ...state,
        trackModal: {
          open: false,
          data: {}
        }
      };
    default:
      return state;
  }
};

const imageModalOpen = state => state.imageModal.open;
const imageModalData = state => state.imageModal.data;
const observationModalOpen = state => state.observationModal.open;
const observationModalData = state => state.observationModal.data;
const trackModalOpen = state => state.trackModal.open;
const trackModalData = state => state.trackModal.data;

const modalSelectors = {
  imageModalOpen,
  imageModalData,
  observationModalOpen,
  observationModalData,
  trackModalOpen,
  trackModalData
};

export default reducer;
export { modalSelectors };
