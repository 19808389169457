import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';

import './assets/scss/theme.scss';
import 'bootstrap/js/dist/dropdown';

import appReducers from './store/appReducers';
import App from './App';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  appReducers,
  composeEnhancers(applyMiddleware(thunk))
);

mapboxgl.accessToken =
  'pk.eyJ1IjoiamFua2VlcyIsImEiOiJjand3MG1iYnAwZHBiNDhyenpwejVkbWxjIn0.UAB-FFpQn2QiHOMEDLEBNg';

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./store/appReducers', () => {
      store.replaceReducer(appReducers);
    });
  }
}

render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./App', () => {
    render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
    );
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
