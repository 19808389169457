import util from './util.js';

let ABORT_CONTROLLERS = new Map();

const provider = (function() {
  const provide = function(config = {}) {
    // needed for offline testing with Jest
    const headers = config.cookie ? { Cookie: config.cookie } : {};

    const hierarchy = function(language) {
      return fetch(
        util.buildUrl(
          '/api/ontology/' + config.searchType + '/hierarchy',
          { language },
          config.baseURI
        ),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const counts = function(searchQuery, signalKey) {
      return fetch(
        util.buildUrl(
          '/api/ontology/' + config.searchType + '/counts',
          null,
          config.baseURI
        ),
        {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(searchQuery),
          credentials: 'same-origin',
          signal: signalKey
            ? util.abortAndGetSignal(signalKey, ABORT_CONTROLLERS)
            : null
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const languages = function() {
      return fetch(
        util.buildUrl('/api/ontology/all/languages', null, config.baseURI),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    return {
      name: 'OntologyClient',
      hierarchy,
      counts,
      languages
    };
  };

  return provide;
})();

export default provider;
