import moment from 'moment';

const getStartEndTime = value => {
  let start,
    end = null;

  switch (value) {
    case '0':
      start = moment().format('YYYY-MM-DD');
      end = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD');
      break;
    case '1':
      start = moment()
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      end = moment().format('YYYY-MM-DD');
      break;
    case '2':
      start = moment()
        .add(-7, 'days')
        .format('YYYY-MM-DD');
      end = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD');
      break;
    case '3':
      start = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      end = moment()
        .endOf('month')
        .add(1, 'days')
        .format('YYYY-MM-DD');
      break;
    case '4':
      start = moment()
        .add(-1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      end = moment()
        .add(-1, 'months')
        .endOf('month')
        .add(1, 'days')
        .format('YYYY-MM-DD');
      break;
    case '5':
      start = moment()
        .startOf('year')
        .format('YYYY-MM-DD');
      end = moment()
        .endOf('year')
        .add(1, 'days')
        .format('YYYY-MM-DD');
      break;
    case '6':
      start = moment()
        .add(-1, 'years')
        .startOf('year')
        .format('YYYY-MM-DD');
      end = moment()
        .add(-1, 'years')
        .endOf('year')
        .add(1, 'days')
        .format('YYYY-MM-DD');
      break;
    default:
      start = moment().format('YYYY-MM-DD');
      end = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD');
      break;
  }

  return { start, end };
};

export { getStartEndTime };
