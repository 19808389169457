import * as types from './actionTypes';
import * as authTypes from '../user/actionTypes';
import MapClient from '../../focus-client/map-client';
import GeoFeatureClient from '../../focus-client/crud-client/geofeature-client';
import ObservationClient from '../../focus-client/crud-client/observation-client';

const setProjectLayers = data => ({
  type: types.SET_PROJECT_LAYERS_SUCCESS,
  payload: data
});

const setLayerFeatures = (
  projectId,
  layerId,
  data,
  selectedItem,
  selectedTrackObservations
) => ({
  type: types.SET_LAYER_FEATURES_SUCCESS,
  payload: { projectId, layerId, data, selectedItem, selectedTrackObservations }
});

const setAlertFeatures = (
  projectId,
  layerId,
  data,
  selectedItem,
  selectedTrackObservations
) => ({
  type: types.SET_ALERT_FEATURES_SUCCESS,
  payload: { projectId, layerId, data, selectedItem, selectedTrackObservations }
});

const getEntireTrack = (projectId, layerId, searchQuery) => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return dispatch => {
    let map = MapClient(config);

    return map
      .features(null, projectId, layerId, searchQuery)
      .then(response => {
        if (!response.isError) {
          dispatch(setEntireTrack(response));
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const getTrackInfo = geoFeatureId => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let geoFeature = GeoFeatureClient(config);

    return geoFeature
      .read(geoFeatureId, getState().ontology.selectedLanguage)
      .then(response => {
        if (!response.isError) {
          if (response.envelope) dispatch(setTrackInfo(response.envelope));
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const getTrackObservations = geoFeatureId => {
  var config = {
    baseURI: document.baseURI,
    searchType: 'all'
  };

  return (dispatch, getState) => {
    let geoFeature = GeoFeatureClient(config);
    let observation = ObservationClient(config);

    return geoFeature
      .observations(geoFeatureId, getState().ontology.selectedLanguage)
      .then(response => {
        if (!response.isError) {
          dispatch(setTrackObservations(response));

          response.forEach(item => {
            observation
              .media(
                item.Observation.observationId,
                getState().ontology.selectedLanguage
              )
              .then(mediaResponse => {
                if (mediaResponse.length > 0) {
                  dispatch(
                    setTrackObservationsMedia(
                      item.Observation.observationId,
                      mediaResponse
                    )
                  );
                }
              });
          });
        } else if (response.error.status === 401) {
          dispatch(localLogout());
        }
      });
  };
};

const setSelectedLayers = value => ({
  type: types.SET_SELECTED_LAYERS,
  payload: value
});

const localLogout = () => ({
  type: authTypes.LOCAL_LOGOUT
});

const setEntireTrack = value => ({
  type: types.SET_ENTIRE_TRACK,
  payload: value
});

const setTrackInfo = value => ({
  type: types.SET_TRACK_INFO,
  payload: value
});

const setTrackObservations = value => ({
  type: types.SET_TRACK_OBSERVATIONS,
  payload: value
});

const setTrackObservationsMedia = (observationId, values) => ({
  type: types.SET_TRACK_OBSERVATIONS_MEDIA,
  payload: { observationId, values }
});

const clearEntireTrack = () => ({
  type: types.CLEAR_ENTIRE_TRACK,
  payload: null
});

export default {
  setProjectLayers,
  setLayerFeatures,
  setAlertFeatures,
  getEntireTrack,
  getTrackInfo,
  getTrackObservations,
  clearEntireTrack,
  setSelectedLayers
};
