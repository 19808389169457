export const NETWORK_LOGIN_SUCCESS = 'user/NETWORK_LOGIN_SUCCESS';
export const NETWORK_LOGIN_FAILURE = 'user/NETWORK_LOGIN_FAILURE';

export const NETWORK_LOGOUT_SUCCESS = 'user/NETWORK_LOGOUT_SUCCESS';
export const NETWORK_LOGOUT_FAILURE = 'user/NETWORK_LOGOUT_FAILURE';

export const FETCH_AUTHSTATUS_SUCCESS = 'user/FETCH_AUTHSTATUS_SUCCESS';

export const LOCAL_LOGOUT = 'user/LOCAL_LOGOUT';
export const SET_CURRENT_USER = 'user/SET_CURRENT_USER';
export const LAST_KNOWN_UPDATE = 'user/LAST_KNOWN_UPDATE';
