import * as types from './actionTypes';

import PingClient from '../../focus-client/ping-client';

const completePing = user => ({
  type: types.PING_SUCCESS,
  payload: { user }
});

const ping = () => {
  var config = {
    baseURI: document.baseURI
  };

  return dispatch => {
    let ping = PingClient(config);

    return ping.ping().then(response => {
      if (response.ping === 'pong') {
        dispatch(completePing(response));
      }
    });
  };
};

export default {
  ping
};
