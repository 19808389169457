import { combineReducers } from 'redux';
import ping from './ping/reducers';
import user from './user/reducers';
import timeline from './timeline/reducers';
import geoFeature from './geoFeature/reducers';
import layer from './layer/reducers';
import ontology from './ontology/reducers';
import modals from './modals/reducers';
import query from './query/reducers';

const data = combineReducers({
  ping,
  user,
  timeline,
  geoFeature,
  layer,
  ontology,
  modals,
  query
});

export default data;
