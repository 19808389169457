import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Login from '../components/views/login/Login';

import actions from '../store/appActions';
import selectors from '../store/appSelectors';

const mapStateToProps = (state, ownProps) => ({
  from: (ownProps.location && ownProps.location.state) || { pathname: '/' },
  loginFailure: selectors.user.loginFailure(state.user)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitLogin: actions.user.submitLogin
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
