import React, { Component } from 'react';

class FileItem extends Component {
  openPDF = item => {};

  render() {
    return (
      <div className="card-body px-1 pb-0">
        <a href={this.props.item.url} target="_blank" rel="noreferrer">
          <button type="button" className="btn btn-outline-primary btn-sm">
            {this.props.item.Media.name}
          </button>
        </a>
      </div>
    );
  }
}

export default FileItem;
