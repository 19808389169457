var util = (function() {
  var buildUrl = function(path, params, base) {
    base = base || window.location.href;
    var url = new URL(path, base);
    if (params) {
      Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
          params[key].map(param => url.searchParams.append(key, param));
        } else {
          url.searchParams.append(key, params[key]);
        }
      });
    }
    return url.toString();
  };

  var asArray = function() {
    var args;

    if (arguments.length === 1) {
      if (Array.isArray(arguments[0])) {
        args = arguments[0];
      } else if (arguments[0] === null || arguments[0] === undefined) {
        args = [];
      } else {
        args = [arguments[0]];
      }
    } else {
      // TODO: compact array?
      args = [].slice.call(arguments);
    }

    return args;
  };

  // from lodash
  var isObject = function(value) {
    var type = typeof value;
    return !!value && (type === 'object' || type === 'function');
  };

  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Polyfill
  var extendObject = function(target) {
    // istanbul ignore if
    if (target === undefined || target === null) {
      throw new TypeError('Cannot convert first argument to object');
    }

    var to = Object(target);
    for (var i = 1; i < arguments.length; i++) {
      var nextSource = arguments[i];
      // istanbul ignore if
      if (nextSource === undefined || nextSource === null) {
        continue;
      }
      nextSource = Object(nextSource);

      var keysArray = Object.keys(Object(nextSource));
      for (
        var nextIndex = 0, len = keysArray.length;
        nextIndex < len;
        nextIndex++
      ) {
        var nextKey = keysArray[nextIndex];
        var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
        // istanbul ignore else
        if (desc !== undefined && desc.enumerable) {
          to[nextKey] = nextSource[nextKey];
        }
      }
    }
    return to;
  };

  var formData = function(params) {
    let data = new URLSearchParams();
    Object.keys(params).forEach(key => {
      let val = params[key];
      if (isObject(val)) {
        data.append(key, JSON.stringify(val));
      } else {
        data.append(key, val);
      }
    });
    return data;
  };

  function abortAndGetSignal(key, ABORT_CONTROLLERS) {
    ABORT_CONTROLLERS.get(key)?.abort?.('CANCELLED');
    const newController = new AbortController();
    ABORT_CONTROLLERS.set(key, newController);
    return newController.signal;
  }

  return {
    buildUrl,
    asArray,
    isObject,
    extendObject,
    formData,
    abortAndGetSignal
  };
})();

export default util;
