import React, { Component } from 'react';
import moment from 'moment';

import Modal from 'react-bootstrap/Modal';

class ImageModal extends Component {
  render() {
    const { ...props } = this.props;

    return (
      <Modal show={props.open} onHide={props.close}>
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {props.data.Media ? props.data.Media.name : ''}
          </h5>
          <span className="text-muted font-weight-light  ml-2 mt-2">
            {' '}
            -{' '}
            {props.data.Media
              ? moment(props.data.Media.when).format('DD MMM YYYY - HH:mm')
              : ''}
          </span>
        </Modal.Header>
        <Modal.Body>
          <img src={props.data.url} className="img-fluid" alt="" />

          <div className="pt-3">
            {/* <span className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase">Reptile</span>
                    <span className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase">Female</span>
                    <span className="badge badge-secondary p-2 mr-1 mb-1 text-uppercase">Adult</span> */}

            <span
              type="button"
              className="btn btn-outline-primary btn-sm float-right d-none"
              data-toggle="collapse"
              href="#multiCollapseExample2"
              role="button"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
            >
              JPG
              <svg
                className="bi bi-download ml-1"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http:www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ImageModal;
