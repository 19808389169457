import React, { Component } from 'react';

class MouseLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseLat: '',
      mouseLong: ''
    };
  }

  componentDidMount() {
    this.setState({
      mouseLat: this.props.lat,
      mouseLong: this.props.long
    });
  }

  changeLocation(e) {
    this.setState({
      mouseLat: e.lngLat.lat.toFixed(4),
      mouseLong: e.lngLat.lng.toFixed(4)
    });
  }

  render() {
    return (
      <div id="mouse-coordinates" className="p-2 mr-3">
        <div>Lat: {this.state.mouseLat}</div>
        <div>Long: {this.state.mouseLong}</div>
      </div>
    );
  }
}

export default MouseLocation;
