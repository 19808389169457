import QueryBuilder from '../query-builder';

const createQuery = (
  scroll,
  withConcepts,
  withDataSources,
  withEntities,
  withAgents,
  timelineLength,
  coordinates,
  areas,
  concepts,
  mode,
  dataSources,
  agents
) => {
  let query = new QueryBuilder.SearchQuery().withOptions(
    new QueryBuilder.SearchOptions(scroll ? timelineLength + 1 : 0, 20)
  );

  if (coordinates)
    query.withGeoQuery(
      new QueryBuilder.GeoQuery()
        .withOperator('intersects')
        .withMapBounds(
          new QueryBuilder.Box(
            coordinates.south,
            coordinates.west,
            coordinates.north,
            coordinates.east
          )
        )
        .withDrawings(buildWithDrawingsQuery(areas))
    );

  if (withEntities) query.withEntities(['track', 'Observation'], 'or');
  if (withConcepts) query.withConcepts(concepts, mode === true ? 'or' : 'and');
  if (withDataSources)
    query.withDataSources(
      dataSources.map(item => item.name),
      'or'
    );
  if (withAgents)
    query.withAgents(
      agents.map(item => item.name),
      'or'
    );

  return query;
};

const buildWithDrawingsQuery = areas => {
  let polygons = [];
  for (var i = 0; i < areas.length; i++) {
    let points = [];
    for (var j = 0; j < areas[i].geometry.coordinates[0].length; j++) {
      let latitude = areas[i].geometry.coordinates[0][j][1];
      let longitude = areas[i].geometry.coordinates[0][j][0];
      points.push(new QueryBuilder.Point(latitude, longitude));
    }
    polygons.push(new QueryBuilder.Polygon(points));
  }
  return polygons;
};

export { createQuery };
