import ping from './ping/actions';
import user from './user/actions';
import timeline from './timeline/actions';
import geoFeature from './geoFeature/actions';
import ontology from './ontology/actions';
import layer from './layer/actions';
import modals from './modals/actions';
import query from './query/actions';

const data = {
  ping,
  user,
  timeline,
  geoFeature,
  ontology,
  layer,
  modals,
  query
};

export default data;
