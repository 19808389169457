import React, { Component } from 'react';
import Switch from 'react-switch';

import moment from 'moment';

import { getUTM } from '../../../utils/locationUtil';

import './Observation.scss';

class AgentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      UTM: false
    };
  }

  handleSwitch = value => {
    this.setState({ UTM: value });
  };

  render() {
    const { item, ...props } = this.props;
    return (
      <div
        id={item.EntityId}
        className={`card container rounded-0 border-left-0 border-top-0 border-right-0 border-bottom p-0 ${
          props.selected ? 'selected' : ''
        }`}
      >
        <div className="row no-gutters">
          <div className="w-100">
            <div className="card-block pl-4 pr-2 pt-3">
              <h6 className="card-title mb-2 mt-1 font-weight-bold pointer">
                {item.properties && item.properties.EntityType === 'user' && (
                  <span
                    className=""
                    onClick={() => props.setSelectedItem(item)}
                  >
                    Observer
                  </span>
                )}
              </h6>
              <div className="card-text mb-2">
                {item.properties && item.properties.Name && (
                  <div className="row no-gutters">
                    <span className="col-12 col-md-3  col-form-label text-muted">
                      Observer
                    </span>
                    <span className="col-12 col-md-9  col-form-label font-weight-light">
                      {item.properties.Name}
                    </span>
                  </div>
                )}
                {item.properties && item.properties.LastSeenDateTime && (
                  <div className="row no-gutters">
                    <span className="col-12 col-md-3  col-form-label text-muted">
                      When
                    </span>
                    <span className="col-12 col-md-9 col-form-label font-weight-light">
                      {moment(item.properties.LastSeenDateTime).format(
                        'DD MMM YYYY - HH:mm'
                      )}
                    </span>
                  </div>
                )}
                {item.geometry && item.geometry.coordinates && (
                  <div className="row no-gutters">
                    <span className="col-12 col-md-3 col-form-label text-muted">
                      Coordinates
                    </span>
                    {!this.state.UTM && (
                      <span className="col-12 col-md-9 col-form-label font-weight-light">
                        {item.geometry.coordinates[1]},{' '}
                        {item.geometry.coordinates[0]}
                      </span>
                    )}
                    {this.state.UTM && (
                      <span className="col-12 col-md-8 col-form-label font-weight-light">
                        {getUTM(
                          item.geometry.coordinates[1],
                          item.geometry.coordinates[0]
                        )}
                      </span>
                    )}
                  </div>
                )}

                {item.geometry && item.geometry.coordinates && (
                  <div className="row no-gutters">
                    <label
                      htmlFor=""
                      className="col-12 col-md-3 col-form-label text-muted"
                    >
                      WGS84
                    </label>
                    <label
                      htmlFor=""
                      className="col-6 col-md-2 col-form-label font-weight-light"
                    >
                      <Switch
                        height={21}
                        width={42}
                        onChange={this.handleSwitch}
                        checked={this.state.UTM}
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />{' '}
                    </label>
                    <label
                      htmlFor=""
                      className="col-6 col-md-7 col-form-label text-muted"
                    >
                      UTM
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgentItem;
