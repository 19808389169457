import util from '../util.js';

const provider = (function() {
  const provide = function(config = {}) {
    // needed for offline testing with Jest
    const headers = config.cookie ? { Cookie: config.cookie } : {};

    const read = function(observationId, language) {
      return fetch(
        util.buildUrl(
          '/api/crud/Observation/' + encodeURIComponent(observationId),
          { language },
          config.baseURI
        ),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const media = function(observationId, language) {
      return fetch(
        util.buildUrl(
          '/api/crud/Observation/' +
            encodeURIComponent(observationId) +
            '/Media',
          { language },
          config.baseURI
        ),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    return {
      name: 'ObservationClient',
      read,
      media
    };
  };

  return provide;
})();

export default provider;
