const getUTM = (latitude, longitude) => {
  let zone = Math.floor(longitude / 6 + 31);

  let letter = '';
  if (latitude < -72) letter = 'C';
  else if (latitude < -64) letter = 'D';
  else if (latitude < -56) letter = 'E';
  else if (latitude < -48) letter = 'F';
  else if (latitude < -40) letter = 'G';
  else if (latitude < -32) letter = 'H';
  else if (latitude < -24) letter = 'J';
  else if (latitude < -16) letter = 'K';
  else if (latitude < -8) letter = 'L';
  else if (latitude < 0) letter = 'M';
  else if (latitude < 8) letter = 'N';
  else if (latitude < 16) letter = 'P';
  else if (latitude < 24) letter = 'Q';
  else if (latitude < 32) letter = 'R';
  else if (latitude < 40) letter = 'S';
  else if (latitude < 48) letter = 'T';
  else if (latitude < 56) letter = 'U';
  else if (latitude < 64) letter = 'V';
  else if (latitude < 72) letter = 'W';
  else letter = 'X';

  let easting =
    ((0.5 *
      Math.log(
        (1 +
          Math.cos((latitude * Math.PI) / 180) *
            Math.sin(
              (longitude * Math.PI) / 180 - ((6 * zone - 183) * Math.PI) / 180
            )) /
          (1 -
            Math.cos((latitude * Math.PI) / 180) *
              Math.sin(
                (longitude * Math.PI) / 180 - ((6 * zone - 183) * Math.PI) / 180
              ))
      ) *
      0.9996 *
      6399593.62) /
      Math.pow(
        1 +
          Math.pow(0.0820944379, 2) *
            Math.pow(Math.cos((latitude * Math.PI) / 180), 2),
        0.5
      )) *
      (1 +
        ((Math.pow(0.0820944379, 2) / 2) *
          Math.pow(
            0.5 *
              Math.log(
                (1 +
                  Math.cos((latitude * Math.PI) / 180) *
                    Math.sin(
                      (longitude * Math.PI) / 180 -
                        ((6 * zone - 183) * Math.PI) / 180
                    )) /
                  (1 -
                    Math.cos((latitude * Math.PI) / 180) *
                      Math.sin(
                        (longitude * Math.PI) / 180 -
                          ((6 * zone - 183) * Math.PI) / 180
                      ))
              ),
            2
          ) *
          Math.pow(Math.cos((latitude * Math.PI) / 180), 2)) /
          3) +
    500000;
  easting = Math.round(easting * 100) * 0.01;
  let northing =
    (((Math.atan(
      Math.tan((latitude * Math.PI) / 180) /
        Math.cos(
          (longitude * Math.PI) / 180 - ((6 * zone - 183) * Math.PI) / 180
        )
    ) -
      (latitude * Math.PI) / 180) *
      0.9996 *
      6399593.625) /
      Math.sqrt(
        1 + 0.006739496742 * Math.pow(Math.cos((latitude * Math.PI) / 180), 2)
      )) *
      (1 +
        (0.006739496742 / 2) *
          Math.pow(
            0.5 *
              Math.log(
                (1 +
                  Math.cos((latitude * Math.PI) / 180) *
                    Math.sin(
                      (longitude * Math.PI) / 180 -
                        ((6 * zone - 183) * Math.PI) / 180
                    )) /
                  (1 -
                    Math.cos((latitude * Math.PI) / 180) *
                      Math.sin(
                        (longitude * Math.PI) / 180 -
                          ((6 * zone - 183) * Math.PI) / 180
                      ))
              ),
            2
          ) *
          Math.pow(Math.cos((latitude * Math.PI) / 180), 2)) +
    0.9996 *
      6399593.625 *
      ((latitude * Math.PI) / 180 -
        0.005054622556 *
          ((latitude * Math.PI) / 180 +
            Math.sin((2 * latitude * Math.PI) / 180) / 2) +
        (4.258201531e-5 *
          (3 *
            ((latitude * Math.PI) / 180 +
              Math.sin((2 * latitude * Math.PI) / 180) / 2) +
            Math.sin((2 * latitude * Math.PI) / 180) *
              Math.pow(Math.cos((latitude * Math.PI) / 180), 2))) /
          4 -
        (1.674057895e-7 *
          ((5 *
            (3 *
              ((latitude * Math.PI) / 180 +
                Math.sin((2 * latitude * Math.PI) / 180) / 2) +
              Math.sin((2 * latitude * Math.PI) / 180) *
                Math.pow(Math.cos((latitude * Math.PI) / 180), 2))) /
            4 +
            Math.sin((2 * latitude * Math.PI) / 180) *
              Math.pow(Math.cos((latitude * Math.PI) / 180), 2) *
              Math.pow(Math.cos((latitude * Math.PI) / 180), 2))) /
          3);
  if (letter < 'N') northing = northing + 10000000;
  northing = Math.round(northing * 100) * 0.01;

  return `${zone}${letter} ${Math.floor(easting)} ${Math.floor(northing)}`;
};

function toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + '° ' + minutes + "' " + seconds + '"';
}

const getDegMinSec = (lat, lng) => {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? 'N' : 'S';

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return (
    latitude +
    ' ' +
    latitudeCardinal +
    '\n' +
    longitude +
    ' ' +
    longitudeCardinal
  );
};

const getWGS = coor => {
  return coor[0] + ', ' + coor[1];
};

export { getUTM, getDegMinSec, getWGS };
