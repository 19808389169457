import React from 'react';
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';

function DraggableModalDialog({ nodeRef, ...props }) {
  return (
    <Draggable nodeRef={nodeRef}>
      <div>
        <ModalDialog {...props} />
      </div>
    </Draggable>
  );
}

export default DraggableModalDialog;
