import React from 'react';
import { withRouter } from 'react-router-dom';

//import logo from './assets/img/logo/logo_SC_square.png';
import './assets/scss/theme.scss';
import AppContainer from './containers/AppContainer';
import Header from './components/views/header/Header';
import Routes from './components/Routes';

const App = appProps => (
  <AppContainer
    {...appProps}
    render={props => (
      <div style={{ height: '100%' }}>
        <Header
          pingInfo={props.pingInfo}
          isAuthenticated={props.isAuthenticated}
          currentUsername={props.currentUser}
          submitLogout={props.submitLogout}
          LKPUpdate={props.LKPUpdate}
          languages={props.languages}
          setSelectedLanguage={props.setSelectedLanguage}
          setUpdateInterval={props.setUpdateInterval}
          alertsTotal={props.alertsTotal}
          setAlertTimeline={props.setAlertTimeline}
          alertSounds={props.alertSounds}
          allowAlertSounds={props.allowAlertSounds}
          disableAlertSounds={props.disableAlertSounds}
        />
        <main className="content" role="main">
          <Routes {...props} />
        </main>
      </div>
    )}
  />
);

export default withRouter(App);
