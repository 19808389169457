import util from './util.js';

const provider = (function() {
  const provide = function(config = {}) {
    const ping = function() {
      // no cedentials/cookie needed
      return fetch(util.buildUrl('/api/ping', null, config.baseURI), {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        },
        credentials: 'same-origin'
      }).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    return {
      name: 'PingClient',
      ping
    };
  };

  return provide;
})();

export default provider;
