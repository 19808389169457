import * as types from './actionTypes';

const setGeoFeatures = geoFeatures => ({
  type: types.SET_GEO_FEATURES_SUCCESS,
  payload: { geoFeatures }
});

export default {
  setGeoFeatures
};
