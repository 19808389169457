import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Home from '../components/views/Home';

import actions from '../store/appActions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAggregates: actions.timeline.getAggregates,
      getTimeline: actions.timeline.getTimeline,
      getAlertTimeline: actions.timeline.getAlertTimeline,
      setScrollToTop: actions.timeline.setScrollToTop,
      getAllLayerFeatures: actions.user.getAllLayerFeatures,
      getAlertLayerFeature: actions.user.getAlertLayerFeature,
      getConceptsCount: actions.ontology.getConceptsCount,
      getDataSources: actions.timeline.getDataSources,
      getAgents: actions.timeline.getAgents,
      getFilterTreeList: actions.ontology.getFilterTreeList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
