import React, { Component } from 'react';
import moment from 'moment';
import { getStartEndTime } from '../../../utils/timeUtil';
import 'react-dropdown-tree-select/dist/styles.css';
import 'react-nice-dates/build/style.css';
import './Header.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import actions from '../../../store/appActions';

// var typingTimer;

const appStyles = {
  fontFamily: 'sans-serif',
  textAlign: 'center'
};

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      standardTimeValue: 0,
      min: null,
      max: null,
      rangedTimeStart: null,
      rangedTimeEnd: null,
      timeType: 0,
      startValid: true,
      endValid: true,
      startBeforeEnd: true,
      dayStart: '',
      monthStart: '',
      inputMonthStart: '',
      yearStart: '',
      dayEnd: '',
      monthEnd: '',
      inputMonthEnd: '',
      yearEnd: ''
    };
  }

  async componentDidMount() {
    let timeType = localStorage.getItem('timeType');
    let standardTimeValue = localStorage.getItem('fixedTimePeriod');
    let rangedTimeStart = localStorage.getItem('rangedTimePeriodStart')
      ? parseInt(localStorage.getItem('rangedTimePeriodStart'))
      : null;
    let rangedTimeEnd = parseInt(localStorage.getItem('rangedTimePeriodEnd'))
      ? parseInt(localStorage.getItem('rangedTimePeriodEnd'))
      : null;

    let startMod = moment(
      rangedTimeStart != null
        ? rangedTimeStart
        : moment()
            .startOf('month')
            .valueOf()
    ).format('YYYY-MM-DD');
    let endMod = moment(
      rangedTimeEnd != null
        ? rangedTimeEnd
        : moment()
            .startOf('day')
            .valueOf()
    ).format('YYYY-MM-DD');

    let start = moment().format('YYYY-MM-DD');
    let end = moment()
      .add(1, 'days')
      .format('YYYY-MM-DD');

    if (timeType == 0 || timeType == null) {
      let timeObject = getStartEndTime(standardTimeValue);
      start = timeObject.start;
      end = timeObject.end;
    } else if (timeType == 1) {
      start = moment(rangedTimeStart).format('YYYY-MM-DD');
      end = moment(rangedTimeEnd)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    await this.setState({
      standardTimeValue: standardTimeValue ? standardTimeValue : 0,
      min: startMod,
      max: endMod,
      timeType: timeType != null ? timeType : 0
    });

    await this.props.setStartEnd(start, end);
    this.setRangedTimePeriod(this.state.min, this.state.max, false);
  }

  changeTimeType = async timeType => {
    await this.setState({ timeType: timeType });
    localStorage.setItem('timeType', timeType);
    if (timeType == 0) {
      this.setFixedTimePeriod(this.state.standardTimeValue);
    } else {
      this.setRangedTimePeriod(this.state.min, this.state.max, true);
    }
  };

  // keyUp = e => {

  //   clearTimeout(typingTimer);
  //   typingTimer = setTimeout(value => this.search(value), 2000, e.target.value);
  // };

  fromDay = async e => {
    let value = parseInt(e.target.value);
    if (value > 31) value = 31;
    await this.setState({ dayStart: value });
    if (
      moment(
        {
          year: this.state.yearStart,
          month: this.state.monthStart,
          day: this.state.dayStart
        },
        true
      ).isValid() &&
      this.state.yearStart !== '' &&
      this.state.monthStart !== '' &&
      this.state.dayStart !== ''
    ) {
      let startBeforeEndBool = moment({
        year: this.state.yearStart,
        month: this.state.monthStart,
        day: this.state.dayStart
      }).isSameOrBefore(
        moment({
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        }),
        'day'
      );
      this.setState({ startValid: true, startBeforeEnd: startBeforeEndBool });
      this.setStartDate();
    } else {
      this.setState({ startValid: false });
    }
  };

  fromMonth = async e => {
    let value = parseInt(e.target.value);
    if (value > 12) value = 12;
    await this.setState({ inputMonthStart: value, monthStart: value - 1 });
    if (
      moment(
        {
          year: this.state.yearStart,
          month: this.state.monthStart,
          day: this.state.dayStart
        },
        true
      ).isValid() &&
      this.state.yearStart !== '' &&
      this.state.monthStart !== '' &&
      this.state.dayStart !== ''
    ) {
      let startBeforeEndBool = moment({
        year: this.state.yearStart,
        month: this.state.monthStart,
        day: this.state.dayStart
      }).isSameOrBefore(
        moment({
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        }),
        'day'
      );
      this.setState({ startValid: true, startBeforeEnd: startBeforeEndBool });
      this.setStartDate();
    } else {
      this.setState({ startValid: false });
    }
  };

  fromYear = async e => {
    let value = parseInt(e.target.value);
    await this.setState({ yearStart: value });
    if (
      moment(
        {
          year: this.state.yearStart,
          month: this.state.monthStart,
          day: this.state.dayStart
        },
        true
      ).isValid() &&
      this.state.yearStart !== '' &&
      this.state.monthStart !== '' &&
      this.state.dayStart !== ''
    ) {
      let startBeforeEndBool = moment({
        year: this.state.yearStart,
        month: this.state.monthStart,
        day: this.state.dayStart
      }).isSameOrBefore(
        moment({
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        }),
        'day'
      );
      this.setState({ startValid: true, startBeforeEnd: startBeforeEndBool });
      this.setStartDate();
    } else {
      this.setState({ startValid: false });
    }
  };

  toDay = async e => {
    let value = parseInt(e.target.value);
    if (value > 31) value = 31;
    await this.setState({ dayEnd: value });
    if (
      moment(
        {
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        },
        true
      ).isValid() &&
      this.state.yearEnd !== '' &&
      this.state.monthEnd !== '' &&
      this.state.dayEnd !== ''
    ) {
      let startBeforeEndBool = moment({
        year: this.state.yearStart,
        month: this.state.monthStart,
        day: this.state.dayStart
      }).isSameOrBefore(
        moment({
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        }),
        'day'
      );
      this.setState({ endValid: true, startBeforeEnd: startBeforeEndBool });
      this.setEndDate();
    } else {
      this.setState({ endValid: false });
    }
  };

  toMonth = async e => {
    let value = parseInt(e.target.value);
    if (value > 12) value = 12;
    await this.setState({ inputMonthEnd: value, monthEnd: value - 1 });
    if (
      moment(
        {
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        },
        true
      ).isValid() &&
      this.state.yearEnd !== '' &&
      this.state.monthEnd !== '' &&
      this.state.dayEnd !== ''
    ) {
      let startBeforeEndBool = moment({
        year: this.state.yearStart,
        month: this.state.monthStart,
        day: this.state.dayStart
      }).isSameOrBefore(
        moment({
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        }),
        'day'
      );
      this.setState({ endValid: true, startBeforeEnd: startBeforeEndBool });
      this.setEndDate();
    } else {
      this.setState({ endValid: false });
    }
  };

  toYear = async e => {
    let value = parseInt(e.target.value);
    await this.setState({ yearEnd: value });
    if (
      moment(
        {
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        },
        true
      ).isValid() &&
      this.state.yearEnd !== '' &&
      this.state.monthEnd !== '' &&
      this.state.dayEnd !== ''
    ) {
      let startBeforeEndBool = moment({
        year: this.state.yearStart,
        month: this.state.monthStart,
        day: this.state.dayStart
      }).isSameOrBefore(
        moment({
          year: this.state.yearEnd,
          month: this.state.monthEnd,
          day: this.state.dayEnd
        }),
        'day'
      );
      this.setState({ endValid: true, startBeforeEnd: startBeforeEndBool });
      this.setEndDate();
    } else {
      this.setState({ endValid: false });
    }
  };

  search = e => {
    this.props.setSearchQuery(e);
    this.props.fetchAllData();
  };

  setFixedTimePeriod = event => {
    // Set to local state and local storage
    this.setState({ standardTimeValue: event });
    localStorage.setItem('fixedTimePeriod', event);

    if (this.state.timeType != 0) return;
    let timeObject = getStartEndTime(event);

    this.setTimePeriod(timeObject.start, timeObject.end);
  };

  setRangedTimePeriod = (start, end, makeCall) => {
    localStorage.setItem('rangedTimePeriodStart', moment(start).format('x'));
    localStorage.setItem('rangedTimePeriodEnd', moment(end).format('x'));
    let localStart = start;
    let localEnd = end;
    let startBeforeEndBool = moment(localStart).isSameOrBefore(
      moment(localEnd),
      'day'
    );

    this.setState({
      dayStart: moment(localStart).date(),
      inputMonthStart: moment(localStart).month() + 1,
      monthStart: moment(localStart).month(),
      yearStart: moment(localStart).year(),
      dayEnd: moment(localEnd).date(),
      inputMonthEnd: moment(localEnd).month() + 1,
      monthEnd: moment(localEnd).month(),
      yearEnd: moment(localEnd).year(),
      startBeforeEnd: startBeforeEndBool
    });

    if (makeCall) {
      this.setTimePeriod(
        localStart,
        moment(localEnd)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      );
    }
  };

  setStartDate = async () => {
    if (this.state.timeType != 1) return;

    await this.setState({
      min: moment({
        year: this.state.yearStart,
        month: this.state.monthStart,
        day: this.state.dayStart
      }).format('YYYY-MM-DD')
    });
    localStorage.setItem(
      'rangedTimePeriodStart',
      moment(this.state.min).format('x')
    );
  };

  setEndDate = async () => {
    if (this.state.timeType != 1) return;

    await this.setState({
      max: moment({
        year: this.state.yearEnd,
        month: this.state.monthEnd,
        day: this.state.dayEnd
      }).format('YYYY-MM-DD')
    });
    localStorage.setItem(
      'rangedTimePeriodEnd',
      moment(this.state.max).format('x')
    );
  };

  getData = () => {
    let start = this.state.min;
    let end = moment(this.state.max).format('YYYY-MM-DD');

    if (start != 'Invalid date' && end != 'Invalid date')
      this.setTimePeriod(
        start,
        moment(end)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      );
  };

  setTimePeriod = async (start, end) => {
    this.props.setStartEnd(
      moment(start).format('YYYY-MM-DD'),
      moment(end).format('YYYY-MM-DD')
    );
    this.props.fetchAllData();
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row search">
          <div className="col-12 border-bottom px-0 py-2">
            <div className="px-3">
              <form onSubmit={e => e.preventDefault()}>
                <div className="form-row align-items-center">
                  <div className="col-auto">
                    <div className="input-group border-right pr-3">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control ml-1"
                        aria-label="Text input with dropdown button"
                        onSubmit={this.prevent}
                        onKeyUp={e => this.search(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mr-4">
                    <label
                      className="form-check-label ml-2 mr-3"
                      htmlFor="fixedPeriod"
                    >
                      Selected time period
                    </label>
                    <input
                      className="form-check-input ml-1"
                      type="radio"
                      name="exampleRadios"
                      id="fixedPeriod"
                      value={0}
                      checked={this.state.timeType == 0 ? true : false}
                      onChange={e => this.changeTimeType(e.target.value)}
                    />
                  </div>

                  <div className="col-auto pr-3">
                    <label
                      className="sr-only "
                      htmlFor="inlineFormCustomSelect"
                    >
                      selected time period
                    </label>
                    <select
                      className="custom-select my-2"
                      id="inlineFormCustomSelect"
                      onChange={e => this.setFixedTimePeriod(e.target.value)}
                      value={this.state.standardTimeValue}
                      disabled={this.state.timeType == 0 ? false : true}
                    >
                      <option value={0}>Today</option>
                      <option value={1}>Yesterday</option>
                      <option value={2}>Past 7 days</option>
                      <option value={3}>This month</option>
                      <option value={4}>Past month</option>
                      <option value={5}>This year</option>
                      <option value={6}>Past year</option>
                    </select>
                  </div>

                  <div className="ml-4 ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="rangedPeriod"
                      value={1}
                      checked={this.state.timeType == 1 ? true : false}
                      onChange={e => this.changeTimeType(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="rangedPeriod"
                    ></label>
                  </div>

                  <div className="ml-1 pr-3 border-right">
                    <div style={appStyles}>
                      <div className="input-group">
                        <input
                          value={this.state.dayStart}
                          type="number"
                          step={1}
                          disabled={this.state.timeType == 1 ? false : true}
                          min="1"
                          max="31"
                          placeholder="Day"
                          className="form-control ml-1 date-range-input day"
                          aria-label="day"
                          onSubmit={this.prevent}
                          onChange={this.fromDay}
                        />
                        <select
                          name="month"
                          id="month"
                          value={this.state.inputMonthStart}
                          disabled={this.state.timeType == 1 ? false : true}
                          className="form-control ml-1 date-range-input month"
                          onChange={this.fromMonth}
                        >
                          <option value="1">Jan.</option>
                          <option value="2">Feb.</option>
                          <option value="3">Mar.</option>
                          <option value="4">Apr.</option>
                          <option value="5">May</option>
                          <option value="6">Jun.</option>
                          <option value="7">Jul.</option>
                          <option value="8">Aug.</option>
                          <option value="9">Sep.</option>
                          <option value="10">Oct.</option>
                          <option value="11">Nov.</option>
                          <option value="12">Dec.</option>
                        </select>
                        <input
                          value={this.state.yearStart}
                          disabled={this.state.timeType == 1 ? false : true}
                          type="number"
                          placeholder="Year"
                          className="form-control ml-1"
                          aria-label="year"
                          onSubmit={this.prevent}
                          onChange={this.fromYear}
                        />
                        {!this.state.startValid && this.state.timeType == 1 && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Invalid date
                              </Tooltip>
                            }
                          >
                            <span className="exclamation-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-exclamation-triangle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                              </svg>
                            </span>
                          </OverlayTrigger>
                        )}
                        <span className="date-range_arrow" />
                        <input
                          value={this.state.dayEnd}
                          disabled={this.state.timeType == 1 ? false : true}
                          type="number"
                          placeholder="Day"
                          className="form-control ml-1 date-range-input day"
                          aria-label="day"
                          onSubmit={this.prevent}
                          onChange={this.toDay}
                        />
                        <select
                          value={this.state.inputMonthEnd}
                          disabled={this.state.timeType == 1 ? false : true}
                          name="month"
                          className="form-control ml-2 date-range-input month"
                          id="month"
                          onChange={this.toMonth}
                        >
                          <option value="1">Jan.</option>
                          <option value="2">Feb.</option>
                          <option value="3">Mar.</option>
                          <option value="4">Apr.</option>
                          <option value="5">May</option>
                          <option value="6">Jun.</option>
                          <option value="7">Jul.</option>
                          <option value="8">Aug.</option>
                          <option value="9">Sep.</option>
                          <option value="10">Oct.</option>
                          <option value="11">Nov.</option>
                          <option value="12">Dec.</option>
                        </select>
                        <input
                          value={this.state.yearEnd}
                          disabled={this.state.timeType == 1 ? false : true}
                          type="number"
                          placeholder="Year"
                          className="form-control ml-1"
                          aria-label="year"
                          onSubmit={this.prevent}
                          onChange={this.toYear}
                        />
                        {(!this.state.endValid || !this.state.startBeforeEnd) &&
                          this.state.timeType == 1 && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  {!this.state.startBeforeEnd
                                    ? 'End date before start date'
                                    : 'Invalid date'}
                                </Tooltip>
                              }
                            >
                              <span className="exclamation-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-exclamation-triangle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                  <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                </svg>
                              </span>
                            </OverlayTrigger>
                          )}
                        <button
                          disabled={
                            this.state.timeType == 1 &&
                            this.state.startValid &&
                            this.state.endValid &&
                            this.state.startBeforeEnd
                              ? false
                              : true
                          }
                          type="button"
                          className="btn btn-primary btn-sm float-right ml-4"
                          onClick={e => {
                            if (this.state.timeType == 1) {
                              e.stopPropagation();
                              this.getData();
                            }
                          }}
                        >
                          Get data
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const getSearchQuery = state => state.query.searchQuery;

const mapStateToProps = createSelector(getSearchQuery, searchQuery => ({
  searchQuery
}));

const mapDispatchToProps = {
  setSearchQuery: actions.query.setSearchQuery,
  getTimeline: actions.timeline.getTimeline,
  setStartEnd: actions.query.setStartEnd
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
