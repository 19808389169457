import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../store/appActions';
import selectors from '../store/appSelectors';

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    // Move fetch() to global so errors, presently just 401s, can be intercepted
    const fetch = global.fetch;
    global.fetch = function(url, options) {
      return fetch(url, options).then(response => {
        if (response.status === 401) {
          props.getAuthenticationStatus();
        }
        return response;
      });
    };
  }

  async componentDidMount() {
    await this.props.ping();
    if (this.props.pingInfo.ping === 'pong') {
      if (!this.props.currentUser) {
        this.props.getAuthenticationStatus();
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      this.props.localLogout();
    } else if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      await this.props.getLanguages();
      this.props.describeMap();
    }
  }

  render() {
    return this.props.render(this.props);
  }
}

AppContainer.propTypes = {
  ping: PropTypes.func.isRequired,
  currentUser: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  getAuthenticationStatus: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  submitLogout: PropTypes.func.isRequired,
  localLogout: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    pingInfo: selectors.ping.pingInfo(state.ping),
    isAuthenticated: selectors.user.isCurrentUserAuthenticated(state.user),
    currentUser: selectors.user.currentUser(state.user),
    LKPUpdate: selectors.user.LKPUpdate(state.user),
    languages: selectors.ontology.languages(state.ontology),
    alertsTotal: selectors.timeline.alertsTotal(state.timeline),
    alertSounds: selectors.timeline.alertSounds(state.timeline),
    ...ownProps
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ping: actions.ping.ping,
      submitLogout: actions.user.submitLogout,
      localLogout: actions.user.localLogout,
      describeMap: actions.user.describeMap,
      getAuthenticationStatus: actions.user.getAuthenticationStatus,
      getLanguages: actions.ontology.getLanguages,
      setSelectedLanguage: actions.ontology.setSelectedLanguage,
      setUpdateInterval: actions.ontology.setUpdateInterval,
      setAlertTimeline: actions.ontology.setAlertTimeline,
      allowAlertSounds: actions.timeline.allowAlertSounds,
      disableAlertSounds: actions.timeline.disableAlertSounds
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
