import * as types from './actionTypes';
import moment from 'moment';

const reducer = (
  state = {
    lastKnownPosition: null
  },
  action
) => {
  let username;
  switch (action.type) {
    case types.NETWORK_LOGIN_SUCCESS:
      username = action.payload.user.username;
      return {
        ...state,
        [username]: {
          ...state[username],
          isAuthenticated: true
        }
      };
    case types.NETWORK_LOGIN_FAILURE:
      return {
        ...state,
        loginFailure: {
          message: action.payload.message,
          attempts: state.loginFailure ? state.loginFailure.attempts + 1 : 1
        }
      };
    case types.NETWORK_LOGOUT_SUCCESS:
      return {};
    case types.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.username
      };
    case types.LOCAL_LOGOUT: {
      let stateClone = Object.assign({}, state);
      delete stateClone.currentUser;
      return stateClone;
    }
    case types.FETCH_AUTHSTATUS_SUCCESS: {
      const newState = {
        ...state,
        currentUser: action.payload.user.username
      };
      username = action.payload.user.username || state.currentUser;
      if (username) {
        newState[username] = {
          ...state[username],
          isAuthenticated: action.payload.user.authenticated
        };
      }
      return newState;
    }
    case types.LAST_KNOWN_UPDATE: {
      let newLastKnownPosition = state.lastKnownPosition;
      newLastKnownPosition = moment().format('YYYY-MM-DD HH:mm:ss');
      return {
        ...state,
        lastKnownPosition: newLastKnownPosition
      };
    }
    default:
      return state;
  }
};

const currentUser = state => state.currentUser;
const isAuthenticated = (state, user) =>
  state[user] && state[user].isAuthenticated;
const loginFailure = state => state.loginFailure || {};
const LKPUpdate = state => state.lastKnownPosition;

const userSelectors = {
  isAuthenticated: (state, user) => state[user] && state[user].isAuthenticated,
  isCurrentUserAuthenticated: state => {
    const user = currentUser(state);
    return !!user && isAuthenticated(state, user);
  },
  currentUser,
  loginFailure,
  LKPUpdate
};

export default reducer;
export { userSelectors };
