import util from '../util.js';

const provider = (function() {
  const provide = function(config = {}) {
    // needed for offline testing with Jest
    const headers = config.cookie ? { Cookie: config.cookie } : {};

    const read = function(geoFeatureId, language) {
      return fetch(
        util.buildUrl(
          '/api/crud/GeoFeature/' + encodeURIComponent(geoFeatureId),
          { language },
          config.baseURI
        ),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    const observations = function(geoFeatureId, language) {
      return fetch(
        util.buildUrl(
          '/api/crud/GeoFeature/' +
            encodeURIComponent(geoFeatureId) +
            '/Observation',
          { language },
          config.baseURI
        ),
        {
          method: 'GET',
          headers: {
            ...headers,
            Accept: 'application/json'
          },
          credentials: 'same-origin'
        }
      ).then(
        response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(error => ({ isError: true, error }));
          }
        },
        error => {
          return { isError: true, error };
        }
      );
    };

    return {
      name: 'GeoFeatureClient',
      read,
      observations
    };
  };

  return provide;
})();

export default provider;
